<template>
  <!-- begin::Seven section - Información familiar detallada -->
  <v-sheet elevation="0" class="rounded-lg mt-4 pa-3">
    <!-- begin::card header -->
    <v-row
      class="d-flex flex-column-reverse flex-sm-row blue accent-5 mb-8 rounded white--text"
    >
      <!-- begin::section title - Dirección -->
      <v-col
        cols="12"
        class="d-flex align-center justify-center justify-md-start flex-column flex-md-row"
      >
        <div class="d-flex flex-row justify-start align-items-center">
          <p class="text-h5 mb-0">Paso</p>
          <v-icon color="white" class="mx-2"
            >mdi-numeric-{{ step }}-circle-outline</v-icon
          >
        </div>
        <p class="ma-0 text-h6 text-center text-md-left mt-2 mt-sm-0">
          <span class="font-weight-medium text-h5 mr-1 d-none d-md-inline"
            >-</span
          >
          Información familiar detallada
        </p>
      </v-col>
      <!-- end::step - Dirección -->
    </v-row>

    <!-- begin::card body -->
    <v-row class="d-flex justify-center">
      <v-col cols="12" class="pa-0">
        <div class="d-flex align-center flex-column flex-sm-row mx-0 mx-sm-4">
          <div>
            <p class="text-h5 text-center mb-4 mb-sm-0 font-weight-bold">
              Información familiar
            </p>
          </div>
          <v-divider class="mx-4 d-none d-sm-flex" vertical></v-divider>
          <!-- <v-spacer></v-spacer> -->
          <!-- Add new entry btn - Egresado - Historial Familiar -->
          <v-btn color="light-blue darken-4" dark @click="showMdlCreate()">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            Agregar
          </v-btn>
        </div>
        <!-- Table instructions and information - Información familiar detallada -->
        <div class="d-flex ml-sm-2 my-4 flex-column flex-sm-row">
          <div class="d-flex align-stretch justify-center mr-2">
            <v-icon small color="deep-orange">mdi-information-outline</v-icon>
          </div>
          <p
            class=" mb-0 mt-sm-0 text-body-1 text-center text-sm-left
                  "
          >
            Agrega información detallada de familiares haciendo click en el
            botón 
            <span class="font-weight-bold">"Agregar"</span>
            .
          </p>
        </div>

        <div class="my-4 d-flex flex-wrap justify-sm-center justify-md-start">
          <!-- No entries fallback text - Información familiar detallada -->
          <v-col cols="12" class="pb-0">
            <v-alert
              class="mb-0"
              dense
              outlined
              prominent
              text
              type="warning"
              v-if="membersDetailedInformation.addedMembers == 0"
              >Debe registrar almenos 1 familiar para poder continuar.
            </v-alert>
          </v-col>
          <!-- begin::Card to show informaion -->
          <v-col
            cols="12"
            md="6"
            lg="4"
            xl="3"
            v-for="(item, index) in membersDetailedInformation.addedMembers"
            :key="index"
          >
            <!-- begin::card -->
            <v-card
              class="mx-auto score-sheet-card-large"
              max-width="400"
              outlined
            >
              <v-list-item three-line>
                <!-- begin::card title -->
                <v-list-item-content
                  class="d-flex justify-start align-start flex-column"
                >
                  <div>
                    <p class="text-h5 font-weight-medium mb-0">
                      {{ item.firstName }}
                    </p>
                    <p class="text-h5 font-weight-medium mb-0">
                      {{ item.lastName }}
                    </p>

                    <v-chip
                      v-if="!!item.isFinancialSupporter"
                      class="mt-2 font-weight-bold"
                      color="green lighten-5"
                      text-color="green"
                    >
                      <v-avatar left>
                        <v-icon>mdi-star-circle</v-icon>
                      </v-avatar>
                      Sostenedor económico
                    </v-chip>

                    <v-chip
                      v-if="item.isManager"
                      class="mt-2"
                      color="blue lighten-5"
                      text-color="blue"
                    >
                      <v-avatar left>
                        <v-icon>mdi-account-supervisor-circle</v-icon>
                      </v-avatar>
                      Responsable ante la institución
                    </v-chip>
                  </div>
                </v-list-item-content>
                <!-- end::card title -->

                <!-- begin::card icon -->
                <div
                  class="ma-0 pa-0 d-flex flex-column justify-center align-top"
                  v-if="!item.isManager"
                >
                  <v-list-item-avatar
                    tile
                    size="80"
                    color="blue-grey lighten-5"
                    class="rounded-lg"
                  >
                    <v-icon
                      x-large
                      :color="`blue lighten-3`"
                     
                      >mdi-account-circle
                    </v-icon>
                  </v-list-item-avatar>
                  <p
                    class="ma-0 mb-2 text-body-1 font-weight-bold text-center black--text"              
                  >
                    {{ item.Kinship.name }}
                    <!-- begin::card email -->
                  </p>
                </div>

                <v-tooltip v-else bottom color="blue-grey darken-4">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="ma-0 pa-0 d-flex flex-column justify-center align-top"
                    >
                      <v-list-item-avatar
                        v-bind="attrs"
                        v-on="on"
                        tile
                        size="80"
                        :color="`${item.isFinancialSupporter ? 'green' :'blue'} lighten-5`"
                        class="rounded-lg mx-auto"
                      >
                        <v-icon x-large :color="item.isFinancialSupporter ? 'green' :'blue accent-2' "
                          >mdi-shield-account
                        </v-icon>
                      </v-list-item-avatar>
                      <p
                        class="ma-0 mb-3 text-body-1 black--text text-center font-weight-bold"
                      >
                        {{ item.Kinship.name }}
                        <!-- begin::card email -->
                      </p>
                    </div>
                  </template>
                  <span>Responsable del menor ante la institución.</span>
                </v-tooltip>

                <!-- end::card icon -->
              </v-list-item>
              <!-- begin::card body -->

              <!-- end::card email -->

              <v-card-actions
                class="d-flex justify-center align-self-end blue-grey lighten-5"
              >
                <v-btn
                  class="my-1"
                  small
                  depressed
                  color="light-blue lighten-4"
                  @click="
                    getDetailsFromSelectedFamilyMember(item);
                    showMdlDetailsSection7Modal();
                  "
                >
                  <v-icon color="light-blue" small class="mr-1"
                    >mdi-information-outline
                  </v-icon>
                  Detalle
                </v-btn>

                <v-btn
                  v-if="!(item.isFinancialSupporter && item.isManager)"
                  class="my-1"
                  small
                  depressed
                  color="blue-grey lighten-4"
                  @click="
                    showMdlUpdateDetailsSection7Modal(),
                      getDetailsFromSelectedFamilyMember(item)
                  "
                >
                  <v-icon color="blue-grey" small class="mr-1"
                    >mdi-pencil-circle-outline
                  </v-icon>
                  Editar
                </v-btn>

                <v-btn
                  v-if="!(item.isFinancialSupporter && item.isManager)"
                  class="my-1"
                  small
                  depressed
                  color="red lighten-4"
                  @click="
                    showDeleteModal(), getDetailsFromSelectedFamilyMember(item)
                  "
                >
                  <v-icon color="red" small class="mr-1"
                    >mdi-delete-circle-outline
                  </v-icon>
                  Eliminar
                </v-btn>
              </v-card-actions>
              <!-- end::card body -->
            </v-card>

            <v-card class="mx-auto rounded score-sheet-card-small" outlined>
              <div class="mt-4 d-flex justify-center flex-column">
                <v-chip
                  v-if="!!item.isFinancialSupporter"
                  class="mt-2 font-weight-bold d-flex justify-center text-center mx-2"
                  color="green lighten-5"
                  text-color="green"
                >
                  <v-avatar left>
                    <v-icon>mdi-star-circle</v-icon>
                  </v-avatar>
                  Sostenedor económico
                </v-chip>

                <v-chip
                  v-if="item.isManager"
                  class="mt-2 d-flex justify-center text-center mx-2"
                  color="blue lighten-5"
                  text-color="blue"
                >
                  <v-avatar left>
                    <v-icon>mdi-account-supervisor-circle</v-icon>
                  </v-avatar>
                  Responsable ante la institución
                </v-chip>

                <p class="text-h5 font-weight-medium mb-0 text-center mt-2">
                  {{ item.firstName }}
                </p>

                <p class="text-h5 font-weight-medium mb-0 text-center">
                  {{ item.lastName }}
                </p>

                <p class="text-overline text-center ma-0 mb-n2">
                  {{ item.Kinship.name }}
                </p>
              </div>

              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-list-item-avatar
                    v-if="!item.isManager"
                    tile
                    size="80"
                    color="blue-grey lighten-5"
                    class="rounded-lg mx-auto"
                  >
                    <v-icon x-large :color="`blue lighten-3`"
                      >mdi-account-circle
                    </v-icon>
                  </v-list-item-avatar>
                  <v-tooltip v-else bottom color="blue-grey darken-4">
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar
                        v-bind="attrs"
                        v-on="on"
                        tile
                        size="80"
                        :color="`${item.isFinancialSupporter ? 'green' :'blue'} lighten-5`"
                        class="rounded-lg mx-auto"
                      >
                        <v-icon x-large :color="item.isFinancialSupporter ? 'green' :'blue accent-2' "
                          >mdi-shield-account
                        </v-icon>
                      </v-list-item-avatar>
                    </template>
                    <span>Responsable del menor ante la institución.</span>
                  </v-tooltip>
                </v-col>
              </v-row>

              <v-card-actions
                class="d-flex justify-center flex-row blue-grey lighten-5"
              >
                <v-btn
                  class="mx-auto my-1"
                  color="light-blue lighten-4"
                  depressed
                  @click="
                    getDetailsFromSelectedFamilyMember(item);
                    showMdlDetailsSection7Modal();
                  "
                >
                  <v-icon color="light-blue">mdi-open-in-new </v-icon>
                </v-btn>

                <v-btn
                  v-if="!(item.isFinancialSupporter && item.isManager)"
                  class="mx-auto my-1"
                  color="blue-grey lighten-4"
                  depressed
                  @click="
                    getDetailsFromSelectedFamilyMember(item),
                      showMdlUpdateDetailsSection7Modal()
                  "
                >
                  <v-icon color="blue-grey">mdi-pencil-outline</v-icon>
                </v-btn>

                <v-btn
                  v-if="!(item.isFinancialSupporter && item.isManager)"
                  class="mx-auto my-1"
                  color="red lighten-4"
                  depressed
                  @click="
                    showDeleteModal(), getDetailsFromSelectedFamilyMember(item)
                  "
                >
                  <v-icon color="red">mdi-delete-outline</v-icon>
                </v-btn>
              </v-card-actions>
              <!-- end::card body -->
            </v-card>
            <!-- end::card -->
          </v-col>
          <!-- end::Card to show information -->
        </div>
      </v-col>
    </v-row>
    <!-- begin::dialog Create Detailed Family Modal -->
    <CreateSection7Modal
      ref="mdlCreateModal"
      :section7="section7"
      :familyList="membersDetailedInformation"
      @create="createStudentManager"
      @resultado="getMunicipality"
    ></CreateSection7Modal>
    <!-- end::dialog Create Detailed Family Modal -->
    <!-- begin::dialog Update Detailed Family Modal -->
    <UpdateSection7Modal
      ref="mdlUpdateModal"
      :section7="section7"
      :selectedFamilyMember="selectedFamilyMemberDetailsForUpdate"
      @update="updateStudentManager"
      @getMunicipio="getMunicipality"
    ></UpdateSection7Modal>
    <!-- begin::dialog Update Detailed Family Modal -->
    <!-- begin::dialog informacion detallada - Informacion familiar detallada -->
    <DetailsSection7Modal
      ref="mdlShowModal"
      :selectedItem="selectedFamilyMemberDetails"
    >
    </DetailsSection7Modal>
    <!-- end::dialog informacion detallada - Informacion familiar detallada -->
    <!-- begin::delete dialog modal - Informacion familiar detallada -->
    <DeleteModal
      ref="mdlDeleteModal"
      :dialogText="
        `¿Está seguro que desea eliminar a ${selectedFamilyMemberDetailsSummary.mainTitle} del registro?`
      "
      :selectedItemInformationSummary="selectedFamilyMemberDetailsSummary"
      @delete="deleteStudentManager"
    >
    </DeleteModal>
    <!-- end::delete dialog modal - Informacion familiar detallada -->
  </v-sheet>
  <!-- end::Seven section - Información familiar detallada -->
</template>
<script>
import CreateSection7Modal from "@/view/components/modals/section-7/CreateSection7Modal";
import UpdateSection7Modal from "@/view/components/modals/section-7/UpdateSection7Modal.vue";
import DetailsSection7Modal from "@/view/components/modals/section-7/DetailsSection7Modal";
import DeleteModal from "@/view/components/modals/DeleteModal";
import municipalitiesRepository from "@/repositories/municipalitiesRepository";
import seventhStepRepository from "@/repositories/steps/seventhStepRepository";

export default {
  name: "DetailedFamilyInformation",
  components: {
    CreateSection7Modal,
    UpdateSection7Modal,
    DeleteModal,
    DetailsSection7Modal,
  },
  props: {
    section7: {
      type: Object,
    },
    step: {
      type: Number,
    },
  },

  mounted() {
    this.getAllStudentManagers();
  },
  watch: {
    step(newvalue) {
      if (newvalue) {
        if (this.step == 7) {
          this.getAllStudentManagers();
        }
      }
    },
  },
  data() {
    return {
      selectedFamilyMemberDetailsSummary: {},
      selectedFamilyMemberDetails: {},
      selectedFamilyMemberDetailsForUpdate: {},
      // progress: 1.5,
      // familyMembersDetailedInformation

      membersDetailedInformation: {
        justAddedMember: [],
        addedMembers: [],
      },
    };
  },
  methods: {
    // Creating non-reactive copies of data for modals

    getDetailsFromSelectedFamilyMember(obj) {
      this.selectedFamilyMemberDetails = { ...obj };
      //for delete card
      this.selectedFamilyMemberDetailsSummary = {
        id: obj.id,
        correlative: obj.correlative,
        upperTitle: obj.Kinship.name,
        mainTitle: `${obj.firstName} ${obj.lastName}`,
        subTitle: obj.kinship,
        icon: obj.isManager ? "mdi-shield-alert" : "mdi-account-minus",
        iconColor: `red darken-1`,
      };
      if (!!obj.Municipality?.DepartmentId) {
        this.getMunicipality(obj.Municipality.DepartmentId);
      }
      this.selectedFamilyMemberDetailsForUpdate = {
        id: obj.id,
        firstName: obj.firstName,
        lastName: obj.lastName,
        dui: obj.dui,
        nit: obj.nit,
        nationalityId: obj.nationalityId,
        birthday: obj.birthday,
        departmentId: obj.Municipality?.DepartmentId,
        municipalityId: obj.municipalityId,
        kinshipId: obj.kinshipId,
        maritalStatusId: obj.maritalStatusId,
        phone: obj.phone,
        cellphone: obj.cellphone,
        profession: obj.profession,
        email: obj.email,
        workplace: obj.workplace,
        workPhone: obj.workPhone,
        extension: obj.extension,
        workAddress: obj.workAddress,
        isManager: obj.isManager,
        municipalities: [],
      };
    },

    // Data fetching

    getMunicipality(deparmentId) {
      municipalitiesRepository
        .municipalitiesByDepartment(deparmentId)
        .then(({ data }) => {
          this.section7.personalDetails.municipalities = data;
          this.selectedFamilyMemberDetailsForUpdate.municipalities = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },

    getAllStudentManagers() {
      seventhStepRepository
        .getAllStudentManagers()
        .then(({ data }) => {
          this.membersDetailedInformation.addedMembers = data;
        })
        .catch((err) => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },

    createStudentManager() {
      seventhStepRepository
        .addStudentManager(this.membersDetailedInformation.justAddedMember)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllStudentManagers();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar guardar los datos",
          });
        });
    },

    updateStudentManager() {
      seventhStepRepository
        .updateStudentManager(
          this.selectedFamilyMemberDetailsForUpdate.id,
          this.selectedFamilyMemberDetailsForUpdate
        )
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllStudentManagers();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No se realizó ningún cambio.",
          });
          this.getAllStudentManagers();
        });
    },

    deleteStudentManager(itemId) {
      seventhStepRepository
        .deleteStudentManager(itemId)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllStudentManagers();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar eliminar el registro",
          });
        });
    },
    validate() {
      const existsManager = this.membersDetailedInformation.addedMembers.some(
        (item) => item.isManager === true
      );
      if (!existsManager) {
        this.fireToast({
          icon: "error",
          title: "Debe agregar al menos un responsable ante la institución",
        });
        return;
      }
      this.$emit("next");
    },
    // Toggle modals

    showMdlCreate() {
      this.$refs.mdlCreateModal.toggleModal();
    },
    showMdlDetailsSection7Modal() {
      this.$refs.mdlShowModal.toggleModal();
    },
    showMdlUpdateDetailsSection7Modal() {
      this.$refs.mdlUpdateModal.toggleModal();
    },
    showDeleteModal() {
      this.$refs.mdlDeleteModal.toggleModal();
    },
  },
};
</script>
<style>
@media only screen and (max-width: 900px) {
  #content {
    overflow-x: auto;
    flex-wrap: nowrap !important;
  }
}
</style>
