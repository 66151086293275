<template>
  <!-- begin::dialog informacion detallada - colegios o escuelas anteriores -->
  <v-dialog
    v-model="modalState"
    transition="dialog-bottom-transition"
    width="500px"
    scrollable
  >
    <v-card>
      <v-card-title class="text-h5 light-blue darken-2 white--text text-center">
        Información detallada
      </v-card-title>

      <v-card-text class="pt-3 pb-1">
        <v-row>
          <v-col cols="12">
            <div class="grey lighten-4 rounded-lg pa-3">
              <div class="d-flex align-center mb-4 flex-column flex-sm-row">
                <v-btn
                  color="primary"
                  elevation="2"
                  class="mb-2 mb-sm-0"
                  fab
                  x-small
                  ><v-icon>mdi-account-circle-outline</v-icon></v-btn
                >
                <p class="text-h5 mb-0 ml-sm-2 font-weight-medium">
                  Información personal
                </p>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Centro educativo</div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedSchoolDetails.School
                      ? selectedSchoolDetails.School.name
                      : undefined
                  }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Departamento</div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedSchoolDetails.School
                      ? selectedSchoolDetails.School.Municipality.Department
                          .name
                      : undefined
                  }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Municipio</div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedSchoolDetails.School
                      ? selectedSchoolDetails.School.Municipality.name
                      : undefined
                  }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">De grado</div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedSchoolDetails
                      ? selectedSchoolDetails.gradeFrom
                      : undefined
                  }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">A grado</div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedSchoolDetails
                      ? selectedSchoolDetails.gradeTo
                      : undefined
                  }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">Motivo de cambio</div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedSchoolDetails
                      ? selectedSchoolDetails.reasonChange
                      : undefined
                  }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end pt-0 pb-3">
        <v-spacer></v-spacer>
        <v-btn
          color="light-blue darken-2"
          class="white--text"
          depressed
          @click="modalState = false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- end::dialog informacion detallada - colegios o escuelas anteriores -->
</template>
<script>
  export default {
    name: "PreviousSchoolInformationModal",
    props: {
      selectedSchoolDetails: {
        type: Object,
      },
    },
    data() {
      return {
        modalState: false,
      };
    },
    methods: {
      toggleModal() {
        this.modalState = !this.modalState;
      },
    },
  };
</script>
