<template>
  <v-dialog
    v-model="modalState"
    max-width="600px"
    transition="dialog-bottom-transition"
    scrollable
    persistent
  >
    <!--Floating input form - Historial Familiar ITR -->
    <v-card elevation="1">
      <!-- form header name - Historial Familiar ITR -->
      <v-card-title class="text-h5 light-blue darken-4 white--text text-center">
        Agregar medicamento
      </v-card-title>
      <v-card-text class="my-0 py-0 mt-5">
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group
                  name="medicamento"
                  :validator="$v.tempUserData.drugId"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :items="section5.conditionsDetails.availablePrescribedDrugs"
                    item-text="name"
                    item-value="id"
                    v-model="tempUserData.drugId"
                    label="Medicamento"
                    color="light-blue darken-4"
                    required
                    outlined
                  ></v-select>
                </form-group>
              </v-col>
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group name="dosis" :validator="$v.tempUserData.dosage">
                  <v-textarea
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    autocomplete="null"
                    outlined
                    rows="1"
                    label="Dosis"
                    placeholder="Ej: 8ml cada 12 horas.."
                    color="light-blue darken-4"
                    :counter="100"
                    v-model="tempUserData.dosage"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end pt-0 pb-3">
        <v-spacer></v-spacer>
        <!-- cancel btn - Historial Familiar ITR -->
        <v-btn
          color="light-blue darken-4"
          outlined
          text
          @click="showMdlConfirm()"
          >Cancelar</v-btn
        >
        <!-- save data btn - Historial Familiar ITR -->
        <v-btn
          color="light-blue darken-4"
          class="white--text"
          depressed
          @click="validate()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
    <ConfirmationModal
      ref="confirmationModal"
      v-on:confirmData="toggleModal"
      v-on:resetForm="resetForm"
    ></ConfirmationModal>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import ConfirmationModal from "@/view/components/modals/ConfirmationModal";
export default {
  name: "CreateModal",
  components: {
    ConfirmationModal,
  },
  props: {
    prescribedDrugsList: {
      type: Object,
    },
    section5: {
      type: Object,
    },
  },
  data() {
    return {
      modalState: false,
      tempUserData: {},
    };
  },
  methods: {
    toggleModal() {
      this.modalState = !this.modalState;
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar los campos requeridos",
        });
        return;
      }
      this.saveData();
    },
    saveData() {
      const data = {
        medicineId: this.tempUserData.drugId,
        dose: this.tempUserData.dosage,
        isAllergic: false,
      };
      this.prescribedDrugsList.prescribedDrugsJustAddedByUser = data;
      this.$emit("create");
      this.toggleModal();
      this.resetForm();
    },
    showMdlConfirm() {
      this.$refs.confirmationModal.toggleModal();
    },
    resetForm() {
      this.tempUserData = {};
      this.$v.$reset();
    },
  },
  validations: {
    tempUserData: {
      drugId: { required },
      dosage: { required },
    },
  },
};
</script>
