<template>
  <!-- begin::Second section - Dirección -->
  <v-sheet elevation="0" class="rounded-lg pa-3">
    <!-- begin::card header -->
    <v-row
      class="d-flex flex-column-reverse flex-sm-row blue accent-5 mb-8 rounded white--text"
    >
      <!-- begin::section title - Dirección -->
      <v-col
        cols="12"
        class="d-flex align-center justify-center justify-lg-start flex-column flex-lg-row"
      >
        <div class="d-flex flex-row justify-start align-items-center">
          <p class="text-h5 mb-0">Paso</p>
          <v-icon color="white" class="ml-2 mr-2"
            >mdi-numeric-{{ step }}-circle-outline</v-icon
          >
        </div>
        <p class="ma-0 text-h6 text-center text-lg-left mt-2 mt-lg-0">
          <span class="font-weight-medium text-h5 mr-1 d-none d-lg-inline"
            >-</span
          >
          Formulario para confirmación de sostenedor económico, facturación
          electrónica, firma de contrato y pagaré
        </p>
      </v-col>
      <!-- end::step - Dirección -->
    </v-row>
    <div
      v-if="studentEconomicManagerLoading"
      style="height: 400px"
      class="white mt-3 mb-6"
    >
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="text-subtitle-1 text-center" cols="12">
          Cargando información...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="blue darken-1"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <!-- begin::card body -->
    <v-form ref="createForm_section2" v-else>
      <v-row class="mt-n8 d-flex justify-center">
        <v-col cols="12" class="px-0">
          <v-alert prominent dense text type="warning" class="rounded-lg">
            <p class="text-h6 grey--text text--darken-4 mb-0">
              Los datos solicitados en este formulario serán usados para
              confirmación de sostenedor económico, facturación electrónica,
              firma de contrato y pagaré.
              <span class="font-weight-bold"
                >Todos los datos solicitados son obligatorios.</span
              >
            </p>
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="Nombre"
            :validator="$v.studentEconomicManager.first_name"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              outlined
              label="Nombre"
              v-model="studentEconomicManager.first_name"
            ></v-text-field>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="Apellido"
            :validator="$v.studentEconomicManager.last_name"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              outlined
              label="Apellido"
              v-model="studentEconomicManager.last_name"
            ></v-text-field>
          </form-group>
        </v-col>

        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="de correo electrónico"
            :validator="$v.studentEconomicManager.email"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              outlined
              label="Correo electrónico"
              v-model="studentEconomicManager.email"
            ></v-text-field>
          </form-group>
        </v-col>

        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="Número de celular"
            :validator="$v.studentEconomicManager.cellphone"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              outlined
              label="Número de celular"
              v-model="studentEconomicManager.cellphone"
              v-mask="'########'"
            ></v-text-field>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="telefono"
            :validator="$v.studentEconomicManager.phone"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              outlined
              label="Teléfono residencial"
              v-model="studentEconomicManager.phone"
              v-mask="'2#######'"
            >
            </v-text-field>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group name="Departamento" :validator="$v.selectedDepartment">
            <v-select
              :loading="loadingDepartments"
              :disabled="loadingDepartments"
              slot-scope="{ attrs }"
              v-bind="attrs"
              outlined
              :items="section7.personalDetails.departments"
              item-value="id"
              item-text="name"
              return-object
              label="Departamento"
              v-model="selectedDepartment"
              @change="
                getMunicipality(selectedDepartment.id), resetMunicipality()
              "
            ></v-select>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group name="Municipio" :validator="$v.selectedMunicipality">
            <v-select
              :loading="loadingMunicipalities"
              :disabled="
                loadingMunicipalities ||
                  Object.keys(selectedDepartment).length === 0
              "
              slot-scope="{ attrs }"
              v-bind="attrs"
              outlined
              :items="section7.personalDetails.municipalities"
              item-value="id"
              item-text="name"
              return-object
              label="Municipio"
              v-model="selectedMunicipality"
            ></v-select>
          </form-group>
        </v-col>
        <v-col cols="12" md="12" class="pa-0 pa-sm-2">
          <form-group
            name="dirección"
            :validator="$v.studentEconomicManager.address"
          >
            <v-textarea
              slot-scope="{ attrs }"
              v-bind="attrs"
              counter
              outlined
              clearable
              auto-grow
              rows="2"
              label="Dirección"
              v-model.trim="studentEconomicManager.address"
              @paste="handleEmptySpaces($event, 'address')"
              @keyup.enter="handleKeyUpAddress()"
            ></v-textarea>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group name="DUI" :validator="$v.studentEconomicManager.dui">
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              outlined
              label="DUI"
              v-model="studentEconomicManager.dui"
              v-mask="'########-#'"
            ></v-text-field>
          </form-group>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
          class="pa-0 pa-sm-2"
          v-if="!switch1"
        >
          <form-group
            name="nit"
            :validator="$v.studentEconomicManager.nit"
            :messages="{
              minLength: 'El campo nit debería contener al menos 14 números',
            }"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              autocomplete="null"
              label="NIT"
              color="light-blue darken-4"
              v-model="studentEconomicManager.nit"
              outlined
              v-mask="'####-######-###-#'"
            ></v-text-field>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-switch
                  class="ml-5"
                  v-model="switch1"
                  color="light-blue darken-4"
                  inset
                  :label="`DUI homologado`"
                  @change="changeSwitchNit()"
                ></v-switch>
              </div>
            </template>
            <div>Marque sí posee el DUI homologado</div>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="parentesco"
            :validator="$v.studentEconomicManager.kinshipId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              label="Parentesco"
              color="light-blue darken-4"
              :items="section7.personalDetails.kinships"
              item-text="name"
              item-value="id"
              v-model="studentEconomicManager.kinshipId"
              outlined
            ></v-select>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <v-menu
            ref="dialog"
            v-model="dateDialogState"
            :close-on-content-click="false"
            :return-value="studentEconomicManager.birthday"
            transition="scale-transition"
            offset-y
            min-width="250px"
          >
            <template v-slot:activator="{ on, attrs }">
              <form-group
                name="fecha de nacimiento"
                :validator="$v.studentEconomicManager.birthday"
                v-bind="attrs"
              >
                <v-text-field
                  v-model="studentEconomicManager.birthday"
                  label="Fecha de nacimiento"
                  color="light-blue darken-4"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  clearable
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  v-on="on"
                  required
                ></v-text-field>
              </form-group>
            </template>
            <v-date-picker
              v-model="studentEconomicManager.birthday"
              color="light-blue darken-4"
              :active-picker.sync="activePicker"
              :max="
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                  .toISOString()
                  .substring(0, 10)
              "
              min="1922-01-01"
              @change="save(studentEconomicManager.birthday)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="estado civil"
            :validator="$v.studentEconomicManager.maritalStatusId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              label="Estado civil"
              color="light-blue darken-4"
              :items="section7.personalDetails.maritalStatus"
              item-text="name"
              item-value="id"
              v-model="studentEconomicManager.maritalStatusId"
              outlined
            ></v-select>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="profesión u oficio"
            :validator="$v.studentEconomicManager.profession"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              autocomplete="null"
              label="Profesión u oficio"
              color="light-blue darken-4"
              v-model="studentEconomicManager.profession"
              outlined
            ></v-text-field>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="lugar de trabajo"
            :validator="$v.studentEconomicManager.workPlace"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              autocomplete="null"
              label="Lugar de trabajo"
              color="light-blue darken-4"
              v-model="studentEconomicManager.workPlace"
              outlined
            ></v-text-field>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="teléfono de trabajo"
            :validator="$v.studentEconomicManager.workPhone"
            :messages="{
              minLength:
                'El campo teléfono de trabajo debería contener al menos 8 números',
            }"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              autocomplete="null"
              label="Teléfono del trabajo"
              color="light-blue darken-4"
              v-model="studentEconomicManager.workPhone"
              outlined
              v-mask="'########'"
            ></v-text-field>
          </form-group>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <v-text-field
            autocomplete="null"
            label="Extensión"
            color="light-blue darken-4"
            v-model="studentEconomicManager.extension"
            outlined
            v-mask="'####'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <v-textarea
            autocomplete="null"
            outlined
            v-model="studentEconomicManager.workAddress"
            rows="1"
            name="input-7-4"
            label="Dirección de trabajo"
            color="light-blue darken-4"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-switch
                  class="ml-5"
                  v-model="studentEconomicManager.isManager"
                  color="light-blue darken-4"
                  inset
                  :label="`¿Es responsable ante la institución?`"
                ></v-switch>
              </div>
            </template>
            <div>
              Marque sí el responsable será responsable ante la institución
            </div>
          </v-tooltip>
        </v-col>
      </v-row>
      <!-- <div>
        <v-alert icon="mdi-shield-lock-outline" prominent text type="info">
          Agregar fotografía del DUI, frente y reverso.
        </v-alert>
      </div> -->

      <!-- <v-row justify="center">
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <div
            height="250"
            width="250"
            class="d-flex flex-column justify-center align-center"
          >
            <h6>Frente</h6>
            <v-img
              :src="
                studentEconomicManager.dui_front_photo_URL
                  ? studentEconomicManager.dui_front_photo_URL
                  : 'media/section-8/vacio.png'
              "
              contain
              class="mx-auto rounded"
              height="180"
            >
            </v-img>

            <v-btn
              color="light-blue darken-4"
              class="mt-3"
              rounded
              dark
              :loading="isSelectingFront"
              @click="handleFileImport('uploader-front', 'isSelectingFront')"
            >
              <span v-if="duiFront">{{ duiFront.name }}</span>
              <span v-else>Subir archivo</span>
            </v-btn>
            <form-group name="dui frente" :validator="$v.duiFront">
              <v-file-input
                slot-scope="{ attrs }"
                v-bind="attrs"
                v-model="duiFront"
                accept="image/*"
                ref="uploader-front"
                type="file"
                @change="selectImage($event, 'dui_front_photo_URL')"
                @click:clear="clearImagePreview()"
              ></v-file-input>
            </form-group>
          </div>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3" class="pa-0 pa-sm-2">
          <div
            height="250"
            width="250"
            class="d-flex flex-column justify-center align-center"
          >
            <h6>Reverso</h6>
            <v-img
              :src="
                studentEconomicManager.dui_back_photo_URL
                  ? studentEconomicManager.dui_back_photo_URL
                  : 'media/section-8/vacio.png'
              "
              contain
              class="mx-auto rounded"
              height="180"
            >
            </v-img>

            <v-btn
              color="light-blue darken-4"
              class="mt-3"
              rounded
              dark
              :loading="isSelectingBack"
              @click="handleFileImport('uploader-back', 'isSelectingBack')"
            >
              <span v-if="duiBack">{{ duiBack.name }}</span>
              <span v-else>Subir archivo</span>
            </v-btn>
            <form-group name="dui reverso" :validator="$v.duiBack">
              <v-file-input
                slot-scope="{ attrs }"
                v-bind="attrs"
                v-model="duiBack"
                accept="image/*"
                ref="uploader-back"
                type="file"
                @change="selectImage($event, 'dui_back_photo_URL')"
                @click:clear="clearImagePreview()"
              ></v-file-input>
            </form-group>
          </div>
        </v-col>
      </v-row> -->
      <v-row class="mt-2 d-flex justify-center">
        <v-col cols="12">
          <v-alert text class="rounded-lg" color="primary">
            <div class="d-flex flex-row justify-start align-center mb-4">
              <v-avatar size="60" color="indigo lighten-4">
                <v-icon class="indigo--text">mdi-file-sign</v-icon>
              </v-avatar>
              <p class="text-h5 mb-0 ml-3 font-weight-bold">
                Términos y condiciones
              </p>
            </div>

            <div class="rounded-lg grey lighten-4 pa-4 mb-2">
              <p class="text-h6 font-weight-normal black--text text-justify">
                Yo,
                <span class="font-weight-bold"
                  >{{ studentEconomicManager.first_name }}
                  {{ studentEconomicManager.last_name }}</span
                >
                con Documento de Identidad número
                <span class="font-weight-bold">{{
                  studentEconomicManager.dui
                }}</span>
                declaro bajo juramento que la información proporcionada en la
                calidad en que actúo en el presente Formulario es veráz y
                demostrable a efecto de cumplir lo dispuesto en la Ley
                tributaria. Me comprometo a informar a la Asociación Institución
                Salesiana - Instituto Técnico Ricaldone, de cualquier cambio
                relativo a la misma en caso de que se presente o sea necesario
                adicionar información. Eximo de responsabilidad a los miembros
                de Junta Directiva, Asociados, directores, gerentes, jefes,
                supervisores y otro personal administrativo de servicios
                operativos con la Asociación Institución Salesiana - Instituto
                Técnico Ricaldone de toda responsabilidad, inclusive a terceros,
                si esta declaración fuese falsa o errónea.
              </p>
            </div>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12">
                <form-group name="aceptar" :validator="$v.termsAndConditions">
                  <v-checkbox
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    v-model="termsAndConditions"
                    color="success"
                    label="Acepto los términos y condiciones"
                  ></v-checkbox>
                </form-group>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
  </v-sheet>
  <!-- end::Second section - Dirección -->
</template>

<script>
import {
  required,
  email,
  maxLength,
  minLength,
  requiredIf,
  sameAs,
} from "vuelidate/lib/validators";
import studentEconomicManagerRepository from "@/repositories/studentEconomicManagerRepository.js";
import seventhStepRepository from "@/repositories/steps/seventhStepRepository";
import municipalitiesRepository from "@/repositories/municipalitiesRepository";
import { mapGetters } from "vuex";

export default {
  name: "registerStudentEconomicManager",
  props: {
    step: {
      type: Number,
    },
    section7: {
      type: Object,
    },
  },
  data() {
    return {
      loadingMunicipalities: false,
      loadingDepartments: false,
      // isSelectingFront: false,
      // isSelectingBack: false,
      // duiFront: null,
      // duiBack: null,
      studentEconomicManagerLoading: false,
      exist: false,
      departmentsList: [],
      municipalitiesList: [],
      selectedDepartment: {},
      selectedMunicipality: {},
      studentEconomicManager: {
        uuid: null,
        first_name: null,
        last_name: null,
        address: null,
        municipality: null,
        dui: null,
        cellphone: null,
        email: null,
        dui_front_photo: null,
        dui_back_photo: null,
        dui_front_photo_URL: null,
        dui_back_photo_URL: null,
        phone: null,
        nit: null,
        kinshipId: null,
        birthday: null,
        maritalStatusId: null,
        profession: null,
        workPlace: null,
        workPhone: null,
        extension: null,
        workAddress: null,
        isManager: false,
      },
      termsAndConditions: false,
      originalState: {},
      //StudentManager - form_db
      switch1: false,
      dateDialogState: false,
      activePicker: null,
    };
  },
  methods: {
    // handleFileImport(inputRef, isSelecting) {
    //   this[isSelecting] = true;
    //   window.addEventListener(
    //     "focus",
    //     () => {
    //       this[isSelecting] = false;
    //     },
    //     { once: true }
    //   );
    //   this.$refs[inputRef].$refs.input.click();
    // },
    // async selectImage(e, property) {
    //   const file = e;
    //   if (!file) return;
    //   const readData = (f) =>
    //     new Promise((resolve) => {
    //       const reader = new FileReader();
    //       reader.onload = () => resolve(reader.result);
    //       reader.readAsDataURL(f);
    //     });

    //   const data = await readData(file);
    //   this.studentEconomicManager[property] = data;
    // },
    getStudentEconomicManager() {
      this.studentEconomicManagerLoading = true;
      studentEconomicManagerRepository
        .registeredStudentEconomicManager(this.currentUserPersonalInfo.uuid)
        .then(({ data }) => {
          if (!Object.keys(data).length == 0) {
            this.exist = true;
            let selectMunicipality = data.municipality;
            this.selectedMunicipality = {
              id: selectMunicipality.id,
              departmentId: selectMunicipality.idDepartamento,
              name: selectMunicipality.nombre,
            };
            this.selectedDepartment = {
              id: data.municipality.departamento.id,
              name: data.municipality.departamento.nombre,
            };
            this.studentEconomicManager = {
              uuid: data.uuid,
              first_name: data.first_name,
              last_name: data.last_name,
              address: data.address,
              municipality: data.municipality,
              dui: data.dui,
              cellphone: data.cellphone,
              email: data.email,
              dui_front_photo_URL: data.dui_front_photo,
              dui_back_photo_URL: data.dui_back_photo,
              accepted_terms_and_conditions: data.accepted_terms_and_conditions,
            };
          }
          this.getMunicipality(this.selectedDepartment.id);
          this.getStudentEconomicManagerInformation();
          this.termsAndConditions = this.studentEconomicManager.accepted_terms_and_conditions;
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
          this.studentEconomicManagerLoading = true;
        });
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar los campos requeridos",
        });
        return;
      }
      this.createOrUpdate();
    },
    createOrUpdate() {
      if (this.exist) {
        this.updateStudentEconomicManager();
      } else {
        this.saveStudentEconomicManager();
      }
    },
    getMunicipality(deparmentId) {
      municipalitiesRepository
        .municipalitiesByDepartment(deparmentId)
        .then(({ data }) => {
          this.section7.personalDetails.municipalities = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },
    resetMunicipality() {
      this.studentEconomicManager.municipality = null;
    },
    saveStudentEconomicManager() {
      let formattedMunicipality = {
        id: this.selectedMunicipality.id,
        idDepartamento: this.selectedMunicipality.departmentId,
        nombre: this.selectedMunicipality.name,
        departamento: {
          id: this.selectedDepartment.id,
          idPais: 68,
          nombre: this.selectedDepartment.name,
          iso31662: "null",
        },
      };
      this.studentEconomicManager.uuid = this.currentUserPersonalInfo.uuid;
      this.studentEconomicManager.municipality = JSON.stringify(
        formattedMunicipality
      );
      this.$emit("loading");
      studentEconomicManagerRepository
        .registerStudentEconomicManager(
          this.objectToFormData(this.studentEconomicManager)
        )
        .then(() => {
          const information = this.updateStudentEconomicManagerInformation();
          if (information) {
            this.fireToast({
              icon: "success",
              title: "Sostenedor económico registrado correctamente",
            });
            this.$emit("next");
          } else {
            throw new Error("Hubo un problema");
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un problema, por favor intentar más tarde",
          });
        })
        .finally(() => {
          this.$emit("stopLoading");
        });
    },
    updateStudentEconomicManager() {
      let formattedMunicipality = {
        id: this.selectedMunicipality.id,
        idDepartamento: this.selectedMunicipality.departmentId,
        nombre: this.selectedMunicipality.name,
        departamento: {
          id: this.selectedDepartment.id,
          idPais: 68,
          nombre: this.selectedDepartment.name,
          iso31662: "null",
        },
      };
      this.studentEconomicManager.uuid = this.currentUserPersonalInfo.uuid;
      this.studentEconomicManager.municipality = JSON.stringify(
        formattedMunicipality
      );
      this.$emit("loading");
      studentEconomicManagerRepository
        .updateStudentEconomicManger(
          this.objectToFormData(this.studentEconomicManager)
        )
        .then(() => {
          const information = this.updateStudentEconomicManagerInformation();
          if (information) {
            this.fireToast({
              icon: "success",
              title: "Sostenedor económico actualizado correctamente",
            });
            this.$emit("next");
          } else {
            throw new Error("Hubo un problema");
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un problema, por favor intentar más tarde",
          });
        })
        .finally(() => {
          this.$emit("stopLoading");
        });
    },
    //FORM_DB
    changeSwitchNit() {
      this.$v.$reset();
      this.studentEconomicManager.nit = "";
    },
    save(date) {
      this.$refs.dialog.save(date);
    },
    getStudentEconomicManagerInformation() {
      seventhStepRepository
        .getStudentManagerInformation(this.studentEconomicManager.dui)
        .then(({ data }) => {
          if (data !== null) {
            this.studentEconomicManager = {
              ...this.studentEconomicManager,
              isManager: data.isManager,
              phone: data.phone,
              nit: data.nit,
              kinshipId: data.kinshipId,
              maritalStatusId: data.maritalStatusId,
              birthday: data.birthday,
              profession: data.profession,
              workPlace: data.workplace,
              workPhone: data.workPhone,
              extension: data.extension,
              workAddress: data.workAddress,
            };
          }
        })
        .catch((err) => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.studentEconomicManagerLoading = false;
        });
    },
    async updateStudentEconomicManagerInformation() {
      const payload = {
        firstName: this.studentEconomicManager.first_name,
        lastName: this.studentEconomicManager.last_name,
        email: this.studentEconomicManager.email,
        kinshipId: this.studentEconomicManager.kinshipId,
        cellphone: this.studentEconomicManager.cellphone,
        phone: this.studentEconomicManager.phone,
        dui: this.studentEconomicManager.dui,
        nit: this.studentEconomicManager?.nit,
        birthday: this.studentEconomicManager.birthday,
        maritalStatusId: this.studentEconomicManager.maritalStatusId,
        nationalityId: 1,
        municipalityId: this.selectedMunicipality.id,
        profession: this.studentEconomicManager.profession,
        workplace: this.studentEconomicManager.workPlace,
        workPhone: this.studentEconomicManager.workPhone,
        workAddress: this.studentEconomicManager.workAddress,
        extension: this.studentEconomicManager.extension,
        isManager: this.studentEconomicManager.isManager,
        isFinancialSupporter: true,
      };
      await seventhStepRepository
        .createStudentManager(payload)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  mounted() {},
  watch: {
    currentUserPersonalInfo: {
      handler(newValue, oldValue) {
        if (!!newValue.uuid && this.studentEconomicManagerLoading != true) {
          this.getStudentEconomicManager();
        }
      },
      immediate: true,
    },
    dateDialogState(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    studentEconomicManager(newvalue) {
      if (newvalue) {
        const hasDui = !!this.studentEconomicManager.dui;
        const isNitEmptyOrNull =
          !this.studentEconomicManager.nit ||
          this.studentEconomicManager.nit == "";
        if (hasDui && isNitEmptyOrNull) {
          this.switch1 = true;
        } else {
          this.switch1 = false;
        }
        return this.switch1;
      }
    },
  },
  validations: {
    selectedDepartment: {
      required,
    },
    selectedMunicipality: {
      required: requiredIf(function() {
        return !Object.keys(this.selectedDepartment).length == 0;
      }),
    },
    termsAndConditions: {
      required,
      "Debe de aceptar los términos y condiciones": sameAs(
        () => true || "true"
      ),
    },
    studentEconomicManager: {
      first_name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      last_name: {
        required,
        maxLength: maxLength(50),
        minLength: minLength(2),
      },
      dui: {
        required,
        "Formato de dui inválido"(value) {
          const pattern = /^\d{8}-\d{1}$/;
          return pattern.test(value);
        },
      },
      cellphone: {
        required,
        "Formato de teléfono inválido"(value) {
          const pattern = /^(2|7|6)\d{3}\d{4}$/;
          return pattern.test(value);
        },
      },
      email: {
        required,
        email,
      },
      address: {
        required,
        maxLength: maxLength(240),
        minLength: minLength(10),
      },
      kinshipId: { required },
      nit: {
        required: requiredIf(function() {
          return !this.switch1;
        }),
        minLength: minLength(17),
      },
      birthday: { required },
      maritalStatusId: { required },
      profession: { required },
      phone: {},
    },
  },
};
</script>
