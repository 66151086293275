import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllKinships = () => {
  return ApiService.get("kinships");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getKinship = (id) => {
  return ApiService.get(`kinships/${id}`);
};

export default {
  getAllKinships,
  getKinship,
};
