<template>
  <!-- begin::Third section - Colegios o escuelas anteriores -->
  <v-sheet elevation="2" class="rounded-lg mt-4 pa-5">
    <!-- begin::card header - Colegios o escuelas anteriores -->
    <v-row
      class="
            d-flex
            flex-column-reverse flex-sm-row
            blue-grey
            lighten-5
            mb-6
            rounded rounded-b-0
          "
    >
      <!-- begin::section title - Colegios o escuelas anteriores -->
      <v-col
        cols="12"
        sm="6"
        class="
              pt-0
              pa-sm-3
              d-flex
              align-center
              justify-center justify-sm-start
              flex-column flex-sm-row
            "
      >
        <v-icon color="cyan">mdi-format-list-bulleted-type</v-icon>
        <p class="ma-0 ml-2 text-h5 text-center text-sm-left">
          Colegios o escuelas anteriores
        </p>
      </v-col>
      <!-- end::section title - Colegios o escuelas anteriores -->

      <!-- begin::step - Colegios o escuelas anteriores -->
      <v-col
        cols="12"
        sm="6"
        class="d-flex align-center justify-center justify-sm-end"
      >
        <p class="ma-0 mr-1 text-h6">Paso</p>
        <v-icon>mdi-numeric-{{ step }}-circle-outline</v-icon>
      </v-col>
      <!-- end::step - Colegios o escuelas anteriores -->
    </v-row>

    <!-- begin::card body - Colegios o escuelas anteriores -->
    <v-row>
      <v-col cols="12" class="pa-0">
        <div class="d-flex align-center flex-column flex-sm-row mx-0 mx-sm-4">
          <!-- table title - Colegios o escuelas anteriores -->
          <div>
            <p class="text-h5 mb-4 mb-sm-0 font-weight-bold">
              Educación previa
            </p>
          </div>
          <v-divider class="mx-4 d-none d-sm-flex" vertical></v-divider>
          <!-- Agregar antiguo colegio - Colegios o escuelas anteriores -->
          <v-btn color="light-blue darken-4" dark @click="showMdlCreate()">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            Agregar
          </v-btn>
        </div>

        <div class="d-flex ml-sm-2 my-4 flex-column flex-sm-row">
          <div class="d-flex align-stretch justify-center mr-2">
            <v-icon small color="deep-orange">mdi-information-outline</v-icon>
          </div>
          <p
            class="mb-0 mt-sm-0 text-body-1 text-center text-sm-left
                "
          >
            Agregue las escuelas o colegios donde ha estudiado el solicitante
            antes del Instituto Técnico Ricaldone, haciendo click en "<span
              class="blue--text"
              ><v-icon color="blue" small>mdi-plus-circle-outline</v-icon>
              agregar</span
            >".
          </p>
        </div>
        <div class="my-4 d-flex flex-wrap justify-sm-center justify-md-start">
          <!-- Table instructions and information - Actualmente - Historial Familiar ITR -->
          <v-col cols="12" class="pb-0">
            <v-alert
              color="blue"
              class="mb-0"
              dense
              outlined
              prominent
              text
              type="info"
              v-if="userPast.schools.length == 0"
              >No se ha registrado ningún colegio o escuela.</v-alert
            >
          </v-col>
          <!-- begin::Card to show informaion - Large CARD -->
          <v-col
            cols="12"
            md="5"
            lg="4"
            xl="3"
            v-for="(item, index) in userPast.schools"
            :key="index"
          >
            <!-- begin::large card - Colegios o escuelas anteriores -->
            <v-card
              class="mx-auto score-sheet-card-large"
              max-width="370"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <!-- begin::large card title - Colegios o escuelas anteriores -->
                  <div>
                    <p class="text-overline ma-0 mb-n2">educación previa</p>
                  </div>
                  <!-- end::large card title - Colegios o escuelas anteriores -->
                  <!-- begin::large card content - Colegios o escuelas anteriores -->
                  <p class="text-h5 mb-0">
                    {{ item.School.name }}
                  </p>
                  <p class="ma-0 text-body-1" style="color:black !importante">
                    <span class="font-weight-medium">Desde </span
                    >{{ item.gradeFrom }},
                    <span class="font-weight-medium">hasta </span
                    >{{ item.gradeTo }}.
                  </p>
                  <!-- end::large card content - Colegios o escuelas anteriores -->
                </v-list-item-content>

                <!-- begin::large card icon - Colegios o escuelas anteriores -->
                <v-list-item-avatar
                  tile
                  size="80"
                  color="blue-grey lighten-5"
                  class="rounded-lg"
                >
                  <v-icon
                    x-large
                    :color="`${colors[item.id % 12]} lighten-3`"
                    class="ml-n1"
                    >mdi-school</v-icon
                  >
                </v-list-item-avatar>

                <!-- end::large card icon - Colegios o escuelas anteriores -->
              </v-list-item>
              <!-- begin::large card body - Colegios o escuelas anteriores -->
              <v-card-actions
                class="d-flex justify-center align-self-end blue-grey lighten-5"
              >
                <v-btn
                  class="my-1"
                  small
                  outlined
                  color="light-blue darken-2"
                  @click="
                    getDetailsFromSelectedSchool(item);
                    showMdlDetails();
                  "
                >
                  <v-icon color="light-blue darken-2" small class="mr-1"
                    >mdi-information-outline</v-icon
                  >
                  Detalle
                </v-btn>

                <v-btn
                  class="my-1"
                  small
                  outlined
                  color="blue-grey darken-3"
                  @click="
                    getMunicipality(item.departmentId);
                    getDetailsFromSelectedSchool(item);
                    showMdlUpdate();
                  "
                >
                  <v-icon color="blue-grey darken-3" small class="mr-1"
                    >mdi-pencil-circle-outline</v-icon
                  >
                  Editar
                </v-btn>
                <v-btn
                  class="my-1"
                  small
                  outlined
                  color="red"
                  @click="showDeleteModal(), getDetailsFromSelectedSchool(item)"
                >
                  <v-icon color="red" small class="mr-1"
                    >mdi-delete-circle-outline</v-icon
                  >
                  Eliminar
                </v-btn>
              </v-card-actions>
              <!-- end::large card body - Colegios o escuelas anteriores -->
            </v-card>
            <!-- begin::small card - Colegios o escuelas anteriores -->
            <v-card class="mx-auto rounded score-sheet-card-small" outlined>
              <div class="mt-4">
                <p class="text-overline text-center ma-0 mb-n2">
                  Previamente
                </p>
              </div>
              <!-- begin::small card icon - Colegios o escuelas anteriores -->
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-list-item-avatar
                    tile
                    size="80"
                    color="blue-grey lighten-5"
                    class="rounded-lg mx-auto"
                  >
                    <v-icon x-large :color="`${colors[item.id % 12]} lighten-2`"
                      >mdi-school</v-icon
                    >
                  </v-list-item-avatar>
                  <!-- end::small card icon - Colegios o escuelas anteriores -->
                </v-col>
              </v-row>
              <!-- begin::small card content - Colegios o escuelas anteriores -->
              <p class="text-h5 mb-0 text-center px-1">
                {{ item.School.name }}
              </p>
              <p
                class="ma-0 text-body-1 text-center"
                style="color:black !importante"
              >
                <span class="font-weight-medium">Desde </span
                >{{ item.gradeFrom }},
                <span class="font-weight-medium">hasta </span
                >{{ item.gradeTo }}.
              </p>
              <!-- end::small card content - Colegios o escuelas anteriores -->
              <!-- begin::small card body - Colegios o escuelas anteriores -->
              <v-card-actions
                class="d-flex justify-center flex-column blue-grey lighten-5"
              >
                <v-btn
                  class="mx-auto my-1"
                  block
                  outlined
                  color="light-blue darken-2"
                  @click="
                    getDetailsFromSelectedSchool(item);
                    showMdlDetails();
                  "
                >
                  <v-icon color="light-blue darken-2" small class="mr-1"
                    >mdi-information-outline</v-icon
                  >
                  Detalle
                </v-btn>

                <v-btn
                  class="mx-auto my-1"
                  block
                  outlined
                  color="blue-grey darken-3"
                  @click="
                    getMunicipality(item.departmentId);
                    getDetailsFromSelectedSchool(item);
                    showMdlUpdate();
                  "
                >
                  <v-icon color="blue-grey darken-3" small class="mr-1"
                    >mdi-pencil-circle-outline</v-icon
                  >
                  Editar
                </v-btn>
                <v-btn
                  class="mx-auto my-1"
                  block
                  outlined
                  color="red"
                  @click="showDeleteModal(), getDetailsFromSelectedSchool(item)"
                >
                  <v-icon color="red" small class="mr-1"
                    >mdi-delete-circle-outline</v-icon
                  >
                  Eliminar
                </v-btn>
              </v-card-actions>
              <!-- begin::small card body - Colegios o escuelas anteriores -->
            </v-card>
            <!-- end::small card - Colegios o escuelas anteriores -->
          </v-col>
          <!-- end::Card to show information -->
          <!-- begin::Create component - Colegios o escuelas anteriores -->
          <CreateSection3Modal
            ref="mdlCreateModal"
            :rules="rules"
            :section3="section3"
            :userPast="userPast"
            @selectedDepartment="getMunicipality"
            @create="createPreviousSchools"
            @selectSchool="getSchoolsByMunicipality"
          ></CreateSection3Modal>
          <!-- end::Create component - Colegios o escuelas anteriores -->
          <!-- begin::dialog informacion detallada - colegios o escuelas anteriores -->
          <DetailsSection3Modal
            ref="mdlShowModal"
            :selectedSchoolDetails="selectedPreviousSchoolDetails"
          ></DetailsSection3Modal>
          <!-- end::dialog informacion detallada - colegios o escuelas anteriores -->
          <!-- begin::Update component - Colegios o escuelas anteriores -->
          <UpdateSection3Modal
            ref="mdlUpdateModal"
            :rules="rules"
            :section3="section3"
            :formattedValues="formattedValues"
            @selectedDepartment="getMunicipality"
            @update="updatePreviousSchools"
            @getMunicipio="getMunicipality"
          >
          </UpdateSection3Modal>
          <!-- end::Update component - Colegios o escuelas anteriores -->
          <!-- Delete dialog - Colegios o escuelas anteriores -->
          <DeleteModal
            ref="mdlDeleteModal"
            dialogText="¿Está seguro que desea eliminar este registro de colegios o escuelas anteriores?"
            :selectedItemInformationSummary="
              selectedPreviousSchoolDetailsSummary
            "
            @delete="deletePreviuosSchools"
          ></DeleteModal>
          <!-- end Delete dialog - Colegios o escuelas anteriores -->
        </div>
      </v-col>
    </v-row>
  </v-sheet>
  <!-- end::Third section - Colegios o escuelas anteriores -->
</template>

<script>
  import CreateSection3Modal from "@/view/components/modals/section-3/CreateSection3Modal";
  import UpdateSection3Modal from "../../components/modals/section-3/UpdateSection3Modal";
  import DetailsSection3Modal from "@/view/components/modals/section-3/DetailsSection3Modal";
  import DeleteModal from "@/view/components/modals/DeleteModal";
  import municipalitiesRepository from "@/repositories/municipalitiesRepository";
  import thirdStepRepository from "@/repositories/steps/thirdStepRepository";
  import schoolRepository from "@/repositories/schoolRepository";

  export default {
    name: "SchoolsPrevious",
    components: {
      CreateSection3Modal,
      UpdateSection3Modal,
      DetailsSection3Modal,
      DeleteModal,
    },
    props: {
      section3: {
        type: Object,
      },
      rules: {
        type: Object,
      },
      step: {
        type: Number,
      },
    },
    data() {
      return {
        selectedPreviousSchoolDetails: {},
        selectedPreviousSchoolDetailsSummary: {},
        formattedValues: {},
        // progress: 1.5,
        userPast: {
          justAddedSchool: [],
          schools: [],
        },
      };
    },
    mounted() {
      this.getPreviousSchools();
    },
    methods: {
      getDetailsFromSelectedSchool(obj) {
        //creating non-reactive copy of data
        this.getMunicipality(obj.School.Municipality.DepartmentId);
        this.formattedValues = {
          id: obj.id,
          deparmentId: obj.School.Municipality.DepartmentId,
          municipalityId: obj.School.MunicipalityId,
          schoolId: obj.SchoolId,
          gradeFrom: obj.gradeFrom,
          gradeTo: obj.gradeTo,
          reasonChange: obj.reasonChange,
          municipio: [],
        };
        this.selectedPreviousSchoolDetails = { ...obj };
        this.selectedPreviousSchoolDetailsSummary = {
          id: obj.id,
          upperTitle: "EDUCACIÓN PREVIA",
          mainTitle: obj.School.name,
          subTitle:
            "Desde: " + obj.gradeFrom + " - " + " Hasta: " + obj.gradeTo,
          icon: "mdi-school",
          iconColor: "red darken-1",
        };
      },
      showMdlCreate() {
        this.$refs.mdlCreateModal.toggleModal();
      },
      showMdlUpdate() {
        this.$refs.mdlUpdateModal.toggleModal();
      },
      showMdlDetails() {
        this.$refs.mdlShowModal.toggleModal();
      },
      showDeleteModal() {
        this.$refs.mdlDeleteModal.toggleModal();
      },
      getMunicipality(deparmentId) {
        municipalitiesRepository
          .municipalitiesByDepartment(deparmentId)
          .then(({ data }) => {
            this.section3.schoolDetails.municipalities = data;
            this.formattedValues.municipio = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar obtener los datos",
            });
          });
      },
      getSchoolsByMunicipality(municipalityId) {
        schoolRepository
          .getSchoolsByMunicipality(municipalityId)
          .then(({ data }) => {
            this.section3.schoolDetails.schools = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar cargar los municipios",
            });
          });
      },
      //CRUD
      getPreviousSchools() {
        thirdStepRepository
          .getAllStepThree()
          .then(({ data }) => {
            this.userPast.schools = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar obtener los datos",
            });
          });
      },
      createPreviousSchools() {
        thirdStepRepository
          .createStepThree(this.userPast.justAddedSchool)
          .then(({ data }) => {
            this.fireToast({
              icon: "success",
              title: data.message,
            });
            this.getPreviousSchools();
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar guardar los datos",
            });
          });
      },
      updatePreviousSchools() {
        thirdStepRepository
          .updateStepThree(this.formattedValues.id, this.formattedValues)
          .then(({ data }) => {
            this.fireToast({
              icon: "success",
              title: data.message,
            });
            this.getPreviousSchools();
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar modificar los datos",
            });
          });
      },
      deletePreviuosSchools() {
        thirdStepRepository
          .deleteStepThree(this.selectedPreviousSchoolDetailsSummary.id)
          .then(({ data }) => {
            this.fireToast({
              icon: "success",
              title: data.message,
            });
            this.getPreviousSchools();
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar eliminar los datos",
            });
          });
      },
    },
  };
</script>
<style>
  @media only screen and (max-width: 925px) {
    .cards {
      width: 45% !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .cards {
      width: 100% !important;
    }
    .v-card__title {
      word-break: normal;
      text-align: center;
    }
  }
</style>
