<template>
  <v-dialog
    v-model="modalState"
    max-width="500px"
    transition="dialog-bottom-transition"
  >
    <!--Floating input form - Historial Familiar ITR -->
    <template v-slot:default="modalState">
      <v-card elevation="1">
        <!-- form header name - Historial Familiar ITR -->
        <v-card-title
          class="text-h5 light-blue darken-2 white--text text-center"
        >
          Detalles de medicamento prescrito seleccionado
        </v-card-title>
        <v-card-text class="pt-3 pb-1">
          <v-row>
            <v-col cols="12">
              <div class="grey lighten-4 rounded-lg pa-3">
                <div class="d-flex align-center mb-4 flex-column flex-sm-row">
                  <v-btn
                    color="red lighten-1"
                    elevation="2"
                    class="mb-2 mb-sm-0 white--text"
                    fab
                    x-small
                    ><v-icon>mdi-medical-bag</v-icon></v-btn
                  >
                  <p class="text-h5 mb-0 ml-sm-2 font-weight-medium">
                    Medicamento preescrito
                  </p>
                </div>
                <div class="mb-3">
                  <div class="grey--text text-overline">
                    Medicamento
                  </div>
                  <div class="text-body-1 font-weight-medium">
                    {{ selectedItemInformation.Medicine.name }}
                  </div>
                </div>
                <div class="mb-3">
                  <div class="grey--text text-overline">Dosis</div>
                  <div class="text-body-1 font-weight-medium">
                    {{ selectedItemInformation.dose }}
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end py-3">
          <v-spacer></v-spacer>
          <!-- cancel btn - Historial Familiar ITR -->
          <v-btn
            color="light-blue darken-4"
            outlined
            text
            @click="modalState.value = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
  export default {
    name: "DetailsModal",
    data() {
      return {
        modalState: false,
      };
    },
    props: {
      selectedItemInformation: {
        type: Object,
      },
    },
    methods: {
      toggleModal() {
        this.modalState = !this.modalState;
      },
    },
  };
</script>
