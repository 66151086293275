import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllMaritalStatus = () => {
  return ApiService.get("marital-statuses");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getMaritalStatus = (id) => {
  return ApiService.get(`marital-statuses/${id}`);
};

export default {
  getAllMaritalStatus,
  getMaritalStatus,
};
