<template>
  <v-dialog
    v-model="dialogState"
    max-width="600px"
    transition="dialog-bottom-transition"
    persistent
    scrollable
  >
    <!--Floating input form - Historial Familiar ITR -->
    <v-card elevation="1">
      <!-- form header name - Historial Familiar ITR -->
      <v-card-title class="text-h5 light-blue darken-4 white--text text-center">
        Agregando familiares estudiando actualmente
      </v-card-title>
      <v-card-text class="my-0 py-0 mt-5">
        <v-form ref="formCreateCurrent">
          <v-container>
            <v-row>
              <!-- carnet - Actualmente - Historial Familiar ITR -->
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group name="carnet" :validator="$v.tempUserData.code">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    autocomplete="null"
                    v-model="tempUserData.code"
                    label="Carnet"
                    color="light-blue darken-4"
                    required
                    outlined
                    v-mask="'20######'"
                  ></v-text-field>
                </form-group>
              </v-col>
              <!-- Nombre completo de familiar - Actualmente - Historial Familiar ITR -->
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group name="nombre" :validator="$v.tempUserData.name">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    autocomplete="null"
                    v-model="tempUserData.name"
                    label="Nombre completo"
                    color="light-blue darken-4"
                    required
                    outlined
                  ></v-text-field>
                </form-group>
              </v-col>
              <!-- Próximo grado a estudiar - Actualmente - Historial Familiar ITR -->
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group name="grado" :validator="$v.tempUserData.gradeId">
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :items="section4.personalDetails.grade"
                    item-text="name"
                    item-value="id"
                    v-model="tempUserData.gradeId"
                    label="Grado que estudiará en el proximo año"
                    color="light-blue darken-4"
                    required
                    outlined
                    @change="resetSpeciality(), setSpecialityToGrades()"
                  ></v-select>
                </form-group>
              </v-col>
              <!-- Especialidad a estudiar - Actualmente - Historial Familiar ITR -->
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group
                  name="especialidad"
                  :validator="$v.tempUserData.specialityId"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :items="section4.personalDetails.specialities"
                    item-text="name"
                    item-value="id"
                    v-model="tempUserData.specialityId"
                    label="Especialidad que estudiará el proximo año"
                    color="light-blue darken-4"
                    required
                    outlined
                    :disabled="disabledGrade"
                  ></v-select>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end pt-0 pb-3">
        <v-spacer></v-spacer>
        <!-- cancel btn - Historial Familiar ITR -->
        <v-btn
          color="light-blue darken-4"
          outlined
          text
          @click="showMdlConfirm()"
          >Cancelar</v-btn
        >
        <!-- save data btn - Historial Familiar ITR -->
        <v-btn
          color="light-blue darken-4"
          class="white--text"
          depressed
          @click="validate()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
    <ConfirmationModal
      ref="confirmationModal"
      v-on:confirmData="toggleModal"
      v-on:resetForm="resetForm"
    ></ConfirmationModal>
  </v-dialog>
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import ConfirmationModal from "@/view/components/modals/ConfirmationModal";
export default {
  name: "CreateCurrentlyFamilyHistoryModal",
  components: {
    ConfirmationModal,
  },
  props: {
    section4: {
      type: Object,
    },
    familyMembers: {
      type: Object,
    },
  },
  data() {
    return {
      dialogState: false,
      tempUserData: {
        code: null,
        name: null,
        gradeId: null,
        specialityId: null,
      },
      disabledGrade: false,
    };
  },
  methods: {
    toggleModal() {
      this.dialogState = !this.dialogState;
    },
    validate() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar los campos requeridos",
        });
        return;
      }
      this.saveData();
      this.resetForm();
    },
    resetSpeciality() {
      this.tempUserData.specialityId = null;
    },
    setSpecialityToGrades() {
      if ([1, 2, 3].includes(this.tempUserData.gradeId)) {
        this.tempUserData.specialityId = 8;
        this.disabledGrade = true;
      } else {
        this.disabledGrade = false;
      }
    },
    saveData() {
      let data = {
        code: Number(this.tempUserData.code),
        name: this.tempUserData.name,
        gradeId: this.tempUserData.gradeId,
        specialityId: this.tempUserData.specialityId,
        isActiveStudent: true,
      };

      this.familyMembers.currentlyStudyingJustAdded = data;
      this.$emit("createActive");
      this.dialogState = false;
    },
    showMdlConfirm() {
      this.$refs.confirmationModal.toggleModal();
    },
    resetForm() {
      this.$v.$reset();
      this.tempUserData = {
        code: null,
        name: null,
        gradeId: null,
        specialityId: null,
      };
    },
  },
  validations: {
    tempUserData: {
      code: { required },
      name: { required, maxLength: maxLength(255) },
      gradeId: { required },
      specialityId: { required },
    },
  },
};
</script>
<style>
.v-card__title {
  word-break: normal;
}
</style>
