<template>
  <v-dialog
    v-model="dialogDelete"
    transition="dialog-bottom-transition"
    max-width="600px"
    scrollable
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="red darken-1" dark
          ><p class="font-weight-medium text-h5 mb-0 mt-4 mt-sm-1">
            Eliminar
          </p>
        </v-toolbar>

        <div class="pa-3">
          <p class="mb-0 text-h6 font-weight-normal text-center mx-sm-10">
            {{ dialogText }}
          </p>
        </div>
        <v-container>
          <v-row class="d-flex justify-center align-center">
            <v-col cols="12" sm="6" class="pt-0 px-10 px-sm-1">
              <SmallSummaryCard
                :upperTitle="selectedItemInformationSummary.upperTitle"
                :mainTitle="selectedItemInformationSummary.mainTitle"
                :subTitle="selectedItemInformationSummary.subTitle"
                :iconColor="selectedItemInformationSummary.iconColor"
                :icon="selectedItemInformationSummary.icon"
                :dataObj="selectedItemInformationSummary"
              ></SmallSummaryCard>
            </v-col>
          </v-row>
        </v-container>
        <v-divider class></v-divider>
        <v-card-actions class="justify-end pt-0 pb-3">
          <v-btn color="red" outlined text @click="dialog.value = false"
            >cancelar</v-btn
          >
          <v-btn
            color="red"
            class="white--text"
            depressed
            :loading="isLoading"
            @click="confirmDelete"
            >si, eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
  import SmallSummaryCard from "@/view/components/SmallSummaryCard";
  export default {
    name: "DeleteModal",

    props: {
      dialogText: {
        type: String,
      },
      selectedItemInformationSummary: {
        type: [Object, Array],
      },
    },
    components: {
      SmallSummaryCard,
    },
    data() {
      return {
        dialogDelete: false,
        isLoading: false,
      };
    },

    methods: {
      confirmDelete() {
        this.isLoading = true;
        this.$emit("delete", this.selectedItemInformationSummary.id);
        this.toggleModal();
        this.isLoading = false;
      },
      toggleModal() {
        this.dialogDelete = !this.dialogDelete;
      },
    },
  };
</script>
<style>
  .v-dialog {
    max-height: 100% !important;
  }
</style>
