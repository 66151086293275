import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllMunicipalities = () => {
    return ApiService.get("municipalities");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getMunicipalities = (id) => {
    return ApiService.get("municipalities", id);
};

/**
 * GET request to fetch a specific data
 * @param deparmentId
 * @returns {*}
 */
export const municipalitiesByDepartment = (deparmentId) => {
    return ApiService.get(`municipalities/department/${deparmentId}`);
};

export default {
    getAllMunicipalities,
    getMunicipalities,
    municipalitiesByDepartment,
};
