import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllEconomicManagers = () => {
  return ApiService.get("economic-managers");
};

/**
 * GET request to fetch all data
 * @param id
 * @returns {*}
 */
export const getEconomicManager = (id) => {
  return ApiService.get(`economic-managers/${id}`);
};

export default {
  getAllEconomicManagers,
  getEconomicManager,
};
