import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllParentingSituations = () => {
    return ApiService.get("parenting-situations");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getParentingSituation = (id) => {
    return ApiService.get("parenting-situations", id);
};


export default {
    getAllParentingSituations,
    getParentingSituation,
};
