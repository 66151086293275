<template>
  <div class="">
    <!-- begin::Page main title -->
    <v-card>
      <div
        class="d-flex justify-center align-top align-sm-center pa-3 rounded-lg rounded-b-0 header-bg-color"
        cols="12"
      >
        <v-row class="py-5 px-5 px-md-10 header-bg-img">
          <v-col cols="12" md="8">
            <h2
              class="display-2 font-weight-medium white--text text-center text-md-left mb-4"
            >
              Formulario de actualización de datos
            </h2>
            <p class="text-h5 white--text mb-0 text-center text-md-left">
              <span class="font-weight-medium">{{
                `${currentUserPersonalInfo.firstName} ${currentUserPersonalInfo.lastName}`
              }}</span>
            </p>
            <p class="text-h6 white--text mb-0 text-center text-md-left">
              <span class="font-weight-medium">{{
                currentUserPersonalInfo.code
              }}</span>
            </p>
            <p class="text-body-1 mt-2 white--text text-center text-md-left">
              Asegurate de corroborar la información de los 7 pasos y de
              actualizar información en caso sea necesario.
            </p>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- begin::Page main title -->
    <!-- begin:: Stepper  -->
    <v-stepper v-model="e1" non-linear>
      <!-- begin:: Stepper header -->

      <v-stepper-header id="content">
        <template v-for="(n, index) in stepTooltip">
          <v-tooltip
            :key="`${index}-step`"
            bottom
            color="light-blue darken-4 white--text"
          >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-stepper-step
                  :complete="e1 > index + 1"
                  :step="index + 1"
                  color="light-blue darken-4"
                  class="step"
                >
                  Paso {{ index + 1 }}
                </v-stepper-step></span
              >
            </template>
            <span>{{ n }}</span>
          </v-tooltip>
          <v-divider
            v-if="index + 1 !== stepTooltip.length"
            :key="index + 1"
          ></v-divider>
        </template>
      </v-stepper-header>
      <!-- end:: Stepper header -->
      <!-- begin:: Stepper items -->
      <v-stepper-items>
        <v-stepper-content class="px-1 px-sm-4" step="1">
          <RegisterStudentEconomicManager
            ref="registerStudentEconomicManager"
            :step="this.e1"
            :section7="section7"
            @next="nextStep(1)"
            @loading="loadingButton"
            @stopLoading="stopLoadingButton"
          ></RegisterStudentEconomicManager>
          <v-card-actions class="justify-end pt-0 pb-3">
            <v-btn
              :loading="btnLoading"
              color="success white--text"
              @click="$refs.registerStudentEconomicManager.validate()"
            >
              Siguiente
              <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <!-- first Stepper::Información del Aspirante -->
        <v-stepper-content class="px-1 pt-0 px-sm-4 pt-sm-2" step="2">
          <AplicantInformation
            :section1="section1"
            ref="aplicant"
            :step="this.e1"
            @next="
              nextStep(2);
              $refs.address.getAdressInformation();
            "
            @loading="loadingButton"
            @stopLoading="stopLoadingButton"
          ></AplicantInformation>

          <v-card-actions class="justify-end pt-0 pb-3">
            <v-btn
              color="light-blue darken-4"
              outlined
              text
              @click="previousStep(2)"
            >
              <v-icon color="light-blue darken-4" small class="mr-1"
                >mdi-arrow-left-bold</v-icon
              >
              Regresar
            </v-btn>
            <v-btn
              :loading="btnLoading"
              color="light-blue darken-4 white--text"
              @click="
                $refs.aplicant.validate(), $refs.address.getAdressInformation()
              "
            >
              Siguiente
              <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <!-- second Stepper::Direccion -->
        <v-stepper-content class="px-1 pt-0 px-sm-4 pt-sm-2" step="3">
          <Address
            :info="currentUserPersonalInfo"
            :section2="section2"
            ref="address"
            :step="this.e1"
            @next="nextStep(3)"
            @loading="loadingButton"
            @stopLoading="stopLoadingButton"
          ></Address>

          <v-card-actions class="justify-end pt-0 pb-3">
            <v-btn
              color="light-blue darken-4"
              outlined
              text
              @click="previousStep(3)"
            >
              <v-icon color="light-blue darken-4" small class="mr-1"
                >mdi-arrow-left-bold</v-icon
              >
              Regresar
            </v-btn>
            <v-btn
              :loading="btnLoading"
              color="light-blue darken-4 white--text"
              @click="$refs.address.validate()"
            >
              Siguiente
              <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>

        <v-stepper-content class="px-1 pt-0 px-sm-4 pt-sm-2" step="4">
          <FamilyHistory
            :section4="section4"
            ref="familyH"
            :step="this.e1"
          ></FamilyHistory>

          <v-card-actions class="justify-end pt-0 pb-3">
            <v-btn
              color="light-blue darken-4"
              outlined
              text
              @click="previousStep(4)"
            >
              <v-icon color="light-blue darken-4" small class="mr-1"
                >mdi-arrow-left-bold</v-icon
              >
              Regresar
            </v-btn>
            <v-btn
              color="light-blue darken-4 white--text"
              @click="
                nextStep(4),
                  $refs.specialConditions.getStudentsConditionsExtraInformation()
              "
            >
              Siguiente
              <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <!-- fifth Stepper::Condiciones especiales -->
        <v-stepper-content class="px-1 pt-0 px-sm-4 pt-sm-2" step="5">
          <SpecialConditions
            :section5="section5"
            ref="specialConditions"
            :step="this.e1"
            @next="nextStep(5)"
            @loading="loadingButton"
            @stopLoading="stopLoadingButton"
          ></SpecialConditions>

          <v-card-actions class="justify-end pt-0 pb-3">
            <v-btn
              color="light-blue darken-4"
              outlined
              text
              @click="previousStep(5)"
            >
              <v-icon color="light-blue darken-4" small class="mr-1"
                >mdi-arrow-left-bold</v-icon
              >
              Regresar
            </v-btn>
            <v-btn
              :loading="btnLoading"
              color="light-blue darken-4 white--text"
              @click="
                $refs.specialConditions.validate();
                $refs.familyInfo.getGeneralFamilyInformation();
              "
            >
              Siguiente
              <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <!-- sixth Stepper::Información Familiar -->
        <v-stepper-content class="px-1 pt-0 px-sm-4 pt-sm-2" step="6">
          <FamilyInformation
            :section6="section6"
            ref="familyInfo"
            :step="this.e1"
            @next="nextStep(6)"
            @loading="loadingButton"
            @stopLoading="stopLoadingButton"
          >
          </FamilyInformation>

          <v-card-actions class="justify-end pt-0 pb-3">
            <v-btn
              color="light-blue darken-4"
              outlined
              text
              @click="previousStep(6)"
            >
              <v-icon color="light-blue darken-4" small class="mr-1"
                >mdi-arrow-left-bold</v-icon
              >
              Regresar
            </v-btn>
            <v-btn
              :loading="btnLoading"
              color="light-blue darken-4 white--text"
              @click="$refs.familyInfo.validate()"
            >
              Siguiente
              <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
        <!-- seventh Stepper::Información Familiar Detallada -->
        <v-stepper-content class="px-1 pt-0 px-sm-4 pt-sm-2" step="7">
          <DetailedFamilyInformation
            :section7="section7"
            ref="detailedFamily"
            :step="this.e1"
            @next="finalize()"
          >
          </DetailedFamilyInformation>

          <v-card-actions class="justify-end pt-0 pb-3">
            <v-btn
              color="light-blue darken-4"
              outlined
              text
              @click="previousStep(7)"
            >
              <v-icon color="light-blue darken-4" small class="mr-1"
                >mdi-arrow-left-bold</v-icon
              >
              Regresar
            </v-btn>
            <v-btn
              color="light-blue darken-4 white--text"
              @click="$refs.detailedFamily.validate()"
            >
              {{
                this.e1 == this.stepTooltip.length
                  ? "Enviar formulario"
                  : "Siguiente"
              }}
              <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
      <!-- end:: Stepper items -->
    </v-stepper>
    <v-dialog v-model="finalModalState" max-width="390" persistent class="pa-5">
      <v-card class="pb-2">
        <v-container class="text-h5 text-center">
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-center mb-5 rounded-lg">
                <div
                  class="card-icon-container orange lighten-4 d-flex align-center justify-center rounded-circle pa-5 mx-auto"
                >
                  <v-icon color="orange" large
                    >mdi-alert-rhombus-outline</v-icon
                  >
                </div>
              </div>
            </v-col>
          </v-row>

          <p class="mb-2 mx-auto">Formulario completado</p>
          <p class="mb-0 mx-auto text-subtitle-1">
            Has finalizado el formulario de actualización de datos, se te
            redigirá al Portal Estudiantil nuevamente y si deseas seguir
            navegando deberás iniciar sesión nuevamente.
          </p>
        </v-container>

        <v-card-actions class="action-buttons-container">
          <v-spacer></v-spacer>

          <v-btn color="blue-grey darken-4" text @click="toggleModal()">
            no, regresar
          </v-btn>

          <v-btn
            @click="goToStudentsPortal()"
            color="blue-grey darken-4 white--text update-btn"
          >
            terminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
//Section components
import AplicantInformation from "@/view/components/sections/Section-1.vue";
import Address from "@/view/components/sections/Section-2.vue";
import SchoolsPrevious from "@/view/components/sections/Section-3.vue";
import FamilyHistory from "@/view/components/sections/Section-4.vue";
import SpecialConditions from "@/view/components/sections/Section-5.vue";
import FamilyInformation from "@/view/components/sections/Section-6.vue";
import DetailedFamilyInformation from "@/view/components/sections/Section-7.vue";
import RegisterStudentEconomicManager from "@/view/components/sections/Section-8.vue";
//Import repositories
import genderRepository from "@/repositories/genderRepository";
import nationalitiesRepository from "@/repositories/nationalitiesRepository";
import sportRepository from "@/repositories/sportRepository";
import religionsRepository from "@/repositories/religionsRepository";
import deparmentsRepository from "@/repositories/deparmentsRepository";
import transportRepository from "@/repositories/transportRepository";
import schoolRepository from "@/repositories/schoolRepository";
import kinshipRepository from "@/repositories/kinshipRepository";
import parentingSituationsRepository from "@/repositories/parentingSituationsRepository";
import maritalStatusRepository from "@/repositories/maritalStatusRepository";
import coexistencesRepository from "@/repositories/coexistencesRepository";
import economicManagerRepository from "@/repositories/economicManagerRepository";
import salaryRangeRepository from "@/repositories/salaryRangeRepository";
import housingTypeRepository from "@/repositories/housingTypeRepository";
import medicineRepository from "@/repositories/medicineRepository";
import diseasesRepository from "@/repositories/diseasesRepository";
import stepSetter from "@/repositories/steps/stepSetter";
import { VERIFY_AUTH } from "@/core/services/store/auth.module.js";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "Tabs",
  title: "Actualización de datos | ITR",

  components: {
    AplicantInformation,
    Address,
    SchoolsPrevious,
    FamilyHistory,
    SpecialConditions,
    FamilyInformation,
    DetailedFamilyInformation,
    RegisterStudentEconomicManager,
  },

  data() {
    return {
      e1: 1,
      stepTooltip: [
        "Registro de sostenedor económico",
        "Información general del estudiante",
        "Información del lugar donde reside el estudiante",
        "Historial de familiares ITR",
        "Condiciones especiales",
        "Información de situación familiar",
        "Información familiar detallada",
      ],
      btnLoading: false,
      //variables

      //section1 - Información del aspirante
      section1: {
        userCommonDetails: {
          userDefaultProfilePicture: "media/section-1/userimg.png",
          gender: [],
          nationality: [],
          sports: [],
          religions: [],
          sacraments: [],
        },
      },
      //section2 - Dirección
      section2: {
        commonAddressInformation: {
          departments: [],
          municipalities: [],
          transport: [],
        },
      },
      //section3 - Colegios o escuelas anteriores
      section3: {
        schoolDetails: {
          departments: [],
          municipalities: [],
          schools: [],
          grade: [],
        },
      },
      //section4 - Historial Familiar ITR
      section4: {
        personalDetails: {
          grade: [],
          specialities: [],
          kinships: [],
        },
      },
      //section5 - Condiciones Especiales
      section5: {
        conditionsDetails: {
          availablePrescribedDrugs: [],
          drugsForAllergies: [],
          diseases: [],
        },
      },
      //section6 - Información familiar
      section6: {
        familyDetails: {
          parentingSituations: [],
          coexistences: [],
          economicManager: [],
          salaryRange: [],
          housingType: [],
        },
      },
      //section7 - Información familiar detallda
      section7: {
        personalDetails: {
          departments: [],
          municipalities: [],
          nationality: [],
          kinships: [],
          maritalStatus: [],
        },
      },

      finalModalState: false,
      //stages
      stages: {
        data: [],
        isLoading: "",
        isLoadingCard: "",
      },
      //Form progress
      // stepProgress: 0,
    };
  },
  mounted() {
    this.getGenders();
    this.getNationalities();
    this.getSports();
    this.getReligions();
    this.getDeparments();
    this.getTransports();
    this.getSchools();
    this.getKinships();
    this.getParentingSituations();
    this.getMaritalStatus();
    this.getCoexistences();
    this.getMedicines();
    this.getDiseases();
    this.getEconomicManager();
    this.getSalaryRange();
    this.getHousingType();
    this.getGrades();
    this.getSpecialities();
  },
  methods: {
    loadingButton() {
      this.btnLoading = true;
    },
    stopLoadingButton() {
      this.btnLoading = false;
    },
    finalize() {
      this.registerStep(7);
      this.toggleModal();
    },
    goToStudentsPortal() {
      window.location = this.studentFormUrl;
    },
    toggleModal() {
      this.finalModalState = !this.finalModalState;
    },
    //nextStep - Evalua que la variable valid sea true, si es mueve el step
    nextStep(n) {
      if (n == 3 || n == 1) {
        if (JwtService.getToken()) {
          this.$store.dispatch(VERIFY_AUTH);
        }
      }
      if (n === this.steps) {
        this.e1 = 1;
        this.top();
        this.right();
      } else {
        this.e1 = n + 1;
        this.top();
        this.right();
        this.registerStep(this.e1);
      }
    },
    //previousStep - Nos regresa un step atras del actual
    previousStep(n) {
      if (n == 3 || n == 1) {
        if (JwtService.getToken()) {
          this.$store.dispatch(VERIFY_AUTH);
        }
      }
      if (n === this.steps) {
        this.e1 = 1;
        this.top();
        this.right();
      } else {
        this.e1 = n - 1;
        this.top();
        this.right();
      }
    },
    //top - Nos hace un scroll hasta arriba.
    top() {
      window.scrollTo({
        top: 150,
        left: 0,
        behavior: "smooth",
      });
    },
    //right - Se encarga de mover a la derecha los steps (mas notable en mobile)
    right() {
      const content = document.querySelector("#content");
      content.scrollLeft += 70;
    },
    getGenders() {
      genderRepository
        .getAllGenders()
        .then(({ data }) => {
          this.section1.userCommonDetails.gender = data;
        })
        .catch(() => {});
    },
    getNationalities() {
      nationalitiesRepository
        .getAllNationalities()
        .then(({ data }) => {
          this.section1.userCommonDetails.nationality = data;
          this.section7.personalDetails.nationality = data;
        })
        .catch(() => {
          console.error("ERROR getNationalities");
        });
    },
    getSports() {
      sportRepository
        .getAllSports()
        .then(({ data }) => {
          this.section1.userCommonDetails.sports = data;
        })
        .catch(() => {
          console.error("ERROR getSport");
        });
    },
    getReligions() {
      religionsRepository
        .getAllReligions()
        .then(({ data }) => {
          this.section1.userCommonDetails.religions = data;
        })
        .catch(() => {
          console.error("ERROR getReligions");
        });
    },
    getDeparments() {
      deparmentsRepository
        .getAllDepartments()
        .then(({ data }) => {
          this.section2.commonAddressInformation.departments = data;
          this.section3.schoolDetails.departments = data;
          this.section7.personalDetails.departments = data;
        })
        .catch(() => {
          console.error("ERROR getDeparments");
        });
    },
    getTransports() {
      transportRepository
        .getAllTransports()
        .then(({ data }) => {
          this.section2.commonAddressInformation.transport = data;
        })
        .catch(() => {
          console.error("ERROR getTransports");
        });
    },
    getSchools() {
      schoolRepository
        .getAllSchools()
        .then(({ data }) => {
          this.section3.schoolDetails.schools = data;
        })
        .catch(() => {
          console.error("ERROR getSchools");
        });
    },
    getKinships() {
      kinshipRepository
        .getAllKinships()
        .then(({ data }) => {
          this.section4.personalDetails.kinships = data;
          this.section7.personalDetails.kinships = data;
        })
        .catch(() => {
          console.error("ERROR getKinships");
        });
    },
    getParentingSituations() {
      parentingSituationsRepository
        .getAllParentingSituations()
        .then(({ data }) => {
          this.section6.familyDetails.parentingSituations = data;
        })
        .catch(() => {
          console.error("ERROR getParentingSituation");
        });
    },
    getMaritalStatus() {
      maritalStatusRepository
        .getAllMaritalStatus()
        .then(({ data }) => {
          this.section7.personalDetails.maritalStatus = data;
        })
        .catch(() => {
          console.error("ERROR getMaritalStatus");
        });
    },
    getCoexistences() {
      coexistencesRepository
        .getAllCoexistences()
        .then(({ data }) => {
          this.section6.familyDetails.coexistences = data;
        })
        .catch(() => {
          console.error("ERROR getCoexistences");
        });
    },
    getEconomicManager() {
      economicManagerRepository
        .getAllEconomicManagers()
        .then(({ data }) => {
          this.section6.familyDetails.economicManager = data;
        })
        .catch(() => {
          console.error("ERROR getEconomicManager");
        });
    },
    getSalaryRange() {
      salaryRangeRepository
        .getAllSalaryRanges()
        .then(({ data }) => {
          this.section6.familyDetails.salaryRange = data;
        })
        .catch(() => {
          console.error("ERROR getSalaryRange");
        });
    },
    getHousingType() {
      housingTypeRepository
        .getAllHousingTypes()
        .then(({ data }) => {
          this.section6.familyDetails.housingType = data;
        })
        .catch(() => {
          console.error("ERROR getHousingType");
        });
    },
    getMedicines() {
      medicineRepository
        .getAllMedicines()
        .then(({ data }) => {
          this.section5.conditionsDetails.availablePrescribedDrugs = data;
          this.section5.conditionsDetails.drugsForAllergies = data;
        })
        .catch(() => {
          console.error("ERROR getMedicines");
        });
    },
    getDiseases() {
      diseasesRepository
        .getAllDiseases()
        .then(({ data }) => {
          this.section5.conditionsDetails.diseases = data;
        })
        .catch(() => {
          console.error("ERROR getMedicines");
        });
    },
    getGrades() {
      let baseUrl;
      baseUrl = process.env.API_GE_URL;
      fetch(`${baseUrl}/grades`)
        .then((response) => response.json())
        .then((json) =>
          json.forEach((element) => {
            this.section4.personalDetails.grade.push(element);
          })
        );
    },
    getSpecialities() {
      let baseUrl;
      baseUrl = process.env.API_GE_URL;
      fetch(`${baseUrl}/specialities`)
        .then((response) => response.json())
        .then((json) =>
          json.forEach((element) => {
            this.section4.personalDetails.specialities.push(element);
          })
        );
    },
    registerStep(step) {
      stepSetter
        .setStep({ formStatus: step })
        .then(() => {})
        .catch(() => {
          console.error("Error al registrar el paso");
        });
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    studentFormUrl() {
      let portalURL;
      if (process.env.APP_ENV === "production") {
        portalURL = process.env.GE_PORTAL_STUDENT;
      } else if (process.env.APP_ENV === "development") {
        portalURL = process.env.GE_PORTAL_STUDENT_LOCAL;
      } else {
        portalURL = process.env.GE_PORTAL_STUDENT_LOCAL;
      }
      return portalURL;
    },
  },
};
</script>
<style>
.v-expansion-panel-content__wrap {
  padding: 0 0px 10px !important;
}

.v-banner__wrapper {
  padding: 0 !important;
}

.v-banner__content {
  padding: 0 !important;
}

.user__profile-picture {
  aspect-ratio: 1/1 !important;
  max-width: 80% !important;
  background-size: cover;
}

.cartilla-vac {
  max-width: 230px !important;
}

.v-label {
  margin-bottom: 0;
}

.v-file-input__text {
  padding-top: 3px !important;
}

.header-bg-color {
  background: #000428;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #2097ff, #203d9e) !important;
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #2097ff, #203d9e) !important;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.header-bg-img {
  background-color: #ffffff00;
  background-image: url("https://www.transparenttextures.com/patterns/maze-black.png");
  background-repeat: repeat;
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

#content {
  overflow-x: auto;
  flex-wrap: nowrap !important;
}

.score-sheet-card-small {
  display: none;
}

@media (max-width: 450px) {
  .score-sheet-card-large {
    display: none;
  }

  .score-sheet-card-small {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .step {
    margin: 0 15px;
  }
}

@media only screen and (min-width: 960px) {
  .step {
    margin: 0 10px;
  }
}
</style>
