import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSacraments = () => {
    return ApiService.get("sacraments");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getSacraments = (id) => {
    return ApiService.get("sacraments", id);
};

/**
 * GET request to fetch a specific data
 * @param religionId
 * @returns {*}
 */
export const sacramentsByReligions = (religionId) => {
    return ApiService.get(`sacraments/religion/${religionId}`);
};


export default {
    getAllSacraments,
    getSacraments,
    sacramentsByReligions,
};
