import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSports = () => {
  return ApiService.get("sports");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getSport = (id) => {
  return ApiService.get(`sports/${id}`);
};

export default {
  getAllSports,
  getSport,
};
