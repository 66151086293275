import ApiService from "@/core/services/api.service";

export const getAllStudentManagers = () => {
  return ApiService.get("student-managers");
};

export const getStudentManagerById = (id) => {
  return ApiService.get(`student-managers/${id}`);
};

export const getStudentManagerInformation = (dui) => {
  return ApiService.get(`student-managers/managerId/${dui}`);
};

export const addStudentManager = (payload) => {
  return ApiService.post("student-managers", payload);
};

export const createStudentManager = (payload)=>{
  return ApiService.post("student-managers/manager", payload)
}

export const updateStudentManager = (id, payload) => {
  return ApiService.put(`student-managers/${id}`, payload);
};

export const deleteStudentManager = (id) => {
  return ApiService.delete(`student-managers/${id}`);
};
export default {
  getAllStudentManagers,
  getStudentManagerById,
  addStudentManager,
  updateStudentManager,
  deleteStudentManager,
  getStudentManagerInformation,
  createStudentManager
};
