import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllReligions = () => {
    return ApiService.get("religions");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getReligion = (id) => {
    return ApiService.get("religions", id);
};


export default {
    getAllReligions,
    getAllReligions,
};
