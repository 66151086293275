<template>
  <!-- begin::Second section - Dirección -->
  <v-sheet elevation="0" class="rounded-lg mt-4 pa-3">
    <!-- begin::card header -->
    <v-row
      class="d-flex flex-column-reverse flex-sm-row blue accent-5 mb-8 rounded white--text"
    >
      <!-- begin::section title - Dirección -->
      <v-col
        cols="12"
        class="d-flex align-center justify-center justify-md-start flex-column flex-md-row"
      >
        <div class="d-flex flex-row justify-start align-items-center">
          <p class="text-h5 mb-0">Paso</p>
          <v-icon color="white" class="ml-2 mr-md-2 mr-0"
            >mdi-numeric-{{ step }}-circle-outline</v-icon
          >
        </div>
        <p class="ma-0 text-h6 text-center text-md-left mt-2 mt-sm-0">
          <span class="font-weight-medium text-h5 mr-1 d-none d-md-inline"
            >-</span
          >
          Información del lugar donde reside el estudiante
        </p>
      </v-col>
      <!-- end::step - Dirección -->
    </v-row>

    <!-- begin::card body -->
    <v-form ref="createForm_section2">
      <v-row>
        <!-- Departamento - Dirección -->
        <v-col cols="12" sm="6" md="6" class="pa-0 pa-sm-2">
          <form-group
            name="departamento"
            :validator="$v.userAddressDetails.departmentId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section2.commonAddressInformation.departments"
              item-text="name"
              item-value="id"
              color="light-blue darken-4"
              v-model="userAddressDetails.departmentId"
              label="Departamento"
              outlined
              @change="
                getMunicipality(userAddressDetails.departmentId);
                resetMunicipality();
              "
            ></v-select>
          </form-group>
        </v-col>
        <!-- Municipio - Dirección -->
        <v-col cols="12" sm="6" md="6" class="pa-0 pa-sm-2">
          <form-group
            name="municipio"
            :validator="$v.userAddressDetails.municipalityId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section2.commonAddressInformation.municipalities"
              item-text="name"
              item-value="id"
              :disabled="!userAddressDetails.departmentId"
              label="Municipio"
              color="light-blue darken-4"
              v-model="userAddressDetails.municipalityId"
              outlined
            ></v-select>
          </form-group>
        </v-col>
        <!-- Dirección - Dirección -->
        <v-col cols="12" xl="12" class="pa-0 pa-sm-2">
          <form-group
            name="dirección"
            :validator="$v.userAddressDetails.address"
          >
            <v-textarea
              slot-scope="{ attrs }"
              v-bind="attrs"
              autocomplete="null"
              outlined
              rows="1"
              label="Dirección"
              color="light-blue darken-4"
              :counter="255"
              v-model="userAddressDetails.address"
            ></v-textarea>
          </form-group>
        </v-col>
        <!-- Medio de transporte - Dirección -->
        <v-col cols="12" sm="6" md="6" class="pa-0 pa-sm-2">
          <form-group
            name="medio de transporte"
            :validator="$v.userAddressDetails.transportId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section2.commonAddressInformation.transport"
              item-text="name"
              item-value="id"
              label="Medio de transporte"
              color="light-blue darken-4"
              v-model="userAddressDetails.transportId"
              outlined
            ></v-select>
          </form-group>
        </v-col>
        <!-- Distancia entre hogar e ITR - Dirección -->
        <v-col cols="12" sm="6" md="6" class="pa-0 pa-sm-2">
          <form-group
            name="distancia hasta el ITR"
            :validator="$v.userAddressDetails.distance"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              autocomplete="null"
              label="Distancia hasta el ITR"
              color="light-blue darken-4"
              suffix="km"
              v-model="userAddressDetails.distance"
              type="number"
              outlined
            ></v-text-field>
          </form-group>
        </v-col>
      </v-row>
    </v-form>
  </v-sheet>
  <!-- end::Second section - Dirección -->
</template>

<script>
import { required, requiredIf, maxValue } from "vuelidate/lib/validators";
import municipalitiesRepository from "@/repositories/municipalitiesRepository";
import secondStepRepository from "@/repositories/steps/secondStepRepository";
import { mapGetters } from "vuex";
export default {
  name: "Address",
  props: {
    info: { type: Object },
    section2: {
      type: Object,
    },
    step: {
      type: Number,
    },
  },
  data() {
    return {
      userAddressDetails: {},
      // progress: 1.5,
    };
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  methods: {
    getAdressInformation() {
      this.userAddressDetails = {
        address:
          this.currentUserPersonalInfo.Municipality !== null
            ? this.info.address
            : undefined,
        departmentId:
          this.currentUserPersonalInfo.Municipality !== null
            ? this.currentUserPersonalInfo.Municipality.departmentId
            : undefined,
        municipalityId:
          this.currentUserPersonalInfo.Municipality !== null
            ? this.info.municipalityId
            : undefined,
        transportId:
          this.currentUserPersonalInfo.Municipality !== null
            ? this.info.transportId
            : undefined,
        distance:
          this.currentUserPersonalInfo.Municipality !== null
            ? this.info.distance
            : undefined,
      };

      // console.log("id departamento", this.userAddressDetails.departmentId);
      this.getMunicipality(this.userAddressDetails.departmentId);
    },
    getMunicipality(departmentId) {
      if (!!departmentId) {
        municipalitiesRepository
          .municipalitiesByDepartment(departmentId)
          .then(({ data }) => {
            this.section2.commonAddressInformation.municipalities = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "Hubo un error al intentar obtener los datos",
            });
          });
      }
    },
    resetMunicipality() {
      this.userAddressDetails.municipalityId = "";
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar los campos requeridos",
        });
        return;
      }
      this.update();
    },
    update() {
      this.$emit("loading");
      secondStepRepository
        .UpdateStepTwo(this.userAddressDetails)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.$emit("next");
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar modificar los datos",
          });
        })
        .finally(() => {
          this.$emit("stopLoading");
        });
    },
  },
  validations: {
    userAddressDetails: {
      departmentId: {
        required,
      },
      municipalityId: {
        required: requiredIf(function() {
          return !!this.userAddressDetails.departmentId;
        }),
      },
      address: {
        required,
      },
      transportId: {
        required,
      },
      distance: {
        required,
        maxValue: maxValue(999.99),
      },
    },
  },
};
</script>
