import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllHousingTypes = () => {
  return ApiService.get("housing-types");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getHousingType = (id) => {
  return ApiService.get(`housing-types/${id}`);
};

export default {
  getAllHousingTypes,
  getHousingType,
};
