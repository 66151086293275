<template>
  <v-dialog
    v-model="dialogState"
    width="500px"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title class="text-h5 light-blue darken-2 white--text text-center">
        Información detallada
      </v-card-title>
      <v-card-text class="pt-3 pb-1">
        <v-row>
          <v-col cols="12">
            <div class="grey lighten-4 rounded-lg pa-3">
              <div class="d-flex align-center mb-4 flex-column flex-sm-row">
                <v-btn
                  color="indigo lighten-2"
                  elevation="2"
                  class="mb-2 mb-sm-0 white--text"
                  fab
                  x-small
                  ><v-icon>mdi-account-school-outline</v-icon></v-btn
                >
                <p class="text-h5 mb-0 ml-sm-2 font-weight-medium">
                  Familiar graduado del ITR
                </p>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Nombre completo</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedFamilyMemberDetails.name }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Año de egreso</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedFamilyMemberDetails.graduationYear }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">
                  Parentesco
                </div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedFamilyMemberDetails.Kinship
                      ? selectedFamilyMemberDetails.Kinship.name
                      : undefined
                  }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">
                  Especialidad cursada
                </div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedFamilyMemberDetails.speciality
                      ? selectedFamilyMemberDetails.speciality.name
                      : undefined
                  }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">
                  Último grado cursado
                </div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedFamilyMemberDetails.grade
                      ? selectedFamilyMemberDetails.grade.name
                      : undefined
                  }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-end pt-0 pb-3">
        <v-spacer></v-spacer>
        <v-btn
          color="light-blue darken-2"
          class="white--text"
          depressed
          @click="dialogState = false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: "GraduatedFamilyHistoryInformationModal",
    props: {
      selectedFamilyMemberDetails: {
        type: Object,
      },
    },
    data() {
      return {
        dialogState: false,
      };
    },
    methods: {
      toggleModal() {
        this.dialogState = !this.dialogState;
      },
    },
  };
</script>
