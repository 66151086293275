<template>
  <!-- begin::fifth section - Condiciones especiales -->
  <v-sheet elevation="0" class="rounded-lg mt-4 pa-3">
    <!-- begin::card header -->
    <v-row
      class="d-flex flex-column-reverse flex-sm-row blue accent-5 mb-8 rounded white--text"
    >
      <!-- begin::section title - Dirección -->
      <v-col
        cols="12"
        class="d-flex align-center justify-center justify-md-start flex-column flex-md-row"
      >
        <div class="d-flex flex-row justify-start align-items-center">
          <p class="text-h5 mb-0">Paso</p>
          <v-icon color="white" class="mx-2"
            >mdi-numeric-{{ step }}-circle-outline</v-icon
          >
        </div>
        <p class="ma-0 text-h6 text-center text-md-left mt-2 mt-sm-0">
          <span class="font-weight-medium text-h5 mr-1 d-none d-md-inline"
            >-</span
          >
          Condiciones especiales del estudiante e información médica
        </p>
      </v-col>
      <!-- end::step - Dirección -->
    </v-row>

    <!-- begin::card body -->

    <!-- begin:: radio buttons container - Condiciones especiales -->
    <v-form>
      <v-row class="d-flex justify-center pa-0 pa-sm-4">
        <!-- Sordera - Condiciones especiales -->
        <v-col cols="12" sm="6">
          <p class="text-h6">¿Padece de sordera?</p>
          <form-group
            name="sordera"
            :validator="$v.userMedicalInformation.detailed.conditions.deafness"
          >
            <v-radio-group
              v-model="userMedicalInformation.detailed.conditions.deafness"
              required
              row
              slot-scope="{ attrs }"
              v-bind="attrs"
            >
              <v-radio
                label="Sí"
                value="true"
                color="light-blue darken-4"
              ></v-radio>
              <v-radio
                label="No"
                value="false"
                color="light-blue darken-4"
              ></v-radio>
            </v-radio-group>
          </form-group>
        </v-col>

        <!-- Hiperactividad - Condiciones especiales -->
        <v-col cols="12" sm="6">
          <p class="text-h6">¿Ha sido diagnosticado con hiperactividad?</p>
          <form-group
            name="hiperactividad"
            :validator="
              $v.userMedicalInformation.detailed.conditions.hyperactivity
            "
          >
            <v-radio-group
              v-model="userMedicalInformation.detailed.conditions.hyperactivity"
              required
              row
              slot-scope="{ attrs }"
              v-bind="attrs"
            >
              <v-radio
                label="Sí"
                value="true"
                color="light-blue darken-4"
              ></v-radio>
              <v-radio
                label="No"
                value="false"
                color="light-blue darken-4"
              ></v-radio>
            </v-radio-group>
          </form-group>
        </v-col>

        <!-- Problemas de aprendizaje - Condiciones especiales -->
        <v-col cols="12" sm="6">
          <p class="text-h6">¿Tiene algún problema de aprendizaje?</p>
          <form-group
            name="aprendizaje"
            :validator="
              $v.userMedicalInformation.detailed.conditions.learningDisabilities
            "
          >
            <v-radio-group
              v-model="
                userMedicalInformation.detailed.conditions.learningDisabilities
              "
              required
              row
              slot-scope="{ attrs }"
              v-bind="attrs"
            >
              <v-radio
                label="Sí"
                value="true"
                color="light-blue darken-4"
              ></v-radio>
              <v-radio
                label="No"
                value="false"
                color="light-blue darken-4"
              ></v-radio>
            </v-radio-group>
          </form-group>
        </v-col>

        <!-- Problema de salud crónico - Condiciones especiales -->
        <v-col cols="12" sm="6">
          <p class="text-h6">
            ¿Le han diagnosticado algún problema de salud crónico?
          </p>
          <form-group
            name="salud crónico"
            :validator="
              $v.userMedicalInformation.detailed.conditions.chronicCondition
            "
          >
            <v-radio-group
              v-model="
                userMedicalInformation.detailed.conditions.chronicCondition
              "
              required
              row
              slot-scope="{ attrs }"
              v-bind="attrs"
            >
              <v-radio
                label="Sí"
                value="true"
                color="light-blue darken-4"
              ></v-radio>
              <v-radio
                label="No"
                value="false"
                color="light-blue darken-4"
              ></v-radio>
            </v-radio-group>
          </form-group>
        </v-col>
      </v-row>
      <!-- end:: permanent treatments and illness information - Condiciones especiales -->
      <v-divider></v-divider>
      <v-row>
        <!-- Table instructions and information - Condiciones especiales -->
        <div class="px-3 mt-3" style="width: 100%">
          <div class="d-flex">
            <v-icon>mdi-information-outline</v-icon>
            <p
              class="pa-0 px-2 ma-0 mt-1 text-body-1 text-justify text-sm-left"
            >
              <b>Nota: </b>Si desea agregar más información médica puedes
              hacerlo acá abajo (no obligatorio)
              <v-icon>mdi-arrow-down-bold</v-icon>
            </p>
          </div>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-text-field
            autocomplete="null"
            v-model="userMedicalInformation.detailed.medicalInformation"
            rows="3"
            outlined
            label="Más información médica (opcional)"
            color="light-blue darken-4"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <!-- end:: radio buttons container - Condiciones especiales -->

    <v-divider></v-divider>
    <!-- begin:: permanent treatments and illness information - Condiciones especiales -->
    <v-expansion-panels focusable class="pa-0 ma-0 my-5">
      <v-expansion-panel>
        <v-expansion-panel-header
          class="light-blue lighten-5 py-5 flex-column flex-md-row"
        >
          <div class="d-flex justify-end">
            <v-icon x-large color="deep-orange" class="mb-2 mb-md-0"
              >mdi-information-outline</v-icon
            >
          </div>

          <h6
            class="ml-0 ml-md-2 mb-0 font-weight-medium text-center text-md-left"
          >
            Si desea agregar más información acerca de medicamentos prescritos,
            <br class="d-md-none" />
            <span class="blue--text"
              ><v-icon color="blue" small>mdi-cursor-default-click</v-icon> Haga
              click aqui</span
            >.
          </h6></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-row class="d-flex justify-start pa-0 pa-sm-4">
            <!-- begin:: Medicamentos prescritos de carácter permanente - Condiciones especiales -->

            <v-col cols="12">
              <v-banner outlined class="rounded-lg px-2">
                <div
                  class="d-flex align-center flex-column flex-sm-row mx-0 mx-sm-4 mt-4"
                >
                  <div>
                    <p
                      class="text-h5 text-center mb-4 mb-sm-0 font-weight-bold"
                    >
                      Medicamentos prescritos de caracter permanente
                    </p>
                  </div>
                  <v-divider class="mx-4 d-none d-sm-flex" vertical></v-divider>
                  <!-- <v-spacer></v-spacer> -->
                  <!-- Add new entry btn - Condiciones especiales -->
                  <v-btn
                    color="light-blue darken-4"
                    dark
                    @click="showCreateSection5Modal()"
                  >
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Agregar
                  </v-btn>
                </div>
                <!-- Table instructions and information - Condiciones especiales -->
                <div class="d-flex ml-sm-2 my-4 flex-column flex-sm-row">
                  <div class="d-flex align-stretch justify-center mr-2">
                    <v-icon small color="deep-orange"
                      >mdi-information-outline</v-icon
                    >
                  </div>
                  <p class="mb-0 mt-sm-0 text-body-1 text-center text-sm-left">
                    Seleccione los medicamentos que se le han prescrito de
                    caracter permanente y su dosis, haciendo click en el botón
                    <span class="font-weight-bold">"Agregar"</span>
                    .
                  </p>
                </div>
                <div
                  class="my-4 d-flex flex-wrap justify-sm-center justify-md-start"
                >
                  <!-- No entries fallback text - Condiciones especiales -->
                  <v-col cols="12" class="pb-0">
                    <v-alert
                      color="blue"
                      class="mb-0"
                      dense
                      outlined
                      prominent
                      text
                      type="info"
                      v-if="
                        userMedicalInformation.prescribedDrugsAddedByUser
                          .length == 0
                      "
                      >No se ha registrado ningún medicamento
                      prescrito.</v-alert
                    >
                  </v-col>
                  <!-- begin::Card to show informaion -->
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                    xl="3"
                    v-for="(
                      item, index
                    ) in userMedicalInformation.prescribedDrugsAddedByUser"
                    :key="index"
                  >
                    <!-- begin::card -->
                    <v-card
                      class="mx-auto score-sheet-card-large"
                      max-width="370"
                      outlined
                    >
                      <v-list-item three-line>
                        <!-- begin::card title -->
                        <v-list-item-content>
                          <div>
                            <p class="text-overline ma-0 mb-n2">
                              Medicamento prescrito
                            </p>
                          </div>

                          <p class="text-h5 mb-0">
                            {{ item.Medicine.name }}
                          </p>
                          <p
                            class="ma-0 text-body-1 font-weight-bold"
                            style="color: black !importante"
                          >
                            {{ item.dose }}
                            <!-- begin::card email -->
                          </p>
                        </v-list-item-content>
                        <!-- end::card title -->

                        <!-- begin::card icon -->
                        <v-list-item-avatar
                          tile
                          size="80"
                          color="blue-grey lighten-5"
                          class="rounded-lg"
                        >
                          <v-icon
                            x-large
                            :color="`${colors[index % 12]} lighten-3`"
                            class="ml-n1"
                            >mdi-pill</v-icon
                          >
                        </v-list-item-avatar>

                        <!-- end::card icon -->
                      </v-list-item>
                      <!-- begin::card body -->

                      <!-- end::card email -->

                      <v-card-actions
                        class="d-flex justify-center align-self-end blue-grey lighten-5"
                      >
                        <v-btn
                          class="my-1"
                          small
                          outlined
                          color="light-blue darken-2"
                          @click="
                            getSelectedPrescribedDrugDetails(item);
                            showDetailsSection5Modal();
                          "
                        >
                          <v-icon color="light-blue darken-2" small class="mr-1"
                            >mdi-information-outline</v-icon
                          >
                          Detalle
                        </v-btn>

                        <v-btn
                          class="my-1"
                          small
                          outlined
                          color="blue-grey darken-3"
                          @click="
                            getSelectedPrescribedDrugDetails(item),
                              showUpdateSection5Modal()
                          "
                        >
                          <v-icon color="blue-grey darken-3" small class="mr-1"
                            >mdi-pencil-circle-outline</v-icon
                          >
                          Editar
                        </v-btn>
                        <v-btn
                          class="my-1"
                          small
                          outlined
                          color="red"
                          @click="
                            showDeleteModal(),
                              getSelectedPrescribedDrugDetails(item)
                          "
                        >
                          <v-icon color="red" small class="mr-1"
                            >mdi-delete-circle-outline</v-icon
                          >
                          Eliminar
                        </v-btn>
                      </v-card-actions>
                      <!-- end::card body -->
                    </v-card>
                    <v-card
                      class="mx-auto rounded score-sheet-card-small"
                      outlined
                    >
                      <div class="mt-4">
                        <p class="text-overline text-center ma-0 mb-n2">
                          Medicamento prescrito
                        </p>
                      </div>

                      <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                          <v-list-item-avatar
                            tile
                            size="80"
                            color="blue-grey lighten-5"
                            class="rounded-lg mx-auto"
                          >
                            <v-icon
                              x-large
                              :color="`${colors[index % 12]} lighten-2`"
                              >mdi-pill</v-icon
                            >
                          </v-list-item-avatar>
                        </v-col>
                      </v-row>

                      <p class="text-h5 mb-0 text-center px-1">
                        {{ item.Medicine.name }}
                      </p>
                      <p
                        class="ma-0 mb-2 text-body-1 text-center"
                        style="color: black !importante"
                      >
                        {{ item.dose }}

                        <!-- begin::card email -->
                      </p>

                      <v-card-actions
                        class="d-flex justify-center flex-column blue-grey lighten-5"
                      >
                        <v-btn
                          class="mx-auto my-1"
                          block
                          outlined
                          color="light-blue darken-2"
                          @click="
                            getSelectedPrescribedDrugDetails(item);
                            showDetailsSection5Modal();
                          "
                        >
                          <v-icon color="light-blue darken-2" small class="mr-1"
                            >mdi-information-outline</v-icon
                          >
                          Detalle
                        </v-btn>

                        <v-btn
                          class="mx-auto my-1"
                          block
                          outlined
                          color="blue-grey darken-3"
                          @click="
                            getSelectedPrescribedDrugDetails(item),
                              showUpdateSection5Modal()
                          "
                        >
                          <v-icon color="blue-grey darken-3" small class="mr-1"
                            >mdi-pencil-circle-outline</v-icon
                          >
                          Editar
                        </v-btn>
                        <v-btn
                          class="mx-auto my-1"
                          block
                          outlined
                          color="red"
                          @click="
                            showDeleteModal(),
                              getSelectedPrescribedDrugDetails(item)
                          "
                        >
                          <v-icon color="red" small class="mr-1"
                            >mdi-delete-circle-outline</v-icon
                          >
                          Eliminar
                        </v-btn>
                      </v-card-actions>
                      <!-- end::card body -->
                    </v-card>
                    <!-- end::card -->
                  </v-col>
                  <!-- end::Card to show information -->
                </div>
              </v-banner>
            </v-col>

            <!-- end:: Medicamentos prescritos de carácter permanente - Condiciones especiales -->

            <!-- begin:: Medicamentos a los que es alérgico/a: - Condiciones especiales -->
            <v-col cols="12" md="6" xl="4">
              <v-banner outlined class="rounded-lg">
                <!-- card title and correlative (2nd) -->
                <div
                  class="d-flex justify-center flex-column flex-sm-row pa-4 mb-4"
                >
                  <p
                    class="pa-0 px-2 ma-0 mt-1 text-body-1 font-weight-bold text-center text-sm-left"
                  >
                    Medicamentos a los que es alérgico/a:
                  </p>
                </div>

                <!-- Treatment (2nd) -->
                <v-row class="d-flex align-start justify-center mx-2 mb-3">
                  <v-col cols="12" lg="12" class="pa-0 pa-sm-2">
                    <v-select
                      hide-details
                      :items="section5.conditionsDetails.drugsForAllergies"
                      item-text="name"
                      item-value="id"
                      v-model="userMedicalInformation.detailed.allergicTo"
                      label="medicamento"
                      color="light-blue darken-4"
                      small-chips
                      multiple
                      outlined
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip
                          color="light-blue darken-4"
                          small
                          v-if="index === 0"
                        >
                          <span class="white--text my-1">{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="black--text text-caption"
                        >
                          (+{{
                            userMedicalInformation.detailed.allergicTo.length -
                            1
                          }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-banner>
            </v-col>
            <!-- end:: Medicamentos a los que es alérgico/a: - Condiciones especiales -->

            <!-- begin:: Enfermedades ó condiciones especiales: - Condiciones especiales -->
            <v-col cols="12" md="6" xl="4" class="mt-2 mt-md-0 mt-xl-0">
              <v-banner outlined class="rounded-lg">
                <!-- card title and correlative (3rd) -->
                <div
                  class="d-flex justify-center flex-column flex-sm-row pa-4 mb-4"
                >
                  <p
                    class="pa-0 px-2 ma-0 mt-1 text-body-1 font-weight-bold text-center text-sm-left"
                  >
                    Enfermedades ó condiciones especiales:
                  </p>
                </div>

                <!-- Condition(3rd) -->
                <v-row class="d-flex align-start justify-center mx-2 mb-3">
                  <v-col cols="12" lg="12" class="pa-0 pa-sm-2">
                    <v-select
                      hide-details
                      :items="section5.conditionsDetails.diseases"
                      item-text="name"
                      item-value="id"
                      v-model="userMedicalInformation.detailed.diseases"
                      label="medicamento"
                      color="light-blue darken-4"
                      small-chips
                      multiple
                      outlined
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip
                          color="light-blue darken-4"
                          small
                          v-if="index === 0"
                        >
                          <span class="white--text my-1">{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="black--text text-caption"
                        >
                          (+{{
                            userMedicalInformation.detailed.diseases.length - 1
                          }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-banner>
            </v-col>
            <!-- begin:: Enfermedades ó condiciones especiales: - Condiciones especiales -->
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- here it was -->
    <CreateSection5Modal
      ref="mdlCreate"
      :section5="section5"
      :prescribedDrugsList="userMedicalInformation"
      @create="addPrescribedDrug"
    ></CreateSection5Modal>
    <UpdateSection5Modal
      ref="mdlUpdate"
      :section5="section5"
      :selectedDrug="selectedPrescribedDrugDetailsForUpdate"
      @update="updatePrescribedDrug"
    ></UpdateSection5Modal>
    <DetailsSection5Modal
      ref="mdlDetails"
      :selectedItemInformation="selectedPrescribedDrugDetails"
    ></DetailsSection5Modal>
    <DeleteModal
      ref="mdlDelete"
      dialogText="¿Está seguro que desea eliminar este registro de medicamento prescrito?"
      :selectedItemInformationSummary="selectedPrescribedDrugDetailsSummary"
      @delete="deletePrescribedDrug"
    ></DeleteModal>
  </v-sheet>
  <!-- end::fifth section - Condiciones especiales -->
</template>
<script>
import { required, maxLength } from "vuelidate/lib/validators";
import fifthStepRepository from "@/repositories/steps/fifthStepRepository";
import CreateSection5Modal from "@/view/components/modals/section-5/CreateSection5Modal";
import UpdateSection5Modal from "@/view/components/modals/section-5/UpdateSection5Modal";
import DetailsSection5Modal from "@/view/components/modals/section-5/DetailsSection5Modal";
import DeleteModal from "@/view/components/modals/DeleteModal";
import { mapGetters } from "vuex";
export default {
  name: "SpecialConditions",
  props: {
    section5: {
      type: Object,
    },
    step: {
      type: Number,
    },
  },
  components: {
    CreateSection5Modal,
    UpdateSection5Modal,
    DeleteModal,
    DetailsSection5Modal,
  },
  mounted() {
    this.getAllPrescribedDrugs();
    this.getAllMedicinesAllergicTo();
    this.getAllStudentDiseases();
    this.getAllStudentConditions();
  },
  data() {
    return {
      // progress: 1.5,
      selectedPrescribedDrugDetails: {},
      selectedPrescribedDrugDetailsSummary: {},
      selectedPrescribedDrugDetailsForUpdate: {},
      medicines: {
        allergicTo: [],
      },
      medicalConditions: {
        diseases: [],
      },
      userMedicalInformation: {
        detailed: {
          conditions: {
            deafness: null,
            hyperactivity: null,
            learningDisabilities: null,
            chronicCondition: null,
          },
          allergicTo: [],
          diseases: [],
          medicalInformation: "",
        },
        prescribedDrugsAddedByUser: [],
        prescribedDrugsJustAddedByUser: {},
      },
    };
  },
  methods: {
    getStudentsConditionsExtraInformation() {
      this.userMedicalInformation.detailed.medicalInformation =
        this.currentUserPersonalInfo.medicalInformation;
    },
    getSelectedPrescribedDrugDetails(obj) {
      this.selectedPrescribedDrugDetails = { ...obj };
      this.selectedPrescribedDrugDetailsForUpdate = {
        id: obj.id,
        medicineId: obj.medicineId,
        dose: obj.dose,
        isAllergic: obj.isAllergic,
      };
      this.selectedPrescribedDrugDetailsSummary = {
        id: obj.id,
        upperTitle: `Medicamento prescrito`,
        mainTitle: obj.Medicine.name,
        subTitle: obj.dose,
        icon: "mdi-pill",
        iconColor: "red darken-1",
      };
    },

    allergies() {
      this.medicines.allergicTo.forEach((element) => {
        this.userMedicalInformation.detailed.allergicTo.push(
          element.Medicine.id
        );
      });
    },
    diseases() {
      this.medicalConditions.diseases.forEach((element) => {
        this.userMedicalInformation.detailed.diseases.push(element.diseaseId);
      });
    },

    //Data fetching - presicribed medicine

    getAllPrescribedDrugs() {
      fifthStepRepository
        .getAllPrescribedMedicines()
        .then(({ data }) => {
          this.userMedicalInformation.prescribedDrugsAddedByUser = data;
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        });
    },

    addPrescribedDrug() {
      fifthStepRepository
        .addPrescribedMedicine(
          this.userMedicalInformation.prescribedDrugsJustAddedByUser
        )
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllPrescribedDrugs();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar guardar los datos",
          });
        });
    },

    updatePrescribedDrug() {
      fifthStepRepository
        .updatePrescribedMedicine(
          this.selectedPrescribedDrugDetailsForUpdate.id,
          this.selectedPrescribedDrugDetailsForUpdate
        )
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllPrescribedDrugs();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar guardar los datos",
          });
        });
    },

    deletePrescribedDrug(itemId) {
      fifthStepRepository
        .deletePrescribedMedicine(itemId)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllPrescribedDrugs();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar eliminar el registro",
          });
        });
    },

    // Data fetching - medicine allergic to

    getAllMedicinesAllergicTo() {
      fifthStepRepository
        .getAllMedicinesAllergicTo()
        .then(({ data }) => {
          // this is an array
          this.medicines.allergicTo = data;
          this.allergies();
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        });
    },

    getAllStudentDiseases() {
      fifthStepRepository
        .getAllStudentDiseases()
        .then(({ data }) => {
          // this is an array
          this.medicalConditions.diseases = data;
          this.diseases();
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        });
    },

    getAllStudentConditions() {
      fifthStepRepository
        .getAllStudentConditions()
        .then(({ data }) => {
          this.filterConditions(data);
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title: error.message,
          });
        });
    },
    //TODO: refractor code, changes are neded in the api
    filterConditions(data) {
      let deafnessState = data.find((el) => el.diseaseId == 1);
      let hyperactivityState = data.find((el) => el.diseaseId == 2);
      let learningDisabilitiesState = data.find((el) => el.diseaseId == 3);
      let chronicConditionState = data.find((el) => el.diseaseId == 4);

      this.userMedicalInformation.detailed.conditions.deafness =
        deafnessState?.suffers.toString();
      this.userMedicalInformation.detailed.conditions.hyperactivity =
        hyperactivityState?.suffers.toString();
      this.userMedicalInformation.detailed.conditions.learningDisabilities =
        learningDisabilitiesState?.suffers.toString();
      this.userMedicalInformation.detailed.conditions.chronicCondition =
        chronicConditionState?.suffers.toString();
    },

    updateMedicinesAllergicTo() {
      this.$emit("loading");
      fifthStepRepository
        .addMedicineAllergicTo(this.userMedicalInformation.detailed)
        .then(({ data }) => {
          this.getAllPrescribedDrugs();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar guardar los datos",
          });
        });
    },

    updateMedicalCoditions() {
      fifthStepRepository
        .addMedicalConditionsInformation(this.userMedicalInformation.detailed)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllPrescribedDrugs();
          this.$emit("next");
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar guardar los datos",
          });
        })
        .finally(() => {
          this.$emit("stopLoading");
        });
    },

    updateExtraMedicalConditionsInformation() {
      fifthStepRepository
        .addExtraConditionsInformation(this.userMedicalInformation.detailed)
        .then(({ data }) => {})
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar guardar los datos",
          });
        });
    },

    //Modal togglers

    showCreateSection5Modal() {
      this.$refs.mdlCreate.toggleModal();
    },
    showDetailsSection5Modal() {
      this.$refs.mdlDetails.toggleModal();
    },
    showUpdateSection5Modal() {
      this.$refs.mdlUpdate.toggleModal();
    },
    showDeleteModal() {
      this.$refs.mdlDelete.toggleModal();
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar los campos requeridos",
        });
        return;
      }
      this.updateMedicinesAllergicTo();
      this.updateMedicalCoditions();
      this.updateExtraMedicalConditionsInformation();
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  validations: {
    userMedicalInformation: {
      detailed: {
        conditions: {
          deafness: { required },
          hyperactivity: { required },
          learningDisabilities: { required },
          chronicCondition: { required },
        },
      },
    },
  },
};
</script>
