import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSchools = () => {
  return ApiService.get("schools");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getSchool = (id) => {
  return ApiService.get(`schools/${id}`);
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getSchoolsByMunicipality = (id) => {
  return ApiService.get(`schools/municipality/${id}`);
};

export default {
  getAllSchools,
  getSchool,
  getSchoolsByMunicipality,
};
