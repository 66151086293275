import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllDepartments = () => {
    return ApiService.get("departments");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getDepartment = (id) => {
    return ApiService.get("departments", id);
};


export default {
    getAllDepartments,
    getDepartment,
};
