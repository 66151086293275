import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllMedicines = () => {
  return ApiService.get("medicines");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getMedicine = (id) => {
  return ApiService.get(`medicines/${id}`);
};

export default {
  getAllMedicines,
  getMedicine,
};
