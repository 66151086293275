<template>
  <v-dialog
    v-model="dialogState"
    max-width="500px  "
    transition="dialog-bottom-transition"
  >
    <v-card elevation="1">
      <v-card-title class="text-h5 light-blue darken-2 white--text text-center">
        Información detallada
      </v-card-title>

      <v-card-text class="pt-3 pb-1">
        <v-row>
          <v-col cols="12">
            <div class="grey lighten-4 rounded-lg pa-3">
              <div class="d-flex align-center mb-4 flex-column flex-sm-row">
                <v-btn
                  color="light-green"
                  elevation="2"
                  class="mb-2 mb-sm-0 white--text"
                  fab
                  x-small
                  ><v-icon>mdi-account-circle-outline</v-icon></v-btn
                >
                <p class="text-h5 mb-0 ml-sm-2 font-weight-medium">
                  Familiar estudiando actualmente
                </p>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Carnet</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedFamilyMemberDetails.code }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Nombre completo</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedFamilyMemberDetails.name }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">
                  Grado que estudiará en el próximo año
                </div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedFamilyMemberDetails.grade
                      ? selectedFamilyMemberDetails.grade.name
                      : undefined
                  }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">
                  Especialidad que estudiará el próximo año
                </div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedFamilyMemberDetails.speciality
                      ? selectedFamilyMemberDetails.speciality.name
                      : undefined
                  }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end pt-0 pb-3">
        <v-spacer></v-spacer>
        <v-btn
          color="light-blue darken-2"
          class="white--text"
          depressed
          @click="dialogState = false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: "CurrentlyFamilyHistoryInformationModal",
    props: {
      selectedFamilyMemberDetails: {
        type: Object,
      },
    },
    data() {
      return {
        dialogState: false,
      };
    },
    methods: {
      toggleModal() {
        this.dialogState = !this.dialogState;
      },
    },
  };
</script>
