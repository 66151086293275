import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllNationalities = () => {
    return ApiService.get("nationalities");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getNacionalities = (id) => {
    return ApiService.get("nationalities", id);
};


export default {
    getAllNationalities,
    getNacionalities,
};
