<template>
  <v-dialog
    v-model="dialogState"
    max-width="600px"
    transition="dialog-bottom-transition"
    scrollable
    persistent
  >
    <!--Floating input form - Historial Familiar ITR -->
    <v-card elevation="1">
      <!-- form header name - Historial Familiar ITR -->
      <v-card-title class="text-h5 light-blue darken-4 white--text text-center">
        Agregando familiares egresados
      </v-card-title>
      <v-card-text class="my-0 py-0 mt-5">
        <v-form ref="formCreateGraduated">
          <v-container>
            <v-row>
              <!-- Nombre completo - Egresados - Historial Familiar ITR -->
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group name="nombre" :validator="$v.tempUserData.name">
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    autocomplete="null"
                    v-model="tempUserData.name"
                    label="Nombre completo"
                    color="light-blue darken-4"
                    required
                    outlined
                  ></v-text-field>
                </form-group>
              </v-col>

              <!-- Año de egreso - Egresados - Historial Familiar ITR -->
              <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                <form-group
                  name="año de egreso"
                  :validator="$v.tempUserData.graduationYear"
                  :messages="{
                    validate_max_year:
                      'El año de egreso no puede ser mayor al año actual',
                  }"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    autocomplete="null"
                    v-model="tempUserData.graduationYear"
                    color="light-blue darken-4"
                    label="Año de egreso"
                    required
                    outlined
                    v-mask="'####'"
                  ></v-text-field>
                </form-group>
              </v-col>

              <!-- Parentesco con el alumno - Egresados - Historial Familiar ITR -->
              <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                <form-group
                  name="parentesco"
                  :validator="$v.tempUserData.kinshipId"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :items="section4.personalDetails.kinships"
                    item-text="name"
                    item-value="id"
                    v-model="tempUserData.kinshipId"
                    label="Parentesco"
                    color="light-blue darken-4"
                    required
                    outlined
                  ></v-select>
                </form-group>
              </v-col>

              <!-- Último grado cursado - Egresados - Historial Familiar ITR -->
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group name="grado" :validator="$v.tempUserData.gradeId">
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :items="section4.personalDetails.grade"
                    item-text="name"
                    item-value="id"
                    v-model="tempUserData.gradeId"
                    label="Último grado cursado"
                    color="light-blue darken-4"
                    required
                    outlined
                    @change="resetSpeciality(), setSpecialityToGrades()"
                  >
                  </v-select>
                </form-group>
              </v-col>

              <!-- Especialidad cursada - Egresados - Historial Familiar ITR -->
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group
                  name="especialidad"
                  :validator="$v.tempUserData.specialityId"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :items="section4.personalDetails.specialities"
                    item-text="name"
                    item-value="id"
                    v-model="tempUserData.specialityId"
                    label="Especialidad cursada"
                    color="light-blue darken-4"
                    required
                    outlined
                    :disabled="disabledGrade"
                  >
                  </v-select>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end pt-0 pb-3">
        <v-spacer></v-spacer>
        <!-- cancel btn - Historial Familiar ITR -->
        <v-btn
          color="light-blue darken-4"
          outlined
          text
          @click="showMdlConfirm()"
          >Cancelar</v-btn
        >
        <!-- save data btn - Historial Familiar ITR -->
        <v-btn
          color="light-blue darken-4"
          class="white--text"
          depressed
          @click="validate()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
    <ConfirmationModal
      ref="confirmationModal"
      v-on:confirmData="toggleModal"
      v-on:resetForm="resetForm"
    ></ConfirmationModal>
  </v-dialog>
</template>
<script>
import { required, helpers, maxValue } from "vuelidate/lib/validators";
import ConfirmationModal from "@/view/components/modals/ConfirmationModal";
export default {
  name: "CreateGraduatedFamilyHistoryModal",
  components: {
    ConfirmationModal,
  },
  props: {
    section4: {
      type: Object,
    },
    familyMembers: {
      type: Object,
    },
  },
  data() {
    return {
      dialogState: false,
      disabledGrade: false,
      tempUserData: {
        name: null,
        graduationYear: null,
        kinshipId: null,
        specialityId: null,
        gradeId: null,
      },
    };
  },
  methods: {
    toggleModal() {
      this.dialogState = !this.dialogState;
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar los campos requeridos",
        });
        return;
      }
      this.saveData();
    },
    resetSpeciality() {
      this.tempUserData.specialityId = null;
    },
    setSpecialityToGrades() {
      if ([1, 2, 3].includes(this.tempUserData.gradeId)) {
        this.tempUserData.specialityId = 8;
        this.disabledGrade = true;
      } else {
        this.disabledGrade = false;
      }
    },
    saveData() {
      let data = {
        name: this.tempUserData.name,
        graduationYear: this.tempUserData.graduationYear,
        kinshipId: this.tempUserData.kinshipId,
        gradeId: this.tempUserData.gradeId,
        specialityId: this.tempUserData.specialityId,
        isActiveStudent: false,
      };

      this.familyMembers.graduatedJustAdded = data;
      this.$emit("createGraduated");
      this.dialogState = false;
      this.resetForm();
    },
    showMdlConfirm() {
      this.$refs.confirmationModal.toggleModal();
    },
    resetForm() {
      this.tempUserData = {
        name: null,
        graduationYear: null,
        kinshipId: null,
        specialityId: null,
        gradeId: null,
      };
      this.$v.$reset();
    },
  },
  validations: {
    tempUserData: {
      name: { required },
      graduationYear: {
        required,
        maxValue: maxValue(new Date().getFullYear()),
      },
      kinshipId: { required },
      specialityId: { required },
      gradeId: { required },
    },
  },
};
</script>
<style>
.v-card__title {
  word-break: normal;
}
</style>
