<template>
  <v-dialog
    v-model="modalState"
    max-width="600px"
    scrollable
    transition="dialog-bottom-transition"
    persistent
  >
    <!--Floating input form - Historial Familiar ITR -->
    <v-card elevation="1">
      <!-- form header name - Historial Familiar ITR -->
      <v-card-title class="text-h5 blue-grey darken-3 white--text text-center">
        Actualizar datos de medicamento
      </v-card-title>
      <v-card-text class="my-0 py-0 mt-5">
        <v-form ref="updateForm">
          <v-container>
            <v-row>
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group
                  name="medicamento"
                  :validator="$v.selectedDrug.medicineId"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :items="section5.conditionsDetails.availablePrescribedDrugs"
                    item-text="name"
                    item-value="id"
                    v-model="selectedDrug.medicineId"
                    label="Medicamento"
                    color="light-blue darken-4"
                    required
                    outlined
                  ></v-select>
                </form-group>
              </v-col>
              <v-col cols="12" class="pa-0 pa-sm-2">
                <form-group name="dosis" :validator="$v.selectedDrug.dose">
                  <v-textarea
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    autocomplete="null"
                    outlined
                    rows="1"
                    label="Dosis"
                    placeholder="8ml cada 12 horas.."
                    color="light-blue darken-4"
                    :counter="100"
                    v-model="selectedDrug.dose"
                  ></v-textarea>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end pt-0 pb-3">
        <v-spacer></v-spacer>
        <!-- cancel btn - Historial Familiar ITR -->
        <v-btn
          color="blue-grey darken-3"
          outlined
          text
          @click="showMdlConfirm()"
          >Cancelar</v-btn
        >
        <!-- save data btn - Historial Familiar ITR -->
        <v-btn
          color="blue-grey darken-3"
          class="white--text"
          depressed
          @click="validate()"
          >Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
    <ConfirmationModal
      ref="confirmationModal"
      @confirmData="toggleModal"
      v-on:resetForm="resetForm"
    ></ConfirmationModal>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import ConfirmationModal from "@/view/components/modals/ConfirmationModal";
export default {
  name: "UpdateModal",
  components: {
    ConfirmationModal,
  },
  props: {
    selectedDrug: {
      type: Object,
    },
    section5: {
      type: Object,
    },
  },
  data() {
    return {
      modalState: false,
    };
  },
  methods: {
    toggleModal() {
      this.modalState = !this.modalState;
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar los campos requeridos",
        });
        return;
      }
      this.$emit("update");
      this.toggleModal();
    },
    showMdlConfirm() {
      this.$refs.confirmationModal.toggleModal();
    },
    resetForm() {
      this.$v.$reset();
    },
  },
  validations: {
    selectedDrug: {
      medicineId: { required },
      dose: { required },
    },
  },
};
</script>
