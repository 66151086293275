<template>
  <v-dialog
    v-model="modalState"
    width="1100px"
    persistent
    transition="dialog-bottom-transition"
  >
    <!-- begin:: Stepper  -->
    <v-stepper v-model="e1" color="blue-grey darken-3">
      <!-- begin:: Stepper header -->
      <v-stepper-header id="content">
        <template v-for="n in steps">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="e1 > n"
            :step="n"
            class="stepper-step"
            color="blue-grey darken-3"
          >
            Paso {{ n }}
          </v-stepper-step>
          <v-divider v-if="n !== steps" :key="n"></v-divider>
        </template>
      </v-stepper-header>
      <!-- end:: Stepper header -->
      <!-- begin:: Stepper items -->
      <v-stepper-items>
        <!-- First Step -->
        <v-stepper-content step="1" class="pa-0">
          <v-form ref="updateForm_section7_step1">
            <v-card elevation="0">
              <v-card-title class="text-h5 blue-grey darken-3 white--text">
                Información personal del familiar
              </v-card-title>
              <v-card-text class="mt-5">
                <v-container>
                  <v-row>
                    <!-- Primeros nombres -Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="nombres"
                        :validator="$v.selectedFamilyMember.firstName"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Nombres"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.firstName"
                          outlined
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Apellidos - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="apellidos"
                        :validator="$v.selectedFamilyMember.lastName"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Apellidos"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.lastName"
                          outlined
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Correo electrónico - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="correo"
                        :validator="$v.selectedFamilyMember.email"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Correo electrónico"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.email"
                          outlined
                        ></v-text-field>
                      </form-group>
                    </v-col>
                    <!-- Parentesco - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="parentesco"
                        :validator="$v.selectedFamilyMember.kinshipId"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          label="Parentesco"
                          color="light-blue darken-4"
                          :items="section7.personalDetails.kinships"
                          item-text="name"
                          item-value="id"
                          v-model="selectedFamilyMember.kinshipId"
                          outlined
                        ></v-select>
                      </form-group>
                    </v-col>
                    <!-- Teléfono de residencia - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="número de teléfono"
                        :messages="{
                          minLength:
                            'El campo número de teléfono debería contener al menos 8 números',
                        }"
                        :validator="$v.selectedFamilyMember.phone"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Número de teléfono"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.phone"
                          outlined
                          v-mask="'########'"
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Teléfono celular - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="número de celular"
                        :messages="{
                          minLength:
                            'El campo número de celular debería contener al menos 8 números',
                        }"
                        :validator="$v.selectedFamilyMember.cellphone"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Número de celular"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.cellphone"
                          outlined
                          v-mask="'########'"
                        ></v-text-field>
                      </form-group>
                    </v-col>
                    <!-- DUI - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="dui"
                        :messages="{
                          minLength:
                            'El campo dui debería contener al menos 9 números',
                        }"
                        :validator="$v.selectedFamilyMember.dui"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="DUI"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.dui"
                          outlined
                          v-mask="'########-#'"
                        ></v-text-field>
                      </form-group>
                    </v-col>
                    <!-- NIT - Información familiar detallada -->
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                      class="pa-0 pa-sm-2"
                      v-if="!switch1"
                    >
                      <form-group
                        name="nit"
                        :messages="{
                          minLength:
                            'El campo nit debería contener al menos 13 números',
                        }"
                        :validator="$v.selectedFamilyMember.nit"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="NIT"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.nit"
                          outlined
                          v-mask="'####-######-###-#'"
                        ></v-text-field>
                      </form-group>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-switch
                              v-model="switch1"
                              color="light-blue darken-4"
                              inset
                              :label="`DUI homologado`"
                              @change="changeSwitchNit()"
                            ></v-switch>
                          </div>
                        </template>
                        <div>Marque sí posee el DUI homologado</div>
                      </v-tooltip>
                    </v-col>
                    <!-- Ckeckbox - Es responsable del menor ante la institución - Información familiar detallada -->
                    <v-col cols="12" md="12">
                      <v-container fluid>
                        <v-checkbox
                          class="mt-0"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.isManager"
                          label="Marcar la casilla si es responsable del menor ante la institución."
                        >
                        </v-checkbox>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions
                class="d-flex flex-row justify-end align-center pt-0 pb-15 px-4"
              >
                <v-btn
                  color="blue-grey darken-3"
                  text
                  large
                  @click="showMdlConfirm()"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue-grey darken-3 white--text"
                  class="white--text"
                  depressed
                  large
                  @click="validate(1)"
                >
                  Siguiente
                  <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-stepper-content>
        <!-- Second Step -->
        <v-stepper-content step="2" class="pa-0">
          <v-form ref="updateForm_section7_step2">
            <v-card elevation="0">
              <v-card-title class="text-h5 blue-grey darken-3 white--text">
                Información de nacimiento del familiar
              </v-card-title>
              <v-card-text class="mt-5">
                <v-container>
                  <v-row>
                    <!-- Fecha de nacimiento - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="6" class="pa-0 pa-sm-2">
                      <v-menu
                        ref="dialog"
                        v-model="dateDialogState"
                        :close-on-content-click="false"
                        :return-value="selectedFamilyMember.birthday"
                        transition="scale-transition"
                        offset-y
                        min-width="250px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <form-group
                            name="fecha de nacimiento"
                            :validator="$v.selectedFamilyMember.birthday"
                            v-bind="attrs"
                          >
                            <v-text-field
                              v-model="selectedFamilyMember.birthday"
                              label="Fecha de nacimiento"
                              color="light-blue darken-4"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              outlined
                              clearable
                              slot-scope="{ attrs }"
                              v-bind="attrs"
                              v-on="on"
                              required
                            ></v-text-field>
                          </form-group>
                        </template>
                        <v-date-picker
                          v-model="selectedFamilyMember.birthday"
                          color="light-blue darken-4"
                          :active-picker.sync="activePicker"
                          :max="
                            new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          min="1922-01-01"
                          @change="save(selectedFamilyMember.birthday)"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <!-- Estado civil - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="6" class="pa-0 pa-sm-2">
                      <form-group
                        name="estado civil"
                        :validator="$v.selectedFamilyMember.maritalStatusId"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          label="Estado civil"
                          color="light-blue darken-4"
                          :items="section7.personalDetails.maritalStatus"
                          item-text="name"
                          item-value="id"
                          v-model="selectedFamilyMember.maritalStatusId"
                          outlined
                        ></v-select>
                      </form-group>
                    </v-col>
                    <!-- Nacionalidad - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" class="pa-0 pa-sm-2">
                      <form-group
                        name="nacionalidad"
                        :validator="$v.selectedFamilyMember.nationalityId"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          label="Nacionalidad"
                          color="light-blue darken-4"
                          :items="section7.personalDetails.nationality"
                          item-text="name"
                          item-value="id"
                          v-model="selectedFamilyMember.nationalityId"
                          outlined
                          @change="changeNationality()"
                        ></v-select>
                      </form-group>
                    </v-col>
                    <!-- Departamento de nacimiento - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" class="pa-0 pa-sm-2">
                      <form-group
                        name="departamento"
                        :validator="$v.selectedFamilyMember.departmentId"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          v-if="selectedFamilyMember.nationalityId == 1"
                          :items="section7.personalDetails.departments"
                          item-text="name"
                          item-value="id"
                          label="Departamento de nacimiento"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.departmentId"
                          outlined
                          @change="
                            loadMunicipalities(
                              selectedFamilyMember.departmentId
                            ),
                              resetMunicipality()
                          "
                        ></v-select>
                      </form-group>
                    </v-col>

                    <!-- Municipio de nacimiento - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" class="pa-0 pa-sm-2">
                      <form-group
                        name="municipio"
                        :validator="$v.selectedFamilyMember.municipalityId"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          v-if="selectedFamilyMember.nationalityId == 1"
                          :disabled="!selectedFamilyMember.departmentId"
                          :items="selectedFamilyMember.municipalities"
                          item-text="name"
                          item-value="id"
                          label="Municipio de nacimiento"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.municipalityId"
                          outlined
                        ></v-select>
                      </form-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions
                class="d-flex flex-row justify-end align-center pt-0 pb-15 px-4"
              >
                <v-btn
                  color="blue-grey darken-3"
                  text
                  large
                  @click="showMdlConfirm()"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue-grey darken-3"
                  outlined
                  text
                  large
                  @click="previousStep(2)"
                >
                  <v-icon small class="ml-1">mdi-arrow-left-bold</v-icon>
                  Regresar
                </v-btn>
                <v-btn
                  color="blue-grey darken-3"
                  class="white--text"
                  depressed
                  large
                  @click="validate(2)"
                >
                  Siguiente
                  <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-stepper-content>
        <!-- Third Step -->
        <v-stepper-content step="3" class="pa-0">
          <v-form ref="updateForm_section7_step3">
            <v-card elevation="0">
              <v-card-title class="text-h5 blue-grey darken-3 white--text">
                Información laboral del familiar
              </v-card-title>
              <v-card-text class="mt-5">
                <v-container>
                  <v-row>
                    <!-- Profesión u officio - Información familiar detallada -->
                    <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                      <form-group
                        name="profesión u oficio"
                        :validator="$v.selectedFamilyMember.profession"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Profesión u oficio"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.profession"
                          outlined
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Lugar de trabajo - Información familiar detallada -->
                    <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                      <form-group
                        name="lugar de trabajo"
                        :validator="$v.selectedFamilyMember.workplace"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Lugar de trabajo"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.workplace"
                          outlined
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Teléfono del area de trabajo - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="3" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="teléfono de trabajo"
                        :validator="$v.selectedFamilyMember.workPhone"
                        :messages="{
                          minLength:
                            'El campo teléfono de trabajo debería contener al menos 8 números',
                        }"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Teléfono del trabajo"
                          color="light-blue darken-4"
                          v-model="selectedFamilyMember.workPhone"
                          outlined
                          v-mask="'########'"
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Extención del teléfono del area de trabajo - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="3" lg="3" class="pa-0 pa-sm-2">
                      <v-text-field
                        autocomplete="null"
                        label="Extensión"
                        color="light-blue darken-4"
                        v-model="selectedFamilyMember.extension"
                        outlined
                        v-mask="'####'"
                      ></v-text-field>
                    </v-col>

                    <!-- Dirección de area de trabajo - Información familiar detallada -->
                    <v-col cols="12" md="6" class="pa-0 pa-sm-2">
                      <v-textarea
                        autocomplete="null"
                        outlined
                        v-model="selectedFamilyMember.workAddress"
                        rows="1"
                        name="input-7-4"
                        label="Dirección de trabajo"
                        color="light-blue darken-4"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions
                class="d-flex flex-row justify-end align-center pt-0 pb-15 px-4"
              >
                <v-btn
                  color="blue-grey darken-3"
                  text
                  large
                  @click="showMdlConfirm()"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="blue-grey darken-3"
                  outlined
                  text
                  large
                  @click="previousStep(3)"
                >
                  <v-icon small class="ml-1">mdi-arrow-left-bold</v-icon>
                  Regresar
                </v-btn>
                <v-btn
                  color="blue-grey darken-3"
                  class="white--text"
                  depressed
                  large
                  @click="validate(3)"
                >
                  Guardar
                  <v-icon right dark> mdi-send </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
      <!-- end:: Stepper items -->
    </v-stepper>
    <!-- end:: Stepper  -->
    <ConfirmationModal
      ref="confirmationModal"
      v-on:confirmData="toggleModal"
      v-on:resetForm="resetForm"
    >
    </ConfirmationModal>
  </v-dialog>
</template>

<script>
import {
  required,
  maxLength,
  requiredIf,
  email,
  minLength,
} from "vuelidate/lib/validators";
import ConfirmationModal from "@/view/components/modals/ConfirmationModal";
export default {
  name: "UpdateDetailedFamilyInformationModal",
  components: {
    ConfirmationModal,
  },
  props: {
    selectedFamilyMember: {
      type: Object,
    },
    section7: {
      type: Object,
    },
  },

  data() {
    return {
      switch1: false,
      modalState: false,
      e1: 1,
      steps: 3,
      date: "",
      dateDialogState: false,
      activePicker: null,
    };
  },
  watch: {
    dateDialogState(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    selectedFamilyMember(newvalue) {
      if (newvalue) {
        const hasDui = !!this.selectedFamilyMember.dui;
        const isNitEmptyOrNull = !this.selectedFamilyMember.nit;
        if (hasDui && isNitEmptyOrNull) {
          this.switch1 = true;
        } else {
          this.switch1 = false;
        }
        return this.switch1;
      }
    },
  },
  methods: {
    save(date) {
      this.$refs.dialog.save(date);
    },
    loadMunicipalities(departmentId) {
      this.$emit("getMunicipio", departmentId);
    },
    toggleModal() {
      this.modalState = !this.modalState;
    },
    changeNationality() {
      this.selectedFamilyMember.departmentId = null;
      this.selectedFamilyMember.municipalityId = null;
    },
    resetMunicipality() {
      this.selectedFamilyMember.municipalityId = null;
    },
    validate(step) {
      if (step == 1) {
        this.$v.selectedFamilyMember.firstName.$touch();
        this.$v.selectedFamilyMember.lastName.$touch();
        this.$v.selectedFamilyMember.email.$touch();
        this.$v.selectedFamilyMember.kinshipId.$touch();
        this.$v.selectedFamilyMember.cellphone.$touch();
        this.$v.selectedFamilyMember.phone.$touch();
        this.$v.selectedFamilyMember.dui.$touch();
        if (!this.switch1) {
          this.$v.selectedFamilyMember.nit.$touch();
        }
        if (
          this.$v.selectedFamilyMember.firstName.$invalid ||
          this.$v.selectedFamilyMember.lastName.$invalid ||
          this.$v.selectedFamilyMember.email.$invalid ||
          this.$v.selectedFamilyMember.kinshipId.$invalid ||
          this.$v.selectedFamilyMember.cellphone.$invalid ||
          this.$v.selectedFamilyMember.phone.$invalid ||
          this.$v.selectedFamilyMember.dui.$invalid ||
          this.$v.selectedFamilyMember.nit.$invalid
        ) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar los campos requeridos",
          });
          return;
        } else {
          this.nextStep(step);
        }
      } else if (step == 2) {
        this.$v.selectedFamilyMember.birthday.$touch();
        this.$v.selectedFamilyMember.maritalStatusId.$touch();
        this.$v.selectedFamilyMember.nationalityId.$touch();
        this.$v.selectedFamilyMember.departmentId.$touch();
        this.$v.selectedFamilyMember.municipalityId.$touch();
        if (
          this.$v.selectedFamilyMember.birthday.$invalid ||
          this.$v.selectedFamilyMember.maritalStatusId.$invalid ||
          this.$v.selectedFamilyMember.nationalityId.$invalid ||
          this.$v.selectedFamilyMember.departmentId.$invalid ||
          this.$v.selectedFamilyMember.municipalityId.$invalid
        ) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar los campos requeridos",
          });
          return;
        } else {
          this.nextStep(step);
        }
      } else if (step == 3) {
        this.$v.selectedFamilyMember.profession.$touch();
        if (this.$v.selectedFamilyMember.profession.$invalid) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar los campos requeridos",
          });
          return;
        }
        this.sendData();
      }
    },
    sendData() {
      this.$emit("update");
      this.resetForm();
      this.toggleModal();
    },
    nextStep(n) {
      this.e1 = n + 1;
    },
    resetForm() {
      this.e1 = 1;
      this.$v.$reset();
    },
    previousStep(n) {
      this.e1 = n - 1;
    },
    changeSwitchNit() {
      this.$v.$reset();
      this.selectedFamilyMember.nit = "";
    },
    showMdlConfirm() {
      this.$refs.confirmationModal.toggleModal();
    },
  },
  validations: {
    selectedFamilyMember: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      kinshipId: { required },
      phone: {
        required: requiredIf(function() {
          return (
            !!this.selectedFamilyMember.phone &&
            String(this.selectedFamilyMember.phone).length > 1
          );
        }),
        minLength: minLength(8),
      },
      cellphone: { required, minLength: minLength(8) },
      dui: { required, minLength: minLength(10) },
      nit: {
        required: requiredIf(function() {
          return !this.switch1;
        }),
        minLength: minLength(17),
      },
      birthday: { required },
      maritalStatusId: { required },
      nationalityId: { required },
      departmentId: {
        required: requiredIf(function() {
          return this.selectedFamilyMember.nationalityId == 1;
        }),
      },
      municipalityId: {
        required: requiredIf(function() {
          return (
            this.selectedFamilyMember.nationalityId == 1 &&
            !!this.selectedFamilyMember.departmentId
          );
        }),
      },
      profession: { required },
    },
  },
};
</script>
