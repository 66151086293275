import ApiService from "@/core/services/api.service";

// the medicines are being fetched at the "medicineRepository"

//The diseases are being fetched at the "diseasesRepository"

// Prescribed medicines

/**
 * GET request to fetch all data from prescribed medicines
 * @returns {*}
 */
export const getAllPrescribedMedicines = () => {
  return ApiService.get("student-medicines/medicine/prescribed");
};

/**
 * GET request to fetch a prescribed medicine by Id
 * @params id
 * @returns {*}
 */
export const getPrescribedMedicineById = (id) => {
  return ApiService.get(`student-medicines/${id}`);
};

/**
 * POST request to add a new prescribed medicine
 * @params payload
 * @returns {*}
 */
export const addPrescribedMedicine = (payload) => {
  return ApiService.post("student-medicines/medicine/prescribed", payload);
};

/**
 * PUT request to update a prescribed medicine
 * @params id
 * @params payload
 * @returns {*}
 */
export const updatePrescribedMedicine = (id, payload) => {
  return ApiService.put(`student-medicines/medicine/prescribed/${id}`, payload);
};

/**
 * DELETE request to delete a prescribed medicine
 * @params id
 * @returns {*}
 */
export const deletePrescribedMedicine = (id) => {
  return ApiService.delete(`student-medicines/medicine/prescribed/${id}`);
};

//Medicines allergic to

/**
 * GET request to fetch all medicines the student is allergic to
 * @returns {*}
 */
export const getAllMedicinesAllergicTo = () => {
  return ApiService.get("student-medicines/medicine/allergic");
};

/**
 * POST request to save all medicines the student is allergic to
 * @params payload
 * @returns {*}
 */
export const addMedicineAllergicTo = (payload) => {
  return ApiService.post("student-medicines/medicine/allergic", payload);
};

//Conditions and extra medical information

/**
 * POST request to add medical conditions
 * @params payload
 * @returns {*}
 */
export const addMedicalConditionsInformation = (payload) => {
  return ApiService.post("student-diseases", payload);
};

/**
 * GET request to fetch all medicines the student is allergic to
 * @returns {*}
 */
export const getAllStudentDiseases = () => {
  return ApiService.get("student-diseases/diseases");
};

/**
 * GET request to fetch all medicines the student is allergic to
 * @returns {*}
 */
export const getAllStudentConditions = () => {
  return ApiService.get("student-diseases/conditions");
};

/**
 * GET request to fetch all medicines the student is allergic to
 * @params payload
 * @returns {*}
 */
export const addExtraConditionsInformation = (payload) => {
  return ApiService.post("students/step-five", payload);
};

export default {
  getAllPrescribedMedicines,
  addPrescribedMedicine,
  updatePrescribedMedicine,
  deletePrescribedMedicine,
  getAllMedicinesAllergicTo,
  addMedicineAllergicTo,
  addMedicalConditionsInformation,
  addExtraConditionsInformation,
  getAllStudentDiseases,
  getAllStudentConditions,
};
