<template>
  <v-dialog
    v-model="modalState"
    transition="dialog-bottom-transition"
    max-width="600px"
    persistent
    scrollable
  >
    <!-- Floating input form - Colegios o escuelas anteriores -->
    <v-card elevation="1">
      <!-- form header name - Colegios o escuelas anteriores -->
      <v-card-title class="text-h5 blue-grey darken-3 white--text">
        Actualizar colegio o escuela anterior
      </v-card-title>

      <v-card-text class="my-0 py-0 mt-5">
        <v-form ref="updateForm_section3">
          <v-container>
            <v-row>
              <!-- Nombre de centro educativo - Colegios o escuelas anteriores -->
              <v-col cols="12" sm="6" class="pa-0 pa-sm-2 col-md-6">
                <v-select
                  :items="section3.schoolDetails.departments"
                  item-text="name"
                  item-value="id"
                  v-model="formattedValues.deparmentId"
                  label="Departamento"
                  color="blue-grey darken-3"
                  :rules="rules.required"
                  required
                  outlined
                  @change="sendData(formattedValues.deparmentId)"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                <v-select
                  :items="formattedValues.municipio"
                  item-text="name"
                  item-value="id"
                  v-model="formattedValues.municipalityId"
                  label="Municipio"
                  color="blue-grey darken-3"
                  :rules="rules.required"
                  required
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" class="pa-0 pa-sm-2">
                <v-select
                  :items="section3.schoolDetails.schools"
                  item-text="name"
                  item-value="id"
                  v-model="formattedValues.schoolId"
                  label="Nombre de centro educativo"
                  color="blue-grey darken-3"
                  :rules="rules.required"
                  required
                  outlined
                ></v-select>
              </v-col>
              <!-- De (grado) - Colegios o escuelas anteriores -->
              <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                <v-select
                  :items="section3.schoolDetails.grade"
                  item-text="name"
                  item-value="id"
                  v-model="formattedValues.gradeFrom"
                  label="De (grado)"
                  color="blue-grey darken-3"
                  :rules="rules.required"
                  required
                  outlined
                ></v-select>
              </v-col>
              <!-- A (grado) - Colegios o escuelas anteriores -->
              <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                <v-select
                  :items="section3.schoolDetails.grade"
                  item-text="name"
                  item-value="id"
                  v-model="formattedValues.gradeTo"
                  label="A (grado)"
                  color="blue-grey darken-3"
                  :rules="rules.required"
                  required
                  outlined
                ></v-select>
              </v-col>
              <!-- Motivo de cambio - Colegios o escuelas anteriores -->
              <v-col cols="12" class="pa-0 pa-sm-2">
                <v-text-field
                  autocomplete="null"
                  v-model="formattedValues.reasonChange"
                  label="Motivo de cambio"
                  color="blue-grey darken-3"
                  :rules="rules.required"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-end pt-0 pb-3">
        <v-spacer></v-spacer>
        <!-- cancel btn - Colegios o escuelas anteriores -->
        <v-btn
          color="blue-grey darken-3"
          outlined
          text
          @click="showMdlConfirm()"
        >
          Cancelar
        </v-btn>
        <!-- save data btn - Colegios o escuelas anteriores -->
        <v-btn
          color="blue-grey darken-3"
          class="white--text"
          depressed
          @click="validate()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmationModal
      ref="confirmationModal"
      v-on:confirmData="toggleModal"
      v-on:resetForm="resetForm"
    ></ConfirmationModal>
  </v-dialog>
</template>

<script>
import ConfirmationModal from "@/view/components/modals/ConfirmationModal";
export default {
  name: "UpdatePreviousSchoolModal",
  components: {
    ConfirmationModal,
  },
  props: {
    rules: {
      type: Object,
    },
    section3: {
      type: Object,
    },
    formattedValues: {
      type: Object,
    },
  },
  data() {
    return {
      modalState: false,
    };
  },
  methods: {
    sendData(id) {
      this.$emit("selectedDepartment", id);
    },
    toggleModal() {
      this.modalState = !this.modalState;
    },
    validate() {
      this.$emit("update");
      this.toggleModal();
    },
    getMunicipio() {
      this.$emit("getMunicipio", this.formattedValues.deparmentId);
    },
    showMdlConfirm() {
      this.$refs.confirmationModal.toggleModal();
    },
    resetForm() {
      this.$refs.updateForm_section3.reset();
    },
  },
};
</script>
