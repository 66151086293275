import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllStudentFamiliarInformation = () => {
  return ApiService.get("student-familiar-informations");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const updateStudentFamiliarInformation = (payload) => {
  return ApiService.post("student-familiar-informations", payload);
};

export default {
  getAllStudentFamiliarInformation,
  updateStudentFamiliarInformation,
};
