<template>
  <!-- begin::First section - Información del aspirante -->
  <v-sheet elevation="0" class="rounded-lg mt-4 pa-3">
    <!-- begin::card header - Información del aspirante -->
    <v-row
      class="d-flex flex-column-reverse flex-sm-row blue accent-5 mb-8 rounded white--text"
    >
      <!-- begin::section title - Dirección -->
      <v-col
        cols="12"
        class="d-flex align-center justify-center justify-md-start flex-column flex-md-row"
      >
        <div class="d-flex flex-row justify-start align-items-center">
          <p class="text-h5 mb-0">Paso</p>
          <v-icon color="white" class="mx-2"
            >mdi-numeric-{{ step }}-circle-outline</v-icon
          >
        </div>
        <p class="ma-0 text-h6 text-center text-md-left mt-2 mt-sm-0">
          <span class="font-weight-medium text-h5 mr-1 d-none d-md-inline"
            >-</span
          >
          Información general del estudiante
        </p>
      </v-col>
      <!-- end::step - Dirección -->
    </v-row>
    <!-- begin::card body - Información del aspirante -->

    <!-- begin::student information input -->

    <v-form ref="createForm_section1">
      <v-row v-if="false" class="mt-n8 d-flex justify-center">
        <v-col cols="12" class="px-0">
          <v-alert prominent dense text type="info" class="rounded-lg">
            <p class="text-h6 grey--text text--darken-4 mb-0">
              La religión NO es condicionante para elección en el proceso de
              admisión.
            </p>
          </v-alert>
        </v-col>
      </v-row>
      <!-- begin::student profile picture - Información del aspirante -->
      <v-row class="responsive" v-show="false">
        <v-col
          cols="12"
          md="4"
          lg="4"
          class="d-flex justify-center flex-column col-1"
        >
          <div
            class="d-flex align-center justify-center mb-5"
            style="background-size: cover; width: 100%"
          >
            <img
              id="userProfile"
              ref="imgUserProfile"
              class="rounded-lg user__profile-picture imgResponsive"
              style="object-fit: contain; width: 100%"
              :src="section1.userCommonDetails.userDefaultProfilePicture"
            />
          </div>

          <v-file-input
            v-model="userPersonalDetails.userProfilePicture"
            color="light-blue darken-4"
            label="Foto"
            outlined
            class="mt-3"
            accept="image/*"
            id="inputProfile"
            ref="fileInputProfile"
            @change="
              setImageAfterUpload(
                $refs.fileInputProfile.id,
                $refs.imgUserProfile.id
              )
            "
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                v-if="index < 2"
                color="light-blue darken-4"
                dark
                label
                small
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-divider vertical inset class="d-none d-sm-block"></v-divider>
        <!-- begin::Photography instructions - Información del aspirante -->
        <v-col cols="12" md="8" lg="8" class="col-2">
          <p class="headline font-weight-bold mb-2">
            Instrucciones para toma de fotografia:
          </p>
          <p class="text-body-1">
            A continuación, se presentan las instrucciones de la foto que debe
            enviar en el formulario.
          </p>
          <!-- begin::instructions for taking pictures on medium and large devices -->
          <div class="instructions-large">
            <p class="text-h6 font-weight-medium mb-0">
              Instrucciones generales:
            </p>
            <ul>
              <li>
                <p class="text-body-1 mb-0">
                  <span class="font-weight-bold">Rostro: </span>Completamente
                  descubierto, sin cabello en la frente, no aretes, ni collares.
                </p>
              </li>
              <li>
                <p class="text-body-1 mb-0">
                  De manera
                  <span class="font-weight-bold">obligatoria</span> vestir una
                  camisa blanca.
                </p>
              </li>
              <li>
                <p class="text-body-1 mb-0">
                  Fondo claro de preferencia que sea una pared.
                </p>
              </li>
              <li>
                <p class="text-body-1 mb-0">Una buena iluminación.</p>
              </li>
            </ul>
          </div>
          <div class="instructions-large">
            <p class="text-h6 font-weight-medium mb-0">
              Instrucciones para caballeros:
            </p>
            <ul>
              <li>
                <p class="text-body-1 mb-0">
                  Los caballeros con su rostro debidamente afeitado.
                </p>
              </li>
              <li>
                <p class="text-body-1 mb-0">Con el corte francesa oscura.</p>
              </li>
              <li>
                <p class="text-body-1 mb-0">
                  No se admiten fotos en las que estén rapados o con cortes de
                  cabello levantado, extravagante, entre otros.
                </p>
              </li>
            </ul>
          </div>
          <div class="instructions-large">
            <p class="text-h6 font-weight-medium mb-0">
              Instrucciones para damas:
            </p>
            <ul>
              <li>
                <p class="text-body-1 mb-0">Cabello recogido</p>
              </li>
              <li>
                <p class="text-body-1 mb-0">
                  Tradicional, sin cabello en el rostro (No flecos ni cepillos
                  que cubran la frente).
                </p>
              </li>
            </ul>
          </div>
          <!-- begin::instructions for taking pictures on medium and large devices -->
          <!-- begin::displayed on small devices -->
          <v-expansion-panels
            v-model="activePanelState"
            class="instructions-small"
          >
            <v-expansion-panel active-class="">
              <v-expansion-panel-header>
                <p class="text-h6 font-weight-medium mb-0">
                  Instrucciones generales:
                </p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li>
                    <p class="text-body-1 mb-0">
                      <span class="font-weight-bold">Rostro: </span
                      >Completamente descubierto, sin cabello en la frente, no
                      aretes, ni collares.
                    </p>
                  </li>
                  <li>
                    <p class="text-body-1 mb-0">
                      De manera
                      <span class="font-weight-bold">obligatoria</span>
                      vestir una camisa blanca.
                    </p>
                  </li>
                  <li>
                    <p class="text-body-1 mb-0">
                      Fondo claro de preferencia que sea una pared.
                    </p>
                  </li>
                  <li>
                    <p class="text-body-1 mb-0">Una buena iluminación.</p>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels class="instructions-small">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <p class="text-h6 font-weight-medium mb-0">
                  Instrucciones para caballeros:
                </p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li>
                    <p class="text-body-1 mb-0">
                      Los caballeros con su rostro debidamente afeitado.
                    </p>
                  </li>
                  <li>
                    <p class="text-body-1 mb-0">
                      Con el corte francesa oscura.
                    </p>
                  </li>
                  <li>
                    <p class="text-body-1 mb-0">
                      No se admiten fotos en las que estén rapados o con cortes
                      de cabello levantado, extravagante, entre otros.
                    </p>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels class="instructions-small">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <p class="text-h6 font-weight-medium mb-0">
                  Instrucciones para damas:
                </p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ul>
                  <li>
                    <p class="text-body-1 mb-0">Cabello recogido</p>
                  </li>
                  <li>
                    <p class="text-body-1 mb-0">
                      Tradicional, sin cabello en el rostro (No flecos ni
                      cepillos que cubran la frente).
                    </p>
                  </li>
                </ul>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- begin::displayed on small devices -->
        </v-col>
        <!-- begin::Photography instructions - Información del aspirante -->
      </v-row>
      <v-divider v-show="false"></v-divider>
      <!-- end::student profile picture - Información del aspirante -->
      <!-- Table instructions and information - Información familiar -->

      <v-row class="mt-4">
        <!-- nombre - Información del aspirante -->
        <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
          <form-group
            name="nombre"
            :validator="$v.userPersonalDetails.firstName"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              autocomplete="null"
              label="Nombres"
              required
              color="light-blue darken-4"
              v-model="userPersonalDetails.firstName"
              :counter="25"
              outlined
            ></v-text-field>
          </form-group>
        </v-col>

        <!-- Apellido - Información del aspirante -->
        <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
          <form-group
            name="apellido"
            :validator="$v.userPersonalDetails.lastName"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              autocomplete="null"
              label="Apellidos"
              required
              color="light-blue darken-4"
              v-model="userPersonalDetails.lastName"
              :counter="25"
              outlined
            ></v-text-field>
          </form-group>
        </v-col>

        <!-- Fecha de nacimiento - Información del aspirante -->
        <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
          <v-menu
            ref="dateDialog"
            v-model="dateDialogState"
            :return-value="userPersonalDetails.birthday"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="250px"
          >
            <template v-slot:activator="{ on, attrs }">
              <form-group
                name="fecha de nacimiento"
                :validator="$v.userPersonalDetails.birthday"
                v-bind="attrs"
              >
                <v-text-field
                  autocomplete="null"
                  v-model="userPersonalDetails.birthday"
                  label="Fecha de nacimiento"
                  color="light-blue darken-4"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  clearable
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  v-on="on"
                  required
                ></v-text-field>
              </form-group>
            </template>
            <v-date-picker
              v-model="userPersonalDetails.birthday"
              :active-picker.sync="activePicker"
              color="light-blue darken-4"
              :max="
                new Date(
                  Date.now() -
                    315576000000 -
                    new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substr(0, 10)
              "
              :min="
                new Date(
                  Date.now() -
                    788940000000 -
                    new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substr(0, 10)
              "
              @change="save(userPersonalDetails.birthday)"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- Género - Información del aspirante -->
        <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
          <form-group
            name="género"
            :validator="$v.userPersonalDetails.genderId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section1.userCommonDetails.gender"
              item-text="name"
              item-value="id"
              label="Género"
              color="light-blue darken-4"
              v-model="userPersonalDetails.genderId"
              outlined
              required
            ></v-select>
          </form-group>
        </v-col>

        <!-- Nacionalidad - Información del aspirante -->
        <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
          <form-group
            name="nacionalidad"
            :validator="$v.userPersonalDetails.nationalityId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section1.userCommonDetails.nationality"
              item-text="name"
              item-value="id"
              label="Nacionalidad"
              color="light-blue darken-4"
              v-model="userPersonalDetails.nationalityId"
              outlined
              required
            ></v-select>
          </form-group>
        </v-col>

        <!-- Deporte practicado - Información del aspirante -->
        <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
          <form-group
            name="deporte"
            :validator="$v.userPersonalDetails.sportId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section1.userCommonDetails.sports"
              item-text="name"
              item-value="id"
              label="Deporte que practica"
              color="light-blue darken-4"
              v-model="userPersonalDetails.sportId"
              outlined
              required
            ></v-select>
          </form-group>
        </v-col>

        <!-- Número teléfónico celular - Información del aspirante -->
        <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <form-group
                name="celular"
                :validator="$v.userPersonalDetails.cellphone"
                :messages="{
                  minLength:
                    'El campo celular debería contener al menos 8 números',
                }"
                v-bind="attrs"
              >
                <v-text-field
                  autocomplete="null"
                  label="Teléfono celular"
                  required
                  color="light-blue darken-4"
                  v-model="userPersonalDetails.cellphone"
                  outlined
                  v-mask="'########'"
                  v-bind="attrs"
                  slot-scope="{ attrs }"
                  v-on="on"
                ></v-text-field>
              </form-group>
            </template>
            <span
              ><v-icon> mdi-information </v-icon> Si no posee número celular
              propio, ingresar el del responsable.</span
            >
          </v-tooltip>
        </v-col>

        <!-- Número telefónico residencial - Información del aspirante -->
        <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
          <form-group
            name="telefono"
            :validator="$v.userPersonalDetails.phone"
            :messages="{
              minLength:
                'El campo teléfono residencial debería contener al menos 8 números',
            }"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              autocomplete="null"
              label="Teléfono residencial"
              required
              color="light-blue darken-4"
              v-model="userPersonalDetails.phone"
              outlined
              v-mask="'2#######'"
            >
            </v-text-field>
          </form-group>
        </v-col>
        <!-- religión - Información familiar -->
        <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
          <form-group
            name="religión"
            :validator="$v.userPersonalDetails.religionId"
          >
            <v-select
              v-bind="attrs"
              slot-scope="{ attrs }"
              :items="section1.userCommonDetails.religions"
              item-text="name"
              item-value="id"
              label="Religión"
              color="light-blue darken-4"
              v-model="userPersonalDetails.religionId"
              @change="getSacraments(userPersonalDetails.religionId)"
              required
              outlined
            ></v-select>
          </form-group>
        </v-col>
        <!-- Sacramentos - Información del aspirante -->
        <!-- Opcional select, this only appear if the selected religion equals to 'Católico' -->
        <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
          <form-group
            name="sacramento"
            :validator="$v.userPersonalDetails.sacraments"
            v-if="userPersonalDetails.religionId == 1"
          >
            <v-select
              v-bind="attrs"
              slot-scope="{ attrs }"
              :items="section1.userCommonDetails.sacraments"
              item-text="name"
              item-value="id"
              label="Sacramentos"
              color="light-blue darken-4"
              v-model="userPersonalDetails.sacraments"
              :disabled="!userPersonalDetails.religionId"
              small-chips
              multiple
              required
              outlined
            >
              <template v-slot:selection="{ item, index }">
                <v-chip color="light-blue darken-4" small v-if="index === 0">
                  <span class="white--text my-1">{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="black--text text-caption">
                  (+{{ userPersonalDetails.sacraments.length - 1 }}
                  seleccionados)
                </span>
              </template>
            </v-select>
          </form-group>
        </v-col>
      </v-row>
    </v-form>
    <!-- end::student information input -->
  </v-sheet>
  <!-- end::First section - Información del aspirante -->
</template>

<script>
import {
  required,
  maxLength,
  minLength,
  requiredIf,
} from "vuelidate/lib/validators";
import sacramentRepository from "@/repositories/sacramentRepository";
import { createNamespacedHelpers, mapGetters } from "vuex";
import firstStepRepository from "@/repositories/steps/firstStepRepository";
export default {
  name: "AplicantInformation",
  props: {
    section1: {
      type: Object,
    },
    step: {
      type: Number,
    },
  },
  data() {
    return {
      activePicker: null,
      userPersonalDetails: {},
      userSacramentReligion: {
        religionId: "",
        sacramentsId: [],
      },
      dateDialogState: false,
      activePanelState: 0,
      // progress: 1.5, //Se utiliza en el index para indicar el progreso
    };
  },
  watch: {
    dateDialogState(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    "section1.userCommonDetails.sacraments"() {
      if (
        this.userPersonalDetails?.religionId != 1 &&
        !!this.userPersonalDetails.religionId
      ) {
        this.userPersonalDetails.sacraments = [
          this.section1.userCommonDetails.sacraments[0].id,
        ];
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  mounted() {
    this.getSacramentsAndReligion();
  },
  methods: {
    getSacramentsAndReligion() {
      firstStepRepository
        .getSacramentsByStudent()
        .then(({ data }) => {
          data.forEach((element) => {
            this.userSacramentReligion.religionId =
              element.Sacrament.religionId;
            this.userSacramentReligion.sacramentsId.push(element.sacramentId);
          });
          this.getStudentInformation();
        })
        .catch((error) => {});
    },
    getStudentInformation() {
      this.getSacraments(this.userSacramentReligion.religionId);
      this.userPersonalDetails = {
        firstName: this.currentUserPersonalInfo.firstName
          ? this.currentUserPersonalInfo.firstName
          : undefined,
        lastName: this.currentUserPersonalInfo.lastName
          ? this.currentUserPersonalInfo.lastName
          : undefined,
        birthday: this.currentUserPersonalInfo.birthday
          ? this.currentUserPersonalInfo.birthday
          : undefined,
        genderId: this.currentUserPersonalInfo.genderId
          ? this.currentUserPersonalInfo.genderId
          : undefined,
        nationalityId: this.currentUserPersonalInfo.nationalityId
          ? this.currentUserPersonalInfo.nationalityId
          : undefined,
        sportId: this.currentUserPersonalInfo.sportId
          ? this.currentUserPersonalInfo.sportId
          : undefined,
        cellphone: this.currentUserPersonalInfo.cellphone
          ? this.currentUserPersonalInfo.cellphone
          : undefined,
        phone: this.currentUserPersonalInfo.phone
          ? this.currentUserPersonalInfo.phone
          : undefined,
        religionId: this.userSacramentReligion.religionId
          ? this.userSacramentReligion.religionId
          : undefined,
        sacraments: this.userSacramentReligion.sacramentsId
          ? this.userSacramentReligion.sacramentsId
          : undefined,
      };
    },
    getSacraments(religionId) {
      sacramentRepository
        .sacramentsByReligions(religionId)
        .then(({ data }) => {
          this.section1.userCommonDetails.sacraments = data;
        })
        .catch(() => {});
    },
    resetSacrament() {
      this.userPersonalDetails.sacraments = [];
    },
    update() {
      this.$emit("loading");
      firstStepRepository
        .UpdateStepOne(this.userPersonalDetails)
        .then(({ data }) => {
          this.updateReligionAndSacrament();
          this.fireToast({
            icon: "success",
            title: data.message,
          });
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar modificar los datos",
          });
        });
    },
    updateReligionAndSacrament() {
      firstStepRepository
        .updateSacramentsAndReligions(this.userPersonalDetails)
        .then(() => {
          this.$emit("next");
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar modificar los datos",
          });
        })
        .finally(() => {
          this.$emit("stopLoading");
        });
    },
    //Funcion para cambiar la foto del estudiante
    // setImageAfterUpload(input, img) {
    //   var contenedor = document.getElementById(img);
    //   var archivos = document.getElementById(input).files;
    //   if (this.userPersonalDetails.userProfilePicture == null) {
    //     contenedor.src = this.section1.userCommonDetails.userDefaultProfilePicture;
    //   } else {
    //     for (let i = 0; i < archivos.length; i++) {
    //       contenedor.src = URL.createObjectURL(archivos[i]);
    //     }
    //   }
    // },
    validate() {
      if (this.userPersonalDetails.religionId != 1) {
        this.$v.userPersonalDetails.firstName.$touch();
        this.$v.userPersonalDetails.lastName.$touch();
        this.$v.userPersonalDetails.birthday.$touch();
        this.$v.userPersonalDetails.genderId.$touch();
        this.$v.userPersonalDetails.nationalityId.$touch();
        this.$v.userPersonalDetails.sportId.$touch();
        this.$v.userPersonalDetails.cellphone.$touch();
        this.$v.userPersonalDetails.phone.$touch();
        this.$v.userPersonalDetails.religionId.$touch();
        if (
          this.$v.userPersonalDetails.firstName.$invalid ||
          this.$v.userPersonalDetails.lastName.$invalid ||
          this.$v.userPersonalDetails.birthday.$invalid ||
          this.$v.userPersonalDetails.genderId.$invalid ||
          this.$v.userPersonalDetails.nationalityId.$invalid ||
          this.$v.userPersonalDetails.sportId.$invalid ||
          this.$v.userPersonalDetails.cellphone.$invalid ||
          this.$v.userPersonalDetails.phone.$invalid ||
          this.$v.userPersonalDetails.religionId.$invalid
        ) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar los campos requeridos",
          });
          return;
        } else {
          this.update();
        }
      } else {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar los campos requeridos",
          });
          return;
        }
        this.update();
      }
    },
    save(date) {
      this.$refs.dateDialog.save(date);
    },
  },
  validations: {
    userPersonalDetails: {
      firstName: {
        required,
        maxLength: maxLength(255),
      },
      lastName: {
        required,
        maxLength: maxLength(255),
      },
      birthday: { required },
      genderId: { required },
      nationalityId: { required },
      sportId: { required },
      cellphone: { required, minLength: minLength(8) },
      phone: {
        required: requiredIf(function() {
          return !!this.userPersonalDetails.phone;
        }),
        minLength: minLength(8),
      },

      religionId: { required },
      sacraments: { required },
    },
  },
};
</script>
<style scoped>
.instructions-small {
  display: none;
}

.imgResponsive {
  max-width: 302.19px !important;
}

@media screen and (min-width: 798px) and (max-width: 959px) {
  .col-1 {
    flex: 1;
  }

  .imgResponsive {
    max-width: 275px !important;
  }

  .col-2 {
    flex: 1;
  }
}

@media only screen and (max-width: 797px) {
  .imgResponsive {
    max-width: 250px !important;
  }

  .instructions-large {
    display: none;
  }

  .instructions-small {
    display: block;
  }
}
</style>
