import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllCoexistences = () => {
    return ApiService.get("coexistences");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getCoexistence = (id) => {
    return ApiService.get("coexistences", id);
};

export default {
    getAllCoexistences,
    getCoexistence,
};
