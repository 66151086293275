import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllGenders = () => {
  return ApiService.get("genders");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getGender = (id) => {
  return ApiService.get(`genders/${id}`);
};

export default {
  getAllGenders,
  getGender,
};
