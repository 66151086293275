<template>
  <div>
    <v-card class="mx-auto score-sheet-card-large" max-width="370" outlined>
      <v-list-item three-line>
        <!-- begin::card title -->
        <v-list-item-content>
          <div>
            <p class="text-overline ma-0 mb-n2">
              {{ upperTitle }}
            </p>
          </div>

          <p class="text-h5 mb-0">{{ mainTitle }}</p>
          <p
            class="ma-0 text-body-1 font-weight-bold"
            style="color: black !importante"
          >
            {{ subTitle }}
            <!-- begin::card email -->
          </p>
        </v-list-item-content>
        <!-- end::card title -->

        <!-- begin::card icon -->

        <v-list-item-avatar
          tile
          size="80"
          color="blue-grey lighten-5"
          class="rounded-lg"
        >
          <v-icon x-large :color="iconColor" class="ml-n1">{{ icon }}</v-icon>
        </v-list-item-avatar>

        <!-- end::card icon -->
      </v-list-item>
    </v-card>
    <v-card class="mx-auto rounded score-sheet-card-small" outlined>
      <div class="mt-4">
        <p class="text-overline text-center ma-0 mb-n2">
          {{ upperTitle }}
        </p>
      </div>

      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-list-item-avatar
            tile
            size="80"
            color="blue-grey lighten-5"
            class="rounded-lg mx-auto"
          >
            <v-icon x-large :color="iconColor">
              {{ icon }}
            </v-icon>
          </v-list-item-avatar>
        </v-col>
      </v-row>

      <p class="text-h5 mb-0 text-center px-1">
        {{ mainTitle }}
      </p>
      <p
        class="ma-0 mb-2 text-body-1 text-center"
        style="color: black !importante"
      >
        {{ subTitle }}
        <!-- begin::card email -->
      </p>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: "SmallSummaryCard",
    props: {
      upperTitle: {
        type: [String, Object, Number],
      },
      mainTitle: {
        type: [String, Object, Number],
      },
      subTitle: {
        type: [String, Object, Number],
      },
      icon: {
        type: String,
      },
      iconColor: {
        type: String,
      },
      dataObj: {
        type: Object,
      },
    },
  };
</script>
