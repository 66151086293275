import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllDiseases = () => {
    return ApiService.get("diseases");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getDisease = (id) => {
    return ApiService.get("diseases", id);
};

export default {
    getAllDiseases,
    getDisease,
};
