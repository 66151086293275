import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @params payload
 * @returns {*}
 */
export const UpdateStepTwo = (payload) => {
    return ApiService.post("students/step-two", payload);
};

export default {
    UpdateStepTwo,
};
