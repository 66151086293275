import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSalaryRanges = () => {
  return ApiService.get("salary-ranges");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getSalaryRange = (id) => {
  return ApiService.get(`salary-ranges/${id}`);
};

export default {
  getAllSalaryRanges,
  getSalaryRange,
};
