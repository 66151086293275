import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllStepThree = () => {
    return ApiService.get("student-schools");
};

/**
* GET request to fetch all data
* @returns {*}
*/
export const getStepThree = (id) => {
    return ApiService.get(`student-schools/${id}`);
};
/**
 * GET request to fetch all data
 * @params payload
 * @returns {*}
 */
export const createStepThree = (payload) => {
    return ApiService.post("student-schools", payload);
};

/**
 * PUT request to update a specific enrollment process student
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateStepThree = (id, payload) => {
    return ApiService.update("student-schools", id, payload);
};
/**
 * DELETE request to delete the specified student-schools
 * @param id
 * @returns {*}
 */

export const deleteStepThree = (id) => {
    return ApiService.delete(`student-schools/${id}`);
};

export default {
    getAllStepThree,
    getStepThree,
    createStepThree,
    updateStepThree,
    deleteStepThree
};
