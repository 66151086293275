<template>
  <v-dialog
    v-model="modalState"
    width="500px"
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <v-card-title class="text-h5 light-blue darken-2 white--text text-center">
        Información detallada
      </v-card-title>

      <v-card-text class=" pt-3 pb-1">
        <v-row v-if="selectedItem.isFinancialSupporter">
          <v-col cols="12">
            <v-alert type="info" dense text>
              Esta información se muestra según lo que hayas ingresado en el
              <span class="font-weight-bold">paso 1</span>. Si deseas actualizar
              algún dato debes regresar a dicho paso.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="grey lighten-4 rounded-lg pa-3">
              <div class="d-flex align-center mb-4 flex-column flex-sm-row">
                <v-btn
                  color="primary"
                  elevation="2"
                  class="mb-2 mb-sm-0 white--text"
                  fab
                  x-small
                  ><v-icon>mdi-account-circle-outline</v-icon></v-btn
                >
                <p class="text-h5 mb-0 ml-sm-2 font-weight-medium">
                  Información personal
                </p>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Nombre</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.firstName }} {{ selectedItem.lastName }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Parentesco</div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedItem.Kinship ? selectedItem.Kinship.name : undefined
                  }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Fecha de nacimiento</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.birthday }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">DUI</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.dui }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">NIT</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.nit }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">Correo electrónico</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.email }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">Teléfono celular</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.cellphone }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">
                  Teléfono de residencia
                </div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.phone }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="grey lighten-4 rounded-lg pa-3">
              <div class="d-flex align-center mb-4 flex-column flex-sm-row">
                <v-btn
                  color="success"
                  elevation="2"
                  class="mb-2 mb-sm-0 white--text"
                  fab
                  x-small
                  ><v-icon>mdi-map-marker-circle</v-icon></v-btn
                >
                <p class="text-h5 mb-0 ml-sm-2 font-weight-medium">
                  Origen
                </p>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Estado civil</div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedItem.MaritalStatus
                      ? selectedItem.MaritalStatus.name
                      : undefined
                  }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Nacionalidad</div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedItem.Nationality
                      ? selectedItem.Nationality.name
                      : undefined
                  }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">
                  Departamento de nacimiento
                </div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedItem.Municipality
                      ? selectedItem.Municipality.Department
                        ? selectedItem.Municipality.Department.name
                        : undefined
                      : undefined
                  }}
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">
                  Município de nacimiento
                </div>
                <div class="text-body-1 font-weight-medium">
                  {{
                    selectedItem.Municipality
                      ? selectedItem.Municipality.name
                      : undefined
                  }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="grey lighten-4 rounded-lg pa-3">
              <div class="d-flex align-center mb-4 flex-column flex-sm-row">
                <v-btn
                  color="light-blue white--text"
                  elevation="2"
                  class="mb-2 mb-sm-0"
                  fab
                  x-small
                  ><v-icon>mdi-briefcase-outline</v-icon></v-btn
                >
                <p class="text-h5 mb-0 ml-sm-2 font-weight-medium">
                  Información laboral
                </p>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Profesión u oficio</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.profession }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">Lugar de trabajo</div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.workplace }}
                </div>
              </div>
              <div class="mb-3">
                <div class="grey--text text-overline">
                  Teléfono de trabajo<span v-if="selectedItem.extension">
                    > extensión</span
                  >
                </div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.workPhone
                  }}<span v-if="selectedItem.extension">
                    > {{ selectedItem.extension }}</span
                  >
                </div>
              </div>

              <div class="mb-3">
                <div class="grey--text text-overline">
                  Dirección
                </div>
                <div class="text-body-1 font-weight-medium">
                  {{ selectedItem.workAddress }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-end pt-0 pb-3">
        <v-btn
          color="light-blue darken-2"
          class="white--text"
          depressed
          @click="modalState = false"
        >
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DetailedFamilyInformationModal",
  props: {
    selectedItem: {
      type: Object,
    },
  },
  data() {
    return {
      modalState: false,
    };
  },
  methods: {
    toggleModal() {
      this.modalState = !this.modalState;
    },
  },
};
</script>
