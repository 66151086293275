<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    transition="dialog-bottom-transition"
    persistent
    scrollable
  >
    <!-- Floating input form - Colegios o escuelas anteriores -->
    <v-card elevation="1">
      <!-- form header name - Colegios o escuelas anteriores -->
      <v-card-title class="text-h5 light-blue darken-4 white--text">
        Agregando colegios o escuelas anteriores
      </v-card-title>

      <v-card-text class="my-0 py-0 mt-5">
        <v-form ref="createForm_section3">
          <v-container>
            <v-row>
              <!-- Departamento - Colegios o escuelas anteriores -->
              <v-col cols="12" sm="6" class="pa-0 pa-sm-2 col-md-6">
                <v-select
                  :items="section3.schoolDetails.departments"
                  item-text="name"
                  item-value="id"
                  v-model="tempUserData.departmentId"
                  label="Departamento"
                  color="light-blue darken-4"
                  :rules="rules.required"
                  required
                  outlined
                  @change="sendData(tempUserData.departmentId)"
                ></v-select>
              </v-col>
              <!-- Municipio - Colegios o escuelas anteriores -->
              <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                <v-select
                  :items="section3.schoolDetails.municipalities"
                  item-text="name"
                  item-value="id"
                  :disabled="!tempUserData.departmentId"
                  v-model="tempUserData.municipalityId"
                  label="Municipio"
                  color="light-blue darken-4"
                  :rules="rules.required"
                  required
                  outlined
                  @change="sendMunicipalityId(tempUserData.municipalityId)"
                ></v-select>
              </v-col>
              <!-- Nombre de centro educativo - Colegios o escuelas anteriores -->
              <v-col cols="12" class="pa-0 pa-sm-2">
                <v-select
                  :items="section3.schoolDetails.schools"
                  item-text="name"
                  item-value="id"
                  v-model="tempUserData.schoolId"
                  label="Nombre de centro educativo"
                  color="light-blue darken-4"
                  :rules="rules.required"
                  required
                  outlined
                ></v-select>
              </v-col>

              <!-- De (grado) - Colegios o escuelas anteriores -->
              <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                <v-select
                  :items="datosPrueba.grade"
                  item-text="name"
                  item-value="id"
                  v-model="tempUserData.fromGrade"
                  label="De (grado)"
                  color="light-blue darken-4"
                  :rules="rules.required"
                  required
                  outlined
                ></v-select>
              </v-col>
              <!-- A (grado) - Colegios o escuelas anteriores -->
              <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                <v-select
                  :items="datosPrueba.grade"
                  item-text="name"
                  item-value="id"
                  v-model="tempUserData.toGrade"
                  label="A (grado)"
                  color="light-blue darken-4"
                  :rules="rules.required"
                  required
                  outlined
                ></v-select>
              </v-col>
              <!-- Motivo de cambio - Colegios o escuelas anteriores -->
              <v-col cols="12" class="pa-0 pa-sm-2">
                <v-text-field
                  autocomplete="null"
                  v-model="tempUserData.changeMotive"
                  label="Motivo de cambio"
                  color="light-blue darken-4"
                  :rules="rules.required"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-end pt-0 pb-3">
        <v-spacer></v-spacer>
        <!-- cancel btn - Colegios o escuelas anteriores -->
        <v-btn
          color="light-blue darken-4"
          outlined
          text
          @click="showMdlConfirm()"
        >
          Cancelar
        </v-btn>
        <!-- save data btn - Colegios o escuelas anteriores -->

        <v-btn
          color="light-blue darken-4"
          class="white--text"
          depressed
          @click="validate()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmationModal
      ref="confirmationModal"
      v-on:confirmData="toggleModal"
      v-on:resetForm="resetForm"
    ></ConfirmationModal>
  </v-dialog>
</template>
<script>
import ConfirmationModal from "@/view/components/modals/ConfirmationModal";
export default {
  name: "CreatePreviousSchoolModal",
  components: {
    ConfirmationModal,
  },
  props: {
    rules: {
      type: Object,
    },
    section3: {
      type: Object,
    },
    userPast: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      tempUserData: {},
    };
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    sendData(id) {
      this.$emit("selectedDepartment", id);
    },
    sendMunicipalityId(id) {
      this.$emit("selectSchool", id);
    },
    validate() {
      this.saveData();
    },
    saveData() {
      const data = {
        schoolId: this.tempUserData.schoolId,
        gradeFrom: this.tempUserData.fromGrade,
        gradeTo: this.tempUserData.toGrade,
        reasonChange: this.tempUserData.changeMotive,
      };

      this.userPast.justAddedSchool = data;
      this.$emit("create");
      this.dialog = false;
      this.$refs.createForm_section3.reset();
    },
    showMdlConfirm() {
      this.$refs.confirmationModal.toggleModal();
    },
    resetForm() {
      this.$refs.createForm_section3.reset();
    },
  },
};
</script>
