import Axios from "axios";
import { StudentApiService } from "@/core/services/studentApi.service.js";

export const registerStudentEconomicManager = (payload) => {
  return StudentApiService.post("economic-managers", payload);
};

export const updateStudentEconomicManger = (payload) => {
  return StudentApiService.post(
    "economic-managers/update/economic-manager",
    payload
  );
};

export const registeredStudentEconomicManager = (studentUUID) => {
  return StudentApiService.get("economic-managers", studentUUID);
};

export default {
  registerStudentEconomicManager,
  registeredStudentEconomicManager,
  updateStudentEconomicManger,
};
