<template>
  <!-- begin::Six section - Información familiar -->
  <v-sheet elevation="0" class="rounded-lg mt-4 pa-3">
    <!-- begin::card header -->
    <v-row
      class="d-flex flex-column-reverse flex-sm-row blue accent-5 mb-8 rounded white--text"
    >
      <!-- begin::section title - Dirección -->
      <v-col
        cols="12"
        class="d-flex align-center justify-center justify-md-start flex-column flex-md-row"
      >
        <div class="d-flex flex-row justify-start align-items-center">
          <p class="text-h5 mb-0">Paso</p>
          <v-icon color="white" class="mx-2"
            >mdi-numeric-{{ step }}-circle-outline</v-icon
          >
        </div>
        <p class="ma-0 text-h6 text-center text-md-left mt-2 mt-sm-0">
          <span class="font-weight-medium text-h5 mr-1 d-none d-md-inline"
            >-</span
          >
          Información de situación familiar
        </p>
      </v-col>
      <!-- end::step - Dirección -->
    </v-row>
    <!-- begin::card body -->
    <v-form ref="formSection6" class="my-5">
      <v-row>
        <!-- Miembros en el hogar - Información familiar -->
        <v-col cols="12" sm="6" md="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="N° de miembros"
            :validator="$v.familyInformation.numberMembers"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              label="N° de miembros en el hogar"
              color="light-blue darken-4"
              v-model="familyInformation.numberMembers"
              type="number"
              min="0"
              required
              outlined
            ></v-text-field>
          </form-group>
        </v-col>

        <!-- Relación de padres biológicos - Información familiar -->
        <v-col cols="12" sm="6" md="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="relación de padres biológicos"
            :validator="$v.familyInformation.parentingSituationId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section6.familyDetails.parentingSituations"
              item-text="name"
              item-value="id"
              label="Relación de padres biológicos"
              color="light-blue darken-4"
              v-model="familyInformation.parentingSituationId"
              required
              outlined
            ></v-select>
          </form-group>
        </v-col>

        <!-- Con quien vive el estudiante - Información familiar -->
        <v-col cols="12" sm="6" md="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="estudiante vive con"
            :validator="$v.familyInformation.coexistenceId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section6.familyDetails.coexistences"
              item-text="name"
              item-value="id"
              label="El estudiante vive con:"
              color="light-blue darken-4"
              v-model="familyInformation.coexistenceId"
              required
              outlined
            ></v-select>
          </form-group>
        </v-col>
        <!-- Epecificar coexistencia - Información familiar -->
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          class="pa-0 pa-sm-2"
          v-if="familyInformation.coexistenceId == 8"
        >
          <form-group
            name="integrante"
            :validator="$v.familyInformation.otherCoexistence"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              v-model="familyInformation.otherCoexistence"
              label="Especifique con que otra persona vive el estudiante"
              required
              outlined
            >
            </v-text-field>
          </form-group>
        </v-col>

        <!-- Responsabilidad económica ante la institución - Información familiar -->
        <v-col cols="12" sm="6" md="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="sostenedor económico"
            :validator="$v.familyInformation.economicManagerId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section6.familyDetails.economicManager"
              item-text="name"
              item-value="id"
              label="Sostenedor económico"
              color="light-blue darken-4"
              v-model="familyInformation.economicManagerId"
              required
              outlined
            ></v-select>
          </form-group>
        </v-col>
        <!-- Otro sostenedor económico - Información familiar -->
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          class="pa-0 pa-sm-2"
          v-if="familyInformation.economicManagerId == 8"
        >
          <form-group
            name="responsable"
            :validator="$v.familyInformation.otherEconomicManager"
          >
            <v-text-field
              slot-scope="{ attrs }"
              v-bind="attrs"
              v-model="familyInformation.otherEconomicManager"
              label="Especifique otro sostenedor económico del estudiante"
              required
              outlined
            >
            </v-text-field>
          </form-group>
        </v-col>

        <!-- rango salarial de pariente - Información familiar -->
        <v-col cols="12" sm="6" md="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="rango salarial de los familiares"
            :validator="$v.familyInformation.salaryRangeId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section6.familyDetails.salaryRange"
              item-text="name"
              item-value="id"
              label="Rango salarial de los familiares"
              color="light-blue darken-4"
              v-model="familyInformation.salaryRangeId"
              required
              outlined
              hint="Especificar según los familiares que integren el nucleo familiar"
              persistent-hint
            ></v-select>
          </form-group>
        </v-col>

        <!-- tipos de vivienda - Información familiar -->
        <v-col cols="12" sm="6" md="4" xl="3" class="pa-0 pa-sm-2">
          <form-group
            name="tipo de vivienda"
            :validator="$v.familyInformation.housingTypeId"
          >
            <v-select
              slot-scope="{ attrs }"
              v-bind="attrs"
              :items="section6.familyDetails.housingType"
              item-text="name"
              item-value="id"
              label="Tipo de vivienda"
              color="light-blue darken-4"
              v-model="familyInformation.housingTypeId"
              required
              outlined
            ></v-select>
          </form-group>
        </v-col>
      </v-row>
    </v-form>
  </v-sheet>
  <!-- end::Six section - Información familiar -->
</template>
<script>
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";
import sixthStepRepository from "@/repositories/steps/sixthStepRepository";
export default {
  name: "FamilyInformation",
  props: {
    section6: {
      type: Object,
    },
    step: {
      type: Number,
    },
  },

  data() {
    return {
      familyInformation: {
        numberMembers: null,
        parentingSituationId: null,
        coexistenceId: null,
        otherCoexistence: null,
        economicManagerId: null,
        otherEconomicManager: null,
        salaryRangeId: null,
        housingTypeId: null,
      },
      rawData: {},
      // progress: 1.5,
    };
  },
  methods: {
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar los campos requeridos",
        });
        return;
      }
      this.updateStudentFamiliarInformation();
    },

    getGeneralFamilyInformation() {
      sixthStepRepository
        .getAllStudentFamiliarInformation()
        .then(({ data }) => {
          this.rawData = data;
          this.formatData();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },
    //TODO: add the family information by vuex to refactor
    formatData(data) {
      if (this.rawData === null) {
        this.familyInformation = {
          numberMembers: undefined,
          parentingSituationId: undefined,
          coexistenceId: undefined,
          otherCoexistence: undefined,
          economicManagerId: undefined,
          otherEconomicManager: undefined,
          salaryRangeId: undefined,
          housingTypeId: undefined,
        };
      } else {
        this.familyInformation = {
          numberMembers: this.rawData.numberMembers,
          parentingSituationId: this.rawData.parentingSituationId,
          coexistenceId: this.rawData.coexistenceId,
          otherCoexistence: this.rawData.otherCoexistence,
          economicManagerId: this.rawData.economicManagerId,
          otherEconomicManager: this.rawData.otherEconomicManager,
          salaryRangeId: this.rawData.salaryRangeId,
          housingTypeId: this.rawData.housingTypeId,
        };
      }
    },

    updateStudentFamiliarInformation() {
      this.$emit("loading");
      sixthStepRepository
        .updateStudentFamiliarInformation(this.familyInformation)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.$emit("next");
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar actualizar los datos",
          });
        })
        .finally(() => {
          this.$emit("stopLoading");
        });
    },
  },
  validations: {
    familyInformation: {
      numberMembers: { required },
      parentingSituationId: { required },
      coexistenceId: { required },
      otherCoexistence: {
        required: requiredIf(function() {
          return this.familyInformation.coexistenceId == 8;
        }),
      },
      economicManagerId: { required },
      otherEconomicManager: {
        required: requiredIf(function() {
          return this.familyInformation.economicManagerId == 8;
        }),
      },
      salaryRangeId: { required },
      housingTypeId: { required },
    },
  },
};
</script>
