import ApiService from "@/core/services/api.service";


/**
 * GET request to fetch all data
 * @param id
 * @returns {*}
 */
 export const getSacramentsByStudent = () => {
  return ApiService.get("student-sacraments");
};
/**
 * GET request to fetch all data
 * @params payload
 * @returns {*}
 */
export const UpdateStepOne = (payload) => {
  return ApiService.post("students/step-one", payload);
};
/**
 * GET request to fetch all data
 * @params payload
 * @returns {*}
 */
export const updateSacramentsAndReligions = (payload) => {
  return ApiService.post("student-sacraments", payload);
};


export default {
  UpdateStepOne,
  getSacramentsByStudent,
  updateSacramentsAndReligions,
};
