<template>
  <!-- begin::Fourth section - Historial Familiar ITR -->
  <v-sheet elevation="0" class="rounded-lg mt-4 pa-3">
    <!-- begin::card header - Historial Familiar ITR -->
    <v-row
      class="d-flex flex-column-reverse flex-sm-row blue accent-5 mb-8 rounded white--text"
    >
      <!-- begin::section title - Dirección -->
      <v-col
        cols="12"
        class="d-flex align-center justify-center justify-md-start flex-column flex-md-row"
      >
        <div class="d-flex flex-row justify-start align-items-center">
          <p class="text-h5 mb-0">Paso</p>
          <v-icon color="white" class="mx-2"
            >mdi-numeric-{{ step }}-circle-outline</v-icon
          >
        </div>
        <p class="ma-0 text-h6 text-center text-md-left mt-2 mt-sm-0">
          <span class="font-weight-medium text-h5 mr-1 d-none d-md-inline"
            >-</span
          >
          Historial de familiares ITR
        </p>
      </v-col>
      <!-- end::step - Dirección -->
    </v-row>
    <!-- begin::card body -->
    <!-- begin::Actually studying -->
    <v-row class="d-flex justify-center pa-0">
      <v-card elevation="0" class="my-1" width="100%">
        <v-col cols="12" class="pa-0">
          <v-row>
            <v-col class="d-flex flex-column mx-4">
              <div class="d-flex flex-column flex-sm-row justify-start align-center mb-4">
                <h5 class="text-h5 text-center mb-4 mb-sm-0 font-weight-bold">
                  Listado de familiares estudiando actualmente en el ITR
                </h5>
                <v-btn
                  color="light-blue darken-4"
                  dark
                  @click="showMdlCreateCurrentlyModal()"
                  class="ml-4"
                >
                  <v-icon left>mdi-plus-circle-outline</v-icon>
                  Agregar
                </v-btn>
              </div>
              <p class="text-body-1 text-justify">
                En esta sección puedes agregar los familiares que estan
                estudiando actualmente en la institución
                <span class="font-weight-bold"
                  >(los familiares que egresaron el presente año, favor
                  incluirlos en el siguiente listado)</span
                >.
              </p>
            </v-col>
          </v-row>
          <div
            class="my-4 my-sm-2 my-md-1 d-flex flex-wrap justify-sm-center justify-md-start"
          >
            <v-col cols="12" class="pb-0">
              <v-alert
                color="blue"
                class="mb-0"
                dense
                outlined
                prominent
                text
                type="info"
                v-if="familyMembers.currentlyStudying.length == 0"
                >No se ha registrado ningún familiar.</v-alert
              >
            </v-col>
            <!-- begin::large Card to show informaion -->
            <v-col
              cols="12"
              md="6"
              lg="4"
              xl="3"
              v-for="(familyMember, index) in familyMembers.currentlyStudying"
              :key="index"
            >
              <!-- begin::large card -->
              <v-card
                class="mx-auto score-sheet-card-large"
                max-width="370"
                outlined
              >
                <v-list-item three-line>
                  <!-- begin::large card title -->
                  <v-list-item-content>
                    <div>
                      <p class="text-overline ma-0 mb-n2">Familiar</p>
                    </div>
                    <!-- end::large card title -->
                    <!-- begin::large card content -->
                    <p class="text-h5 mb-0">
                      {{ familyMember.name }}
                    </p>
                    <p
                      class="ma-0 text-body-1 font-weight-bold"
                      style="color: black !importante"
                    >
                      {{ familyMember.code }}
                    </p>
                    <!-- end::large card content -->
                  </v-list-item-content>

                  <!-- begin::large card icon -->
                  <v-list-item-avatar
                    tile
                    size="80"
                    color="blue-grey lighten-5"
                    class="rounded-lg"
                  >
                    <v-icon
                      x-large
                      :color="`${colors[familyMember.id % 12]} lighten-3`"
                      class="ml-n1"
                      >mdi-account-circle</v-icon
                    >
                  </v-list-item-avatar>
                  <!-- end::large card icon -->
                </v-list-item>
                <!-- begin::large card body -->
                <v-card-actions
                  class="d-flex justify-center align-self-end blue-grey lighten-5"
                >
                  <v-btn
                    class="my-1"
                    small
                    depressed
                    color="light-blue lighten-4"
                    @click="
                      getDetailsFromSelectedFamilyMember(familyMember);
                      showMdlDetailsCurrentlyModal();
                    "
                  >
                    <v-icon color="light-blue" small class="mr-1"
                      >mdi-information-outline
                    </v-icon>
                    Detalle
                  </v-btn>

                  <v-btn
                    class="my-1"
                    small
                    depressed
                    color="blue-grey lighten-4"
                    @click="
                      getDetailsFromSelectedFamilyMember(familyMember),
                        showMdlUpdateCurrentlyModal()
                    "
                  >
                    <v-icon color="blue-grey" small class="mr-1"
                      >mdi-pencil-circle-outline
                    </v-icon>
                    Editar
                  </v-btn>

                  <v-btn
                    class="my-1"
                    small
                    depressed
                    color="red lighten-4"
                    @click="
                      showMdlDeleteModal(),
                        getDetailsFromSelectedFamilyMember(familyMember)
                    "
                  >
                    <v-icon color="red" small class="mr-1"
                      >mdi-delete-circle-outline
                    </v-icon>
                    Eliminar
                  </v-btn>
                </v-card-actions>
                <!-- end::large card body -->
              </v-card>
              <!-- begin::small card -->
              <v-card class="mx-auto rounded score-sheet-card-small" outlined>
                <!-- begin::small card title -->
                <div class="mt-4">
                  <p class="text-overline text-center ma-0 mb-n2">
                    Familiar
                  </p>
                </div>
                <!-- end::small card title -->
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <!-- begin::small card icon -->
                    <v-list-item-avatar
                      tile
                      size="80"
                      color="blue-grey lighten-5"
                      class="rounded-lg mx-auto"
                    >
                      <v-icon
                        x-large
                        :color="`${colors[familyMember.id % 12]} lighten-2`"
                        >mdi-account-circle</v-icon
                      >
                    </v-list-item-avatar>
                    <!-- end::small card icon -->
                  </v-col>
                </v-row>
                <!-- begin::small card content -->
                <p class="text-h5 mb-0 text-center px-1">
                  {{ familyMember.name }}
                </p>
                <p
                  class="ma-0 mb-2 text-body-1 text-center"
                  style="color: black !importante"
                >
                  {{ familyMember.code }}
                </p>
                <!-- end::small card content -->
                <!-- begin::small card body -->
                <v-card-actions
                  class="d-flex justify-center flex-row blue-grey lighten-5"
                >
                  <v-btn
                    class="mx-auto my-1"
                    color="light-blue lighten-4"
                    depressed
                    @click="
                      getDetailsFromSelectedFamilyMember(familyMember);
                      showMdlDetailsCurrentlyModal();
                    "
                  >
                    <v-icon color="light-blue">mdi-open-in-new </v-icon>
                  </v-btn>

                  <v-btn
                    class="mx-auto my-1"
                    color="blue-grey lighten-4"
                    depressed
                    @click="
                      getDetailsFromSelectedFamilyMember(familyMember),
                        showMdlUpdateCurrentlyModal()
                    "
                  >
                    <v-icon color="blue-grey">mdi-pencil-outline</v-icon>
                  </v-btn>

                  <v-btn
                    class="mx-auto my-1"
                    color="red lighten-4"
                    depressed
                    @click="
                      showMdlDeleteModal(),
                        getDetailsFromSelectedFamilyMember(familyMember)
                    "
                  >
                    <v-icon color="red">mdi-delete-outline</v-icon>
                  </v-btn>
                </v-card-actions>
                <!-- end::small card body -->
              </v-card>
              <!-- end::small card -->
            </v-col>
            <!-- end::Card to show information -->
          </div>
        </v-col>
      </v-card>
    </v-row>
    <!-- end::estudiando actualmente -->
    <v-divider class="my-8"></v-divider>
    <!-- begin::graduated Family -->
    <v-row class="d-flex justify-center pa-0">
      <v-card elevation="0" class="my-1" width="100%">
        <v-col cols="12" class="pa-0">
          <v-row>
            <v-col class="d-flex flex-column mx-4">
              <div class="d-flex flex-column flex-sm-row justify-start align-center mb-4">
                <h5 class="text-h5 text-center mb-4 mb-sm-0 font-weight-bold">
                  Listado de familiares egresados del ITR
                </h5>
                <v-btn
                  color="light-blue darken-4"
                  dark
                  class="ml-4"
                  @click="showMdlCreateGraduatedModal()"
                >
                  <v-icon left>mdi-plus-circle-outline</v-icon>
                  Agregar
                </v-btn>
              </div>
              <p class="text-body-1 text-justify">
                En esta sección puedes agregar los familiares que han estudiado
                anteriormente en la institución
                <span class="font-weight-bold"
                  >(incluir los familiares que egresen en el presente año)</span
                >.
              </p>
            </v-col>
          </v-row>
          <div class="d-flex flex-wrap justify-sm-center justify-md-start">
            <!-- No entries fallback text - Egresados - Historial Familiar ITR -->
            <v-col cols="12" class="pb-0">
              <v-alert
                color="blue"
                class="mb-0"
                dense
                outlined
                prominent
                text
                type="info"
                v-if="familyMembers.graduated.length == 0"
                >No se ha registrado ningún familiar.</v-alert
              >
            </v-col>
            <!-- begin::large Card to show informaion -->
            <v-col
              cols="12"
              md="6"
              lg="4"
              xl="3"
              v-for="(familyMember, index) in familyMembers.graduated"
              :key="index"
            >
              <!-- begin::large card - Egresados - Historial Familiar ITR -->
              <v-card
                class="mx-auto score-sheet-card-large"
                max-width="370"
                outlined
              >
                <v-list-item three-line>
                  <!-- begin::large card title - Egresados - Historial Familiar ITR -->
                  <v-list-item-content>
                    <div>
                      <p class="text-overline ma-0 mb-n2">
                        Familiar egresado en {{ familyMember.graduationYear }}
                      </p>
                    </div>
                    <!-- end::large card title - Egresados - Historial Familiar ITR -->
                    <!-- begin::large card content -->
                    <p class="text-h5 mb-0">
                      {{ familyMember.name }}
                    </p>
                    <p
                      class="ma-0 text-body-1 font-weight-bold"
                      style="color: black !importante"
                    >
                      {{ familyMember.Kinship.name }}
                    </p>
                    <!-- end::large card content -->
                  </v-list-item-content>

                  <!-- begin::large card icon -->
                  <v-list-item-avatar
                    tile
                    size="80"
                    color="blue-grey lighten-5"
                    class="rounded-lg"
                  >
                    <v-icon
                      x-large
                      :color="`${colors[familyMember.id % 12]} lighten-3`"
                      class="ml-n1"
                      >mdi-account-circle</v-icon
                    >
                  </v-list-item-avatar>
                  <!-- end::large card icon -->
                </v-list-item>
                <!-- begin::card body -->
                <v-card-actions
                  class="d-flex justify-center align-self-end blue-grey lighten-5"
                >
                  <v-btn
                    class="my-1"
                    small
                    depressed
                    color="light-blue lighten-4"
                    @click="
                      getDetailsFromSelectedFamilyMember(familyMember);
                      showMdlDetailsGraduatedModal();
                    "
                  >
                    <v-icon color="light-blue" small class="mr-1"
                      >mdi-information-outline
                    </v-icon>
                    Detalle
                  </v-btn>

                  <v-btn
                    class="my-1"
                    small
                    depressed
                    color="blue-grey lighten-4"
                    @click="
                      getDetailsFromSelectedFamilyMember(familyMember),
                        showMdlUpdateGraduatedModal()
                    "
                  >
                    <v-icon color="blue-grey" small class="mr-1"
                      >mdi-pencil-circle-outline
                    </v-icon>
                    Editar
                  </v-btn>

                  <v-btn
                    class="my-1"
                    small
                    depressed
                    color="red lighten-4"
                    @click="
                      showMdlDeleteModal(),
                        getDetailsFromSelectedFamilyMember(familyMember)
                    "
                  >
                    <v-icon color="red" small class="mr-1"
                      >mdi-delete-circle-outline
                    </v-icon>
                    Eliminar
                  </v-btn>
                </v-card-actions>
                <!-- end::large card body -->
              </v-card>
              <!-- begin::small card -->
              <v-card class="mx-auto rounded score-sheet-card-small" outlined>
                <!-- begin::small card title -->
                <div class="mt-4">
                  <p class="text-overline text-center ma-0 mb-n2">
                    Familiar egresado en {{ familyMember.graduationYear }}
                  </p>
                </div>
                <!-- end::small card title -->
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <!-- begin::small card icon -->
                    <v-list-item-avatar
                      tile
                      size="80"
                      color="blue-grey lighten-5"
                      class="rounded-lg mx-auto"
                    >
                      <v-icon
                        x-large
                        :color="`${colors[familyMember.id % 12]} lighten-2`"
                        >mdi-account-circle</v-icon
                      >
                    </v-list-item-avatar>
                    <!-- end::small card icon -->
                  </v-col>
                </v-row>
                <!-- begin::small card content -->
                <p class="text-h5 mb-0 text-center px-1">
                  {{ familyMember.name }}
                </p>
                <p
                  class="ma-0 mb-2 text-body-1 text-center"
                  style="color: black !importante"
                >
                  {{ familyMember.Kinship.name }}
                </p>
                <!-- end::small card content -->
                <!-- begin::small card body -->
                <v-card-actions
                  class="d-flex justify-center flex-row blue-grey lighten-5"
                >
                  <v-btn
                    class="mx-auto my-1"
                    color="light-blue lighten-4"
                    depressed
                    @click="
                      getDetailsFromSelectedFamilyMember(familyMember);
                      showMdlDetailsGraduatedModal();
                    "
                  >
                    <v-icon color="light-blue">mdi-open-in-new </v-icon>
                  </v-btn>

                  <v-btn
                    class="mx-auto my-1"
                    color="blue-grey lighten-4"
                    depressed
                    @click="
                      getDetailsFromSelectedFamilyMember(familyMember),
                        showMdlUpdateGraduatedModal()
                    "
                  >
                    <v-icon color="blue-grey">mdi-pencil-outline</v-icon>
                  </v-btn>

                  <v-btn
                    class="mx-auto my-1"
                    color="red lighten-4"
                    depressed
                    @click="
                      showMdlDeleteModal(),
                        getDetailsFromSelectedFamilyMember(familyMember)
                    "
                  >
                    <v-icon color="red">mdi-delete-outline</v-icon>
                  </v-btn>
                </v-card-actions>
                <!-- end::small card body -->
              </v-card>
              <!-- end::small card -->
            </v-col>
            <!-- end::small Card to show information -->
          </div>
        </v-col>
      </v-card>
    </v-row>
    <!-- begin::dialog Create Currently Family History Modal -->
    <CreateCurrentlyModal
      ref="mdlCreateCurrent"
      :rules="rules"
      :section4="section4"
      :familyMembers="familyMembers"
      @createActive="createActiveRelative"
    ></CreateCurrentlyModal>
    <!-- end::dialog Create graduated Family History Modal -->
    <!-- begin::dialog Create graduated Family History Modal -->
    <CreateGraduatedModal
      ref="mdlCreateGraduated"
      :rules="rules"
      :section4="section4"
      :familyMembers="familyMembers"
      @createGraduated="createGraduatedRelative"
    ></CreateGraduatedModal>
    <!-- end::dialog Create graduated Family History Modal -->
    <!-- begin::dialog Currently Family History Information Modal -->
    <DetailsCurrentlyModal
      ref="mdlShowCurrentModal"
      :selectedFamilyMemberDetails="selectedFamilyMemberDetails"
    ></DetailsCurrentlyModal>
    <!-- end::dialog Currently Family History Information Modal -->
    <!-- begin::dialog Graduated Family History Information Modal -->
    <DetailsGraduatedModal
      ref="mdlShowGraduatedModal"
      :selectedFamilyMemberDetails="selectedFamilyMemberDetails"
    ></DetailsGraduatedModal>
    <!-- end::dialog graduated Family History Information Modal -->
    <!-- begin::dialog Update Currently Family History Modal -->
    <UpdateCurrentlyModal
      ref="mdlUpdateCurrentlyModal"
      :rules="rules"
      :section4="section4"
      :formattedValues="formattedValues"
      @update="updateActiveRelative"
    >
    </UpdateCurrentlyModal>
    <!-- end::dialog Update Currently Family History Modal -->
    <!-- begin::dialog Update Graduated Family History Modal -->
    <UpdateGraduatedModal
      ref="mdlUpdateGraduatedModal"
      :rules="rules"
      :section4="section4"
      :formattedValues="formattedValues"
      @update="updateGraduatedRelative"
    >
    </UpdateGraduatedModal>
    <!-- end::dialog Update Graduated Family History Modal -->
    <!--begin::delete entry dialog - Historial Familiar ITR -->
    <DeleteModal
      ref="mdlDeleteModal"
      dialogText="¿Está seguro que desea eliminar el registro de este familiar?"
      :selectedItemInformationSummary="selectedFamilyMemberDetailsSummary"
      @delete="deleteRelative"
    ></DeleteModal>
    <!-- end::delete entry dialog - Historial Familiar ITR -->
  </v-sheet>
  <!-- end::Fourth section - Historial Familiar ITR -->
</template>

<script>
import CreateCurrentlyModal from "@/view/components/modals/section-4/actualmente/CreateCurrentlyModal";
import UpdateCurrentlyModal from "../../components/modals/section-4/actualmente/UpdateCurrentlyModal";
import DetailsCurrentlyModal from "@/view/components/modals/section-4/actualmente/DetailsCurrentlyModal";
import CreateGraduatedModal from "@/view/components/modals/section-4/egresados/CreateGraduatedModal";
import UpdateGraduatedModal from "../../components/modals/section-4/egresados/UpdateGraduatedModal";
import DetailsGraduatedModal from "@/view/components/modals/section-4/egresados/DetailsGraduatedModal";
import DeleteModal from "@/view/components/modals/DeleteModal";
import fourthStepRepository from "@/repositories/steps/fourthStepRepository";
export default {
  name: "FamilyHistory",
  components: {
    CreateCurrentlyModal,
    UpdateCurrentlyModal,
    DetailsCurrentlyModal,
    CreateGraduatedModal,
    UpdateGraduatedModal,
    DetailsGraduatedModal,
    DeleteModal,
  },
  props: {
    section4: {
      type: Object,
    },
    rules: {
      type: Object,
    },
    step: {
      type: Number,
    },
  },
  data() {
    return {
      selectedFamilyMemberDetails: {},
      selectedFamilyMemberDetailsSummary: {},
      // progress: 1.5,
      formattedValues: {},
      familyMembers: {
        currentlyStudyingJustAdded: [],
        currentlyStudying: [],
        graduatedJustAdded: [],
        graduated: [],
      },
    };
  },
  mounted() {
    this.getAllActiveRelatives();
    this.getAllGraduatedRelatives();
  },
  methods: {
    getDetailsFromSelectedFamilyMember(obj) {
      if (!!obj.graduationYear) {
        this.formattedValues = {
          id: obj.id,
          name: obj.name,
          graduationYear: obj.graduationYear,
          kinshipId: obj.kinshipId,
          specialityId: obj.specialityId,
          gradeId: obj.gradeId,
        };
      } else {
        this.formattedValues = {
          id: obj.id,
          code: obj.code,
          name: obj.name,
          gradeId: obj.gradeId,
          specialityId: obj.specialityId,
        };
      }
      this.selectedFamilyMemberDetails = { ...obj };
      this.selectedFamilyMemberDetailsSummary = {
        id: obj.id,
        upperTitle: "Familiar",
        mainTitle: obj.name,
        subTitle: obj.code ? obj.code : obj.KinshipId,
        icon: "mdi-account-off",
        iconColor: "red darken-1",
      };
    },
    showMdlCreateCurrentlyModal() {
      this.$refs.mdlCreateCurrent.toggleModal();
    },
    showMdlCreateGraduatedModal() {
      this.$refs.mdlCreateGraduated.toggleModal();
    },
    showMdlUpdateCurrentlyModal() {
      this.$refs.mdlUpdateCurrentlyModal.toggleModal();
    },
    showMdlDetailsCurrentlyModal() {
      this.$refs.mdlShowCurrentModal.toggleModal();
    },
    showMdlUpdateGraduatedModal() {
      this.$refs.mdlUpdateGraduatedModal.toggleModal();
    },
    showMdlDetailsGraduatedModal() {
      this.$refs.mdlShowGraduatedModal.toggleModal();
    },
    showMdlDeleteModal() {
      this.$refs.mdlDeleteModal.toggleModal();
    },
    //CRUD - Active Relatives
    getAllActiveRelatives() {
      fourthStepRepository
        .getAllActiveRelatives()
        .then(({ data }) => {
          this.familyMembers.currentlyStudying = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },
    createActiveRelative() {
      fourthStepRepository
        .createActive(this.familyMembers.currentlyStudyingJustAdded)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllActiveRelatives();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar ingresar los datos",
          });
        });
    },
    updateActiveRelative() {
      fourthStepRepository
        .updateActive(this.formattedValues.id, this.formattedValues)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllActiveRelatives();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar modificar los datos",
          });
        });
    },
    deleteRelative() {
      fourthStepRepository
        .deleteRelative(this.selectedFamilyMemberDetailsSummary.id)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllActiveRelatives();
          this.getAllGraduatedRelatives();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar eliminar el registro",
          });
        });
    },
    //CRUD - Graduated Relatives
    getAllGraduatedRelatives() {
      fourthStepRepository
        .getAllGraduatedRelatives()
        .then(({ data }) => {
          this.familyMembers.graduated = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },
    createGraduatedRelative() {
      fourthStepRepository
        .createGraduated(this.familyMembers.graduatedJustAdded)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllGraduatedRelatives();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar ingresar los datos",
          });
        });
    },
    updateGraduatedRelative() {
      fourthStepRepository
        .updateGraduated(this.formattedValues.id, this.formattedValues)
        .then(({ data }) => {
          this.fireToast({
            icon: "success",
            title: data.message,
          });
          this.getAllGraduatedRelatives();
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar modificar los datos",
          });
        });
    },
  },
};
</script>
<style>
@media only screen and (max-width: 600px) {
  .v-toolbar {
    height: 64px !important;
  }
  .v-toolbar__content {
    flex-direction: column !important;
    text-align: center !important;
  }
  .v-card__title {
    word-break: normal;
    text-align: center;
  }
}
</style>
