<template>
  <v-dialog
    v-model="modalState"
    width="1100px"
    persistent
    transition="dialog-bottom-transition"
  >
    <!-- begin:: Stepper  -->
    <v-stepper v-model="e1" color="light-blue darken-4">
      <!-- begin:: Stepper header -->
      <v-stepper-header id="content">
        <template v-for="n in steps">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="e1 > n"
            :step="n"
            class="stepper-step"
            color="light-blue darken-4"
          >
            Paso {{ n }}
          </v-stepper-step>
          <v-divider v-if="n !== steps" :key="n"></v-divider>
        </template>
      </v-stepper-header>
      <!-- end:: Stepper header -->
      <!-- begin:: Stepper items -->
      <v-stepper-items>
        <!-- First Step -->
        <v-stepper-content step="1" class="pa-0">
          <v-form ref="createForm_section7_step1">
            <v-card elevation="0">
              <v-card-title class="text-h5 light-blue darken-4 white--text">
                Información personal del familiar
              </v-card-title>
              <v-card-text class="mt-5 px-4 py-3">
                <v-container>
                  <v-row>
                    <!-- Primeros nombres -Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="nombres"
                        :validator="$v.tempUserData.firstName"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Nombres"
                          color="light-blue darken-4"
                          v-model="tempUserData.firstName"
                          outlined
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Apellidos - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="apellidos"
                        :validator="$v.tempUserData.lastName"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Apellidos"
                          color="light-blue darken-4"
                          v-model="tempUserData.lastName"
                          outlined
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Correo electrónico - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="correo"
                        :validator="$v.tempUserData.email"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Correo electrónico"
                          color="light-blue darken-4"
                          v-model="tempUserData.email"
                          outlined
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Parentesco - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="parentesco"
                        :validator="$v.tempUserData.kinship"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          label="Parentesco"
                          color="light-blue darken-4"
                          :items="section7.personalDetails.kinships"
                          item-text="name"
                          item-value="id"
                          v-model="tempUserData.kinship"
                          outlined
                        ></v-select>
                      </form-group>
                    </v-col>
                    <!-- Teléfono de residencia - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        :messages="{
                          minLength:
                            'El campo número de teléfono debería contener al menos 8 números',
                        }"
                        name="número de teléfono"
                        :validator="$v.tempUserData.phone"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Número de teléfono"
                          color="light-blue darken-4"
                          v-model="tempUserData.phone"
                          outlined
                          v-mask="'########'"
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Teléfono celular - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        :messages="{
                          minLength:
                            'El campo teléfono celular debería contener al menos 8 números',
                        }"
                        name="Número de celular"
                        :validator="$v.tempUserData.cellPhone"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Número de celular"
                          color="light-blue darken-4"
                          v-model="tempUserData.cellPhone"
                          outlined
                          v-mask="'########'"
                        ></v-text-field>
                      </form-group>
                    </v-col>
                    <!-- DUI - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="dui"
                        :validator="$v.tempUserData.dui"
                        :messages="{
                          minLength:
                            'El campo dui debería contener al menos 9 números',
                        }"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="DUI"
                          color="light-blue darken-4"
                          v-model="tempUserData.dui"
                          outlined
                          v-mask="'########-#'"
                        ></v-text-field>
                      </form-group>
                    </v-col>
                    <!-- NIT - Información familiar detallada -->
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      lg="3"
                      class="pa-0 pa-sm-2"
                      v-if="!switch1"
                    >
                      <form-group
                        name="nit"
                        :validator="$v.tempUserData.nit"
                        :messages="{
                          minLength:
                            'El campo nit debería contener al menos 14 números',
                        }"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="NIT"
                          color="light-blue darken-4"
                          v-model="tempUserData.nit"
                          outlined
                          v-mask="'####-######-###-#'"
                        ></v-text-field>
                      </form-group>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="3" class="pa-0 pa-sm-2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-switch
                              v-model="switch1"
                              color="light-blue darken-4"
                              inset
                              :label="`DUI homologado`"
                              @change="changeSwitchNit()"
                            ></v-switch>
                          </div>
                        </template>
                        <div>Marque sí posee el DUI homologado</div>
                      </v-tooltip>
                    </v-col>

                    <!-- Ckeckbox - Es responsable del menor ante la institución - Información familiar detallada -->
                    <v-col class="pa-0" cols="12" md="12">
                      <v-container fluid>
                        <v-checkbox
                          class="mt-0"
                          color="light-blue darken-4"
                          v-model="tempUserData.isManager"
                          label="Marcar la casilla si es responsable del menor ante la institución."
                        >
                        </v-checkbox>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions
                class="d-flex flex-row justify-end align-center pt-0 pb-15 px-4"
              >
                <v-btn
                  color="light-blue darken-4"
                  text
                  large
                  @click="showMdlConfirm()"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="light-blue darken-4 white--text"
                  class="white--text"
                  depressed
                  large
                  @click="validate(1)"
                >
                  Siguiente
                  <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-stepper-content>
        <!-- Second Step -->
        <v-stepper-content step="2" class="pa-0">
          <v-form ref="createForm_section7_step2">
            <v-card elevation="0">
              <v-card-title class="text-h5 light-blue darken-4 white--text">
                Información de nacimiento del familiar
              </v-card-title>
              <v-card-text class="mt-5">
                <v-container>
                  <v-row>
                    <!-- Fecha de nacimiento - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="6" class="pa-0 pa-sm-2">
                      <v-menu
                        ref="dialog"
                        v-model="dateDialogState"
                        :close-on-content-click="false"
                        :return-value="tempUserData.birthday"
                        transition="scale-transition"
                        offset-y
                        min-width="250px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <form-group
                            name="fecha de nacimiento"
                            :validator="$v.tempUserData.birthday"
                            v-bind="attrs"
                          >
                            <v-text-field
                              v-model="tempUserData.birthday"
                              label="Fecha de nacimiento"
                              color="light-blue darken-4"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              outlined
                              clearable
                              slot-scope="{ attrs }"
                              v-bind="attrs"
                              v-on="on"
                              required
                            ></v-text-field>
                          </form-group>
                        </template>
                        <v-date-picker
                          v-model="tempUserData.birthday"
                          color="light-blue darken-4"
                          :active-picker.sync="activePicker"
                          :max="
                            new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substring(0, 10)
                          "
                          min="1922-01-01"
                          @change="save(tempUserData.birthday)"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <!-- Estado civil - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="6" class="pa-0 pa-sm-2">
                      <form-group
                        name="estado civil"
                        :validator="$v.tempUserData.maritalStatus"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          label="Estado civil"
                          color="light-blue darken-4"
                          :items="section7.personalDetails.maritalStatus"
                          item-text="name"
                          item-value="id"
                          v-model="tempUserData.maritalStatus"
                          outlined
                        ></v-select>
                      </form-group>
                    </v-col>
                    <!-- Nacionalidad - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="4" class="pa-0 pa-sm-2">
                      <form-group
                        name="nacionalidad"
                        :validator="$v.tempUserData.nationality"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          label="Nacionalidad"
                          color="light-blue darken-4"
                          :items="section7.personalDetails.nationality"
                          item-text="name"
                          item-value="id"
                          v-model="tempUserData.nationality"
                          outlined
                          @change="changeNationality()"
                        ></v-select>
                      </form-group>
                    </v-col>
                    <!-- Departamento de nacimiento - Información familiar detallada -->
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      class="pa-0 pa-sm-2"
                      v-if="tempUserData.nationality == 1"
                    >
                      <form-group
                        name="departamento"
                        :validator="$v.tempUserData.department"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          :items="section7.personalDetails.departments"
                          item-text="name"
                          item-value="id"
                          label="Departamento de nacimiento"
                          color="light-blue darken-4"
                          v-model="tempUserData.department"
                          outlined
                          @change="
                            sendData(tempUserData.department);
                            resetMunicipality();
                          "
                        ></v-select>
                      </form-group>
                    </v-col>

                    <!-- Municipio de nacimiento - Información familiar detallada -->
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      class="pa-0 pa-sm-2"
                      v-if="tempUserData.nationality == 1"
                    >
                      <form-group
                        name="municipio"
                        :validator="$v.tempUserData.municipality"
                      >
                        <v-select
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          :disabled="!tempUserData.department"
                          :items="section7.personalDetails.municipalities"
                          item-text="name"
                          item-value="id"
                          label="Municipio de nacimiento"
                          color="light-blue darken-4"
                          v-model="tempUserData.municipality"
                          outlined
                        ></v-select>
                      </form-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions
                class="d-flex flex-row justify-end align-center pt-0 pb-15 px-4"
              >
                <v-btn
                  color="light-blue darken-4"
                  text
                  large
                  @click="showMdlConfirm()"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="light-blue darken-4"
                  outlined
                  text
                  large
                  @click="previousStep(2)"
                >
                  <v-icon small class="ml-1">mdi-arrow-left-bold</v-icon>
                  Regresar
                </v-btn>
                <v-btn
                  color="light-blue darken-4"
                  class="white--text"
                  depressed
                  large
                  @click="validate(2)"
                >
                  Siguiente
                  <v-icon small class="ml-1">mdi-arrow-right-bold</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-stepper-content>
        <!-- Third Step -->
        <v-stepper-content step="3" class="pa-0">
          <v-form ref="createForm_section7_step3">
            <v-card elevation="0">
              <v-card-title
                class="text-h5 lighten-2 light-blue darken-4 white--text"
              >
                Información laboral del familiar
              </v-card-title>
              <v-card-text class="mt-5">
                <v-container>
                  <v-row>
                    <!-- Profesión u officio - Información familiar detallada -->
                    <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                      <form-group
                        name="profesión u oficio"
                        :validator="$v.tempUserData.profession"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Profesión u oficio"
                          color="light-blue darken-4"
                          v-model="tempUserData.profession"
                          outlined
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Lugar de trabajo - Información familiar detallada -->
                    <v-col cols="12" sm="6" class="pa-0 pa-sm-2">
                      <form-group
                        name="lugar de trabajo"
                        :validator="$v.tempUserData.workPlace"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Lugar de trabajo"
                          color="light-blue darken-4"
                          v-model="tempUserData.workPlace"
                          outlined
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Teléfono del area de trabajo - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="3" lg="3" class="pa-0 pa-sm-2">
                      <form-group
                        name="teléfono de trabajo"
                        :validator="$v.tempUserData.workPhone"
                        :messages="{
                          minLength:
                            'El campo teléfono de trabajo debería contener al menos 8 números',
                        }"
                      >
                        <v-text-field
                          slot-scope="{ attrs }"
                          v-bind="attrs"
                          autocomplete="null"
                          label="Teléfono del trabajo"
                          color="light-blue darken-4"
                          v-model="tempUserData.workPhone"
                          outlined
                          v-mask="'########'"
                        ></v-text-field>
                      </form-group>
                    </v-col>

                    <!-- Extención del teléfono del area de trabajo - Información familiar detallada -->
                    <v-col cols="12" sm="6" md="3" lg="3" class="pa-0 pa-sm-2">
                      <v-text-field
                        autocomplete="null"
                        label="Extensión"
                        color="light-blue darken-4"
                        v-model="tempUserData.extension"
                        outlined
                        v-mask="'####'"
                      ></v-text-field>
                    </v-col>

                    <!-- Dirección de area de trabajo - Información familiar detallada -->
                    <v-col cols="12" md="6" class="pa-0 pa-sm-2">
                      <v-textarea
                        autocomplete="null"
                        outlined
                        v-model="tempUserData.workAdress"
                        rows="1"
                        name="input-7-4"
                        label="Dirección de trabajo"
                        color="light-blue darken-4"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions
                class="d-flex flex-row justify-end align-center pt-0 pb-15 px-4"
              >
                <v-btn
                  color="light-blue darken-4"
                  text
                  large
                  @click="showMdlConfirm()"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="light-blue darken-4"
                  outlined
                  text
                  large
                  @click="previousStep(3)"
                >
                  <v-icon small class="ml-1">mdi-arrow-left-bold</v-icon>
                  Regresar
                </v-btn>
                <v-btn
                  color="light-blue darken-4"
                  class="white--text"
                  depressed
                  large
                  @click="validate(3)"
                >
                  Guardar
                  <v-icon right dark> mdi-send </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
      <!-- end:: Stepper items -->
    </v-stepper>
    <!-- end:: Stepper  -->
    <ConfirmationModal
      ref="confirmationModal"
      v-on:confirmData="toggleModal"
      v-on:resetForm="resetForm"
    ></ConfirmationModal>
  </v-dialog>
</template>
<script>
import {
  required,
  requiredIf,
  email,
  minLength,
} from "vuelidate/lib/validators";
import ConfirmationModal from "@/view/components/modals/ConfirmationModal";
export default {
  name: "CreateDetailedFamilyInformationModal",
  components: {
    ConfirmationModal,
  },
  props: {
    section7: {
      type: Object,
    },
    familyList: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      switch1: false,
      modalState: false,
      e1: 1,
      steps: 3,
      date: "",
      tempUserData: {
        firstName: null,
        lastName: null,
        email: null,
        kinship: null,
        phone: null,
        cellPhone: null,
        dui: null,
        nit: null,
        birthday: null,
        maritalStatus: null,
        nationality: null,
        department: null,
        municipality: null,
        profession: null,
        workPlace: null,
      },
      dateDialogState: false,
      activePicker: null,
    };
  },
  watch: {
    dateDialogState(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    toggleModal() {
      this.modalState = !this.modalState;
    },
    changeNationality() {
      this.tempUserData.department = null;
      this.tempUserData.municipality = null;
    },
    sendData(id) {
      this.$emit("resultado", id);
    },
    resetMunicipality() {
      this.tempUserData.municipality = null;
    },
    validate(step) {
      if (step == 1) {
        this.$v.tempUserData.firstName.$touch();
        this.$v.tempUserData.lastName.$touch();
        this.$v.tempUserData.email.$touch();
        this.$v.tempUserData.kinship.$touch();
        this.$v.tempUserData.cellPhone.$touch();
        this.$v.tempUserData.phone.$touch();
        this.$v.tempUserData.dui.$touch();
        if (!this.switch1) {
          this.$v.tempUserData.nit.$touch();
        }
        if (
          this.$v.tempUserData.firstName.$invalid ||
          this.$v.tempUserData.lastName.$invalid ||
          this.$v.tempUserData.email.$invalid ||
          this.$v.tempUserData.kinship.$invalid ||
          this.$v.tempUserData.cellPhone.$invalid ||
          this.$v.tempUserData.phone.$invalid ||
          this.$v.tempUserData.dui.$invalid ||
          this.$v.tempUserData.nit.$invalid
        ) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar los campos requeridos",
          });
          return;
        } else {
          this.nextStep(step);
        }
      } else if (step == 2) {
        this.$v.tempUserData.birthday.$touch();
        this.$v.tempUserData.maritalStatus.$touch();
        this.$v.tempUserData.nationality.$touch();
        this.$v.tempUserData.department.$touch();
        this.$v.tempUserData.municipality.$touch();
        if (
          this.$v.tempUserData.birthday.$invalid ||
          this.$v.tempUserData.maritalStatus.$invalid ||
          this.$v.tempUserData.nationality.$invalid ||
          this.$v.tempUserData.department.$invalid ||
          this.$v.tempUserData.municipality.$invalid
        ) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar los campos requeridos",
          });
          return;
        } else {
          this.nextStep(step);
        }
      } else if (step == 3) {
        this.$v.tempUserData.profession.$touch();
        if (this.$v.tempUserData.profession.$invalid) {
          this.fireToast({
            icon: "error",
            title: "Asegurate de llenar los campos requeridos",
          });
          return;
        }
        this.saveAndFormatData();
      }
    },
    nextStep(n) {
      this.e1 = n + 1;
    },
    previousStep(n) {
      this.e1 = n - 1;
    },

    //tempUserData es model temporal donde se guarda la info antes de pushearla al objeto final
    saveAndFormatData() {
      const dataBridgeObject = {
        firstName: this.tempUserData.firstName,
        lastName: this.tempUserData.lastName,
        dui: this.tempUserData.dui,
        nit: this.switch1 ? null : this.tempUserData.nit,
        nationalityId: this.tempUserData.nationality,
        birthday: this.tempUserData.birthday,
        municipalityId: this.tempUserData.municipality,
        kinshipId: this.tempUserData.kinship,
        maritalStatusId: this.tempUserData.maritalStatus,
        phone: this.tempUserData.phone,
        cellphone: this.tempUserData.cellPhone,
        profession: this.tempUserData.profession,
        email: this.tempUserData.email,
        workplace: this.tempUserData.workPlace,
        workPhone: this.tempUserData.workPhone,
        extension: this.tempUserData.extension,
        workAddress: this.tempUserData.workAdress,
        isManager: this.tempUserData.isManager
          ? this.tempUserData.isManager
          : false,
      };
      this.familyList.justAddedMember = dataBridgeObject;
      this.$emit("create");
      this.modalState = false;
      this.resetForm();
    },
    save(date) {
      this.$refs.dialog.save(date);
    },
    showMdlConfirm() {
      this.$refs.confirmationModal.toggleModal();
    },
    changeSwitchNit() {
      this.$v.$reset();
      this.tempUserData.nit = "";
    },
    resetForm() {
      this.$v.$reset();
      this.tempUserData = {};
      this.switch1 = false;
      this.e1 = 1;
    },
  },
  validations: {
    tempUserData: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      kinship: { required },
      phone: {
        required: requiredIf(function() {
          return (
            !!this.tempUserData.phone &&
            String(this.tempUserData.phone).length > 1
          );
        }),
        minLength: minLength(8),
      },
      cellPhone: { required, minLength: minLength(8) },
      dui: { required, minLength: minLength(10) },
      nit: {
        required: requiredIf(function() {
          return !this.switch1;
        }),
        minLength: minLength(17),
      },
      birthday: { required },
      maritalStatus: { required },
      nationality: { required },
      department: {
        required: requiredIf(function() {
          return this.tempUserData.nationality == 1;
        }),
      },
      municipality: {
        required: requiredIf(function() {
          return (
            this.tempUserData.nationality == 1 && !!this.tempUserData.department
          );
        }),
      },
      profession: { required },
    },
  },
};
</script>
