import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllTransports = () => {
  return ApiService.get("transports");
};

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getTransport = (id) => {
  return ApiService.get(`transports/${id}`);
};

export default {
  getAllTransports,
  getTransport,
};
