import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllActiveRelatives = () => {
    return ApiService.get("student-relatives/relative/active");
};
/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllGraduatedRelatives = () => {
    return ApiService.get("student-relatives/relative/graduated");
};
/**
* GET request to fetch all data
* @returns {*}
*/
export const getOneRelative = (id) => {
    return ApiService.get(`student-relatives/${id}`);
};
/**
 * GET request to fetch all data
 * @params payload
 * @returns {*}
 */
export const createActive = (payload) => {
    return ApiService.post("student-relatives/relative/active", payload);
};
/**
 * GET request to fetch all data
 * @params payload
 * @returns {*}
 */
export const createGraduated = (payload) => {
    return ApiService.post("student-relatives/relative/graduated", payload);
};
/**
 * PUT request to update a specific enrollment process student
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateActive = (id, payload) => {
    return ApiService.update("student-relatives/relative/active", id, payload);
};
/**
 * PUT request to update a specific enrollment process student
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateGraduated = (id, payload) => {
    return ApiService.update("student-relatives/relative/graduated", id, payload);
};
/**
 * DELETE request to delete the specified student-schools
 * @param id
 * @returns {*}
 */

export const deleteRelative = (id) => {
    return ApiService.delete(`student-relatives/${id}`);
};

export default {
    getAllActiveRelatives,
    getAllGraduatedRelatives,
    getOneRelative,
    createActive,
    createGraduated,
    updateActive,
    updateGraduated,
    deleteRelative,
};
